import { Buffer as Buf } from "buffer/"

function unescape(str) {
    return (str + '==='.slice((str.length + 3) % 4))
        .replace(/-/g, '+')
        .replace(/_/g, '/')
}

function escape(str) {
    return str.replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
}

export function base64UrlEncode(str, encoding) {
    return escape(Buf.from(str, encoding || 'utf8').toString('base64'));
}

export function base64UrlObjectEncode(object, encoding) {
    return escape(Buf.from(JSON.stringify(object), encoding || 'utf8').toString('base64'));
}

export function base64UrlDecode(str, encoding) {
    return Buf.from(unescape(str), 'base64').toString(encoding || 'utf8');
}

export function base64UrlObjectDecode(str, encoding) {
    return JSON.parse(Buf.from(unescape(str), 'base64').toString(encoding || 'utf8'));
}