const chartHeight = 300;
const realtimeMaxChartData = 100;

function getMargin() {
    const width = window.innerWidth;

    if (width > 1440)
        return 120;
    if (width > 1280)
        return 80;
    if (width > 1024)
        return 40;

    return 0;
}

export { chartHeight, realtimeMaxChartData, getMargin }