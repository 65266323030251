import { DashBoardSettedService } from "../../services";
export const dashboardsetted = {
  namespaced: true,
  state: { failedError: null },
  actions: {
    setTemperatureForMail({ commit }, data) {
      return new Promise((resolve, reject) => {
        DashBoardSettedService.setTemperatureForMail(data)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            commit("getAllFailure", error);
            reject(error);
          });
      });
    },

    getTemperatureForMail({ commit }, data) {
      return new Promise((resolve, reject) => {
        DashBoardSettedService.getTemperatureForMail(data)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            commit("getAllFailure", error);
            reject(error);
          });
      });
    },
  },
  mutations: {},
  getters: {
    getAllFailure(state, data) {
      state.failedError = data;
    },
  },
};
