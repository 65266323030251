<template>
  <nav class="
      top-0
      absolute
      z-50
      w-full
      flex flex-wrap
      items-center
      justify-between
      px-2
      py-3
      navbar-expand-lg
    ">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="
          w-full
          relative
          flex
          justify-between
          lg:w-auto lg:static lg:block lg:justify-start
        ">
        <router-link class="
            text-white text-sm
            font-bold
            leading-relaxed
            flex
            mr-4
            py-2
            whitespace-nowrap
            uppercase
          " to="/">
          <img alt="logo" src="/images/logo_white.png" style="height: 20px" height="20" /><span>
            Cloud</span>
        </router-link>
        <button class="
            cursor-pointer
            text-xl
            leading-none
            px-3
            py-1
            border border-solid border-transparent
            rounded
            bg-transparent
            block
            lg:hidden
            outline-none
            focus:outline-none
          " type="button" v-on:click="setNavbarOpen">
          <i class="fas fa-bars text-white"></i>
        </button>
      </div>
      <div class="
          lg:flex
          flex-grow
          items-center
          bg-white
          lg:bg-opacity-0 lg:shadow-none
        " :class="[navbarOpen ? 'block rounded shadow-lg' : 'hidden']" id="example-navbar-warning">
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <LocaleChanger />
          <li class="flex items-center">
            <a class="
                lg:text-white lg:hover:text-blueGray-200
                text-blueGray-700
                px-3
                py-4
                lg:py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              " href="https://www.facebook.com/aketroniks/" target="_blank">
              <i class="
                  lg:text-blueGray-200
                  text-blueGray-400
                  fab
                  fa-facebook
                  text-lg
                  leading-lg
                " />
              <span class="lg:hidden inline-block ml-2">Facebook</span>
            </a>
          </li>

          <li class="flex items-center">
            <a class="
                lg:text-white lg:hover:text-blueGray-200
                text-blueGray-700
                px-3
                py-4
                lg:py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              " href="https://twitter.com/aketroniks" target="_blank">
              <i class="
                  lg:text-blueGray-200
                  text-blueGray-400
                  fab
                  fa-twitter
                  text-lg
                  leading-lg
                " />
              <span class="lg:hidden inline-block ml-2">Twitter</span>
            </a>
          </li>

          <li class="flex items-center">
            <a class="
                lg:text-white lg:hover:text-blueGray-200
                text-blueGray-700
                px-3
                py-4
                lg:py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              " href="https://www.instagram.com/aketroniks/" target="_blank">
              <i class="
                  lg:text-blueGray-200
                  text-blueGray-400
                  fab
                  fa-instagram
                  text-lg
                  leading-lg
                " />
              <span class="lg:hidden inline-block ml-2">Instagram</span>
            </a>
          </li>

          <li class="flex items-center">
            <router-link to="/admin" v-if="isLogin" class="
                lg:text-white lg:hover:text-blueGray-200
                text-blueGray-700
                px-3
                py-4
                lg:py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              ">
              <i class="
                  lg:text-blueGray-200
                  text-blueGray-400
                  fab
                  fas
                  fa-desktop
                  text-lg
                  leading-lg
                " />
              <span class="inline-block ml-2">{{ $t("menu.mydevices") }}</span>
            </router-link>

            <router-link to="/auth/register" v-else-if="$route.name === 'Login'" class="
                lg:text-white lg:hover:text-blueGray-200
                text-blueGray-700
                px-3
                py-4
                lg:py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              ">
              <i class="
                  lg:text-blueGray-200
                  text-blueGray-400
                  fab
                  fas fas
                  fa-user
                  text-lg
                  leading-lg
                " />
              <span class="inline-block ml-2">{{
                  $t("auth.registerTitle")
              }}</span>
            </router-link>
            <router-link to="/auth/login" v-else class="
                lg:text-white lg:hover:text-blueGray-200
                text-blueGray-700
                px-3
                py-4
                lg:py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              ">
              <i class="
                  lg:text-blueGray-200
                  text-blueGray-400
                  fab
                  fas
                  fa-sign-in-alt fa-user
                  text-lg
                  leading-lg
                " />
              <span class="inline-block ml-2">{{ $t("auth.loginTitle") }}</span>
            </router-link>
          </li>

          <li class="flex items-center">
            <user-dropdown v-if="isLogin" />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import LocaleChanger from "../LocaleChanger.vue";
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen() {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  computed: {
    ...mapGetters("authentication", ["isLogined", "loginState"]),
    isLogin() {
      return this.$store.state.authentication.status.loggedIn;
    },
  },
  components: {
    LocaleChanger,
    UserDropdown
  },
};
</script>