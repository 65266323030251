<template>
  <div class="
    bg-blueGray-50
    border-indigo-500
      px-2
      py-1
      rounded
      text-sm
    ">
    <span class="
        text-black
        bg-blueGray-50
        border-indigo-500
        border
        rounded
        px-4
        py-2
        flex
        items-center
        cursor-pointer
        transition-all
      " ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
      {{ selectedItem }}
    </span>
    <div ref="popoverDropdownRef" class="
        bg-white
        z-50
        float-left
        list-none
        text-left
        rounded
        shadow-xl
        min-w-48
        transition-all
        border border-blueGray-300
      " :class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">
      <div class="bg-gray-100 w-full h-14 absolute rounded" style="z-index: -1;" />
      <div class="pb-6 px-2">
        <div class="relative">
          <i class="
            fas
            fa-times
            mt-3
            p-2
            absolute
            cursor-pointer
            right-4
            scale-125
            hover:scale-150
            transition-all
          " @click="toggleDropdown"></i>
        </div>
        <div class="
          flex flex-wrap flex-row
          divide-x-0 divide-gray-400 divide-opacity-75
          md:divide-x
          py-2
          px-2
          md:py-0
        ">
          <div class="px-0 md:px-2">
            <span class="
              p-3
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-blueGray-700 text-lg
            ">{{ $t("datePicker.quickRanges") }}
            </span>
            <div class="flex flex-wrap flex-row">
              <div class="flex flex-wrap flex-col">
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last2Days'), {
                    keyword: 'last',
                    value: 2,
                    type: 'day',
                  })
                ">{{ $t("datePicker.last2Days") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last7Days'), {
                    keyword: 'last',
                    value: 7,
                    type: 'day',
                  })
                ">{{ $t("datePicker.last7Days") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last30Days'), {
                    keyword: 'last',
                    value: 30,
                    type: 'day',
                  })
                ">{{ $t("datePicker.last30Days") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last60Days'), {
                    keyword: 'last',
                    value: 60,
                    type: 'day',
                  })
                ">{{ $t("datePicker.last60Days") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last90Days'), {
                    keyword: 'last',
                    value: 90,
                    type: 'day',
                  })
                ">{{ $t("datePicker.last90Days") }}</span>
              </div>
              <div class="flex flex-wrap flex-col">
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.yesterday'), {
                    value: 1,
                    type: 'day',
                  })
                ">{{ $t("datePicker.yesterday") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.dayBefore'), {
                    value: 2,
                    type: 'day',
                  })
                ">{{ $t("datePicker.dayBefore") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.previousWeek'), {
                    value: 1,
                    type: 'week',
                  })
                ">{{ $t("datePicker.previousWeek") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.previousMonth'), {
                    value: 1,
                    type: 'month',
                  })
                ">{{ $t("datePicker.previousMonth") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last6Months'), {
                    keyword: 'last',
                    value: 6,
                    type: 'month',
                  })
                ">{{ $t("datePicker.last6Months") }}</span>
              </div>
              <div class="flex flex-wrap flex-col">
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.today'), {
                    value: 0,
                    type: 'day',
                  })
                ">{{ $t("datePicker.today") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.thisWeek'), {
                    value: 0,
                    type: 'week',
                  })
                ">{{ $t("datePicker.thisWeek") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.thisMonth'), {
                    value: 0,
                    type: 'month',
                  })
                ">{{ $t("datePicker.thisMonth") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.thisYear'), {
                    value: 0,
                    type: 'year',
                  })
                ">{{ $t("datePicker.thisYear") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.lastYear'), {
                    keyword: 'last',
                    value: 1,
                    type: 'year',
                  })
                ">{{ $t("datePicker.lastYear") }}</span>
              </div>
              <div class="flex flex-wrap flex-col">
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.lastHour'), {
                    keyword: 'last',
                    value: 1,
                    type: 'hour',
                  })
                ">{{ $t("datePicker.lastHour") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last3Hours'), {
                    keyword: 'last',
                    value: 3,
                    type: 'hour',
                  })
                ">{{ $t("datePicker.last3Hours") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last6Hours'), {
                    keyword: 'last',
                    value: 6,
                    type: 'hour',
                  })
                ">{{ $t("datePicker.last6Hours") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last12Hours'), {
                    keyword: 'last',
                    value: 12,
                    type: 'hour',
                  })
                ">{{ $t("datePicker.last12Hours") }}</span>
                <span class="
                  p-3
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                  hover:bg-gray-200
                  cursor-pointer
                  rounded
                  transition-all
                " @click="
                  onItemSelected($t('datePicker.last24Hours'), {
                    keyword: 'last',
                    value: 24,
                    type: 'hour',
                  })
                ">{{ $t("datePicker.last24Hours") }}</span>
              </div>
            </div>
          </div>
          <div class="px-0 md:px-2">
            <span class="
              p-3
              block
              whitespace-nowrap
              bg-transparent
              text-blueGray-700 text-lg
              w-full
            ">{{ $t("datePicker.custom") }}</span>
            <div class="max-w-sm">
              <div>
                <span class="
                  p-3
                  block
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                ">{{ $t("datePicker.from") }}</span>
                <div class="flex px-0 md:px-4 space-x-4">
                  <Datepicker dayPicker :enableTimePicker="false" v-model="startTime.date" :clearable="false"
                    hideInputIcon @update:modelValue="onDateUpdated" :maxDate="new Date()" />
                  <Datepicker timePicker v-model="startTime.time" :clearable="false" hideInputIcon
                    @update:modelValue="onDateUpdated" />
                </div>
              </div>
              <div>
                <span class="
                  p-3
                  block
                  whitespace-nowrap
                  bg-transparent
                  text-blueGray-700
                ">{{ $t("datePicker.to") }}</span>
                <div class="flex px-0 md:px-4 space-x-4">
                  <Datepicker dayPicker :enableTimePicker="false" v-model="endTime.date" :clearable="false"
                    hideInputIcon @update:modelValue="onDateUpdated" :maxDate="new Date()" />
                  <Datepicker timePicker v-model="endTime.time" :clearable="false" hideInputIcon
                    @update:modelValue="onDateUpdated" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import moment from "moment";

export default {
  props: {
    chartId: {
      required: false,
      default: ""
    }
  },
  data() {
    return {
      dropdownPopoverShow: false,
      selectedItem: this.$t("datePicker.thisWeek"),
      startTime: {
        date: new Date(),
        time: {
          hours: 0,
          minutes: 0,
        },
      },
      endTime: {
        date: new Date(),
        time: {
          hours: new Date().getHours(),
          minutes: new Date().getMinutes(),
        },
      },
    };
  },
  emits: ["onQuickRangeSelected", "onRangeSelected"],
  created() {
    const selected = this.$cookies.get(`selected-date-time-${this.chartId}`);

    if (!selected)
      return;

    if (selected.item) {
      this.selectedItem = selected.item;
    }
    else if (selected.startTime) {
      this.selectedItem = `${selected.startTime} -> ${selected.endTime}`
    }
  },
  methods: {
    toggleDropdown() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
        return;
      }

      this.dropdownPopoverShow = true;
      createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
        placement: "bottom-start",
      });
    },
    onItemSelected(item, value) {
      this.toggleDropdown();

      if (this.selectedItem === item) return;
      this.selectedItem = item;

      this.$cookies.set(`selected-date-time-${this.chartId}`, { item, value });

      this.$emit("onQuickRangeSelected", value);
    },
    onDateUpdated() {

      const startDate = ` ${moment(this.startTime.date).format(
        "YYYY-MM-DD"
      )} ${this.formatTime(this.startTime.time)}`;

      const endDate = ` ${moment(this.endTime.date).format(
        "YYYY-MM-DD"
      )} ${this.formatTime(this.endTime.time)}`;

      this.selectedItem = `${startDate} -> ${endDate}`;
      this.$cookies.set(`selected-date-time-${this.chartId}`, {
        startTime: startDate,
        endTime: endDate,
      });

      this.$emit("onRangeSelected", {
        startTime: startDate,
        endTime: endDate,
      });
    },
    formatTime(time) {
      function padZero(number) {
        return String(number).padStart(2, "0");
      }
      return `${padZero(time.hours)}:${padZero(time.minutes)}`;
    },
  },
};
</script>