<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {{ $t("menu.apollo32.program.create") }}
        </h6>
        <div>
          <button class="
              bg-blue-500
              text-white
              active:bg-blue-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              disabled:opacity-20
              duration-150
            " type="button" :disabled="progressIndicatorVisible || !createButtonEnabled" @click="handleProgramEdit()">
            <i class="far fa-save"></i>
            {{ $t("navigation.create") }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("programs.programInfo") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("programs.name") }}
              </label>
              <input v-model.trim="data.name" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :placeholder="$t('programs.name')" :disabled="progressIndicatorVisible" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                {{ $t("programs.description") }}
              </label>

              <input v-model.trim="data.description" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :placeholder="$t('programs.description')" :disabled="progressIndicatorVisible" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="flex-auto px-4 lg:px-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
          {{ $t("programs.weeklySchedule") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <Scheduler ref="scheduler"></Scheduler>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="flex-auto px-4 lg:px-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
          {{ $t("programs.exceptionDays") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <ExceptionDays ref="exceptions"></ExceptionDays>
            </div>
          </div>
        </div>
      </form>
    </div>

    <pulse-loader class="text-center p-4" :loading="progressIndicatorVisible"></pulse-loader>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Scheduler from "../../../../components/Scheduler/Scheduler.vue";
import ExceptionDays from "../../../../components/Scheduler/ExceptionDays.vue";
import { successToast, errorToast } from "../../../../helpers/toast-helper";

export default {
  data() {
    return {
      data: {
        name: "",
        description: "",
      },
      progressIndicatorVisible: false,
    };
  },
  methods: {
    ...mapActions("programs", [
      "addNewApollo32Schedule",
      "addApollo32ScheduleData",
    ]),
    handleProgramEdit() {
      this.progressIndicatorVisible = true;

      this.addNewApollo32Schedule({
        name: this.data.name,
        description: this.data.description,
      })
        .then((data) => {
          this.addApollo32ScheduleData({
            id: data.id,
            data: {
              scheduleTypeId: data.id,
              durations: this.$refs.scheduler.getData(),
              exceptions: this.$refs.exceptions.getData(),
            },
          })
            .then(() => {
              successToast(this.$t("messages.createdSuccesfully"));
              this.$router.push({ name: "apollo32.programs" });
            })
            .finally(() => (this.progressIndicatorVisible = false));
        })
        .catch((err) => {
          errorToast(this.$t("messages.failed"));
          console.error(err);
          this.progressIndicatorVisible = false;
        });
    },
    groupBy(array, group) {
      let hash = Object.create(null);
      let result = {};

      array.forEach((a) => {
        if (!hash[a[group]]) {
          hash[a[group]] = [];
          result[a[group]] = hash[a[group]];
        }

        hash[a[group]].push(a);
      });

      return result;
    },
  },
  components: {
    Scheduler,
    ExceptionDays,
  },
  computed: {
    createButtonEnabled() {
      return this.data.name.length > 0;
    },
  },
};
</script>