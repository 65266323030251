import { GroupingService } from "../../services";

export const grouping = {
    namespaced: true,
    state: {
        groups: [],
        groupDevices: [],
        failedError: null,
    },
    actions: {
        getGroups({ commit }) {

            return new Promise((resolve, reject) => {

                GroupingService.getUserGroups()
                    .then((res) => {
                        commit("setGroups", res.data);
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        addNewGroup({ commit }, group) {

            return new Promise((resolve, reject) => {

                GroupingService.postUserGroup(group)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        updateGroup({ commit }, group) {

            return new Promise((resolve, reject) => {

                GroupingService.putUserGroup(group)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    })
            })
        },
        removeGroup({ commit }, group) {

            return new Promise((resolve, reject) => {

                GroupingService.deleteUserGroup(group)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getGroupDevices({ commit }, groupId) {

            return new Promise((resolve, reject) => {

                GroupingService.getGroupDevices(groupId)
                    .then((res) => {
                        commit("setGroupDevices", res.data);
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        setGroupDevices({ commit }, data) {

            return new Promise((resolve, reject) => {

                GroupingService.postGroupDevice(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        removeGroupDevice({ commit }, data) {

            return new Promise((resolve, reject) => {

                GroupingService.deleteGroupDevice(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        setGroups(state, data) {
            state.groups = data;
        },
        setGroupDevices(state, data) {
            state.groupDevices = data;
        },
        getAllFailure(state, data) {
            state.failedError = data;
        },
    },
    getters: {
        allGroups: (state) => {
            return state.groups;
        },
        allGroupDevice: (state) => {
            return state.groupDevices;
        },
    },
};