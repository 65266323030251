<template>
  <div class="flex flex-wrap w-full z-10">
    <ClickableTable :title="$t('batchWorking.batchWorkingProfiles')" tableId="profilesTable" :headers="headers"
      :rows="rows" @onClick="onProfileClick" />
  </div>
  <pulse-loader class="text-center" :loading="progressIndicatorVisible"></pulse-loader>

  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-lg
      rounded
    ">
    <div class="block w-full overflow-x-auto">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-blueGray-700 pt-2 pb-2">
              {{ $t("batchWorking.createNewPlan") }}
            </h3>
          </div>

          <div class="border-collapse w-full">
            <div class="flex flex-wrap" v-if="batchWorkingProjects.length > 0">
              <div class="
                    group
                    p-6
                    bg-white
                    rounded
                    hover:shadow-lg
                    cursor-pointer
                    max-w-sm
                    m-3
                    border
                    transition-all
                    w-full
                  " v-for="(item, index) in batchWorkingProjects" :key="index"
                @click="onNewProjectClicked(index, item)">
                <p class="text-base text-blueGray-700 font-bold">
                  {{ item.name }}
                </p>
                <p class="
                      text-sm text-blueGray-700
                      overflow-ellipsis overflow-hidden
                    ">
                  {{ $t(`batchWorking.projectInfos.${item.project}`) }}
                </p>
              </div>
            </div>
            <div v-else>
              <div class="p-4">
                <h4 class="text-blueGray-500">
                  {{ $t("batchWorking.notFoundSupportingProject") }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      rounded
      mb-6
      xl:mb-0
      shadow-lg
    ">
    <div class="flex-auto p-4">
      <div class="flex flex-wrap">
        <RouterButton :title="$t('devices.help')" route-to="/help" icon-class="fas fa-question-circle" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ClickableTable from "../../../components/Tables/ClickableTable.vue";
import { base64UrlObjectEncode } from "../../../helpers/encoing-helper";
import RouterButton from "../../../components/Buttons/RouterButton.vue";

export default {
  data() {
    return {
      ...mapState("users", ["groups"]),
      headers: [
        { langKey: "batchWorking.programName", key: "name" },
        { langKey: "groups.description", key: "description" },
        { langKey: "batchWorking.productType", key: "projectName" },
        { langKey: "groups.actions", key: "actions" },
      ],
      values: [],
      batchWorkingProjects: [],
      progressIndicatorVisible: false,
    };
  },
  created() {
    this.getAllUserProjects().then(
      (data) =>
      (this.batchWorkingProjects = data
        .filter((e) => e.hasbatches)
        .map((e) => ({ id: e.id, name: e.name, project: e.databasename })))
    );

    this.progressIndicatorVisible = true;
    this.getBatchWorkingProfiles()
      .then((data) => {

        for (const [key, value] of Object.entries(data)) {
          value.forEach((element) => {
            this.values.push({
              id: element.id,
              project: key,
              name: element.name,
              description: element.description,
              projectName: key,
            });
          });
        }
      })
      .finally(() => (this.progressIndicatorVisible = false));
  },
  methods: {
    ...mapActions("users", ["getAllUserProjects"]),
    ...mapActions("batchWorking", ["getBatchWorkingProfiles"]),
    onNewProjectClicked(index, item) {
      this.$router.push({
        name: "createBatchWorking",
        params: {
          project: base64UrlObjectEncode(this.batchWorkingProjects[index]),
        },
      });
    },
    onProfileClick(index, item) {
      const row = this.values[index];
      this.$router.push({
        name: `batchWorking_${row.project}`,
        params: {
          profile: base64UrlObjectEncode(row),
        },
      });
    },
  },
  components: {
    ClickableTable,
    RouterButton
  },
  computed: {
    rows: function () {
      if (!Array.isArray(this.values)) return;

      return this.values.map((x) => {
        const buttons = [];

        buttons.push({
          langKey: "batchWorking.editProgram",
          routerLink: {
            name: "editBatchWorking",
            params: {
              profile: base64UrlObjectEncode({
                id: x.id,
                name: x.name,
                description: x.description,
                project: x.projectName,
              }),
            },
          },
          iconClass: "far fa-edit",
        });

        return {
          columns: [
            { name: x.name },
            { description: x.description },
            { projectName: x.projectName },
          ],
          buttons: buttons,
        };
      });
    },
  },
};
</script>