<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {{ $t("menu.createBatchWorking") }}
          ({{ params.name }})
        </h6>
        <button class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            disabled:opacity-20
            duration-150
          " type="button" :disabled="progressIndicatorVisible || !createButtonEnabled" @click="handleGroupCreate()">
          <i class="fas fa-plus"></i>
          {{ $t("navigation.create") }}
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("batchWorking.profileInfo") }}
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("batchWorking.programName") }}
              </label>
              <input v-model.trim="profileData.name" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :disabled="progressIndicatorVisible" :placeholder="$t('batchWorking.programNamePlaceholder')" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                {{ $t("groups.description") }}
              </label>
              <input v-model.trim="profileData.description" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :placeholder="$t('groups.description')" :disabled="progressIndicatorVisible" />
            </div>
          </div>
        </div>
      </form>

      <pulse-loader class="text-center" :loading="progressIndicatorVisible"></pulse-loader>
    </div>

    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" v-if="devices.length > 0">
      <div class="flex flex-wrap w-full z-10">
        <SelectableTable :title="$t('devices.devices')" tableId="createProfileTable" :headers="headers" :rows="devices"
          @onItemClick="onItemClick" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import SelectableTable from "../../../components/Tables/SelectableTable.vue";
import { errorToast, successToast } from "../../../helpers/toast-helper";

export default {
  data() {
    return {
      headers: [
        { langKey: "devices.serialNo", key: "serialNo" },
        { langKey: "devices.name", key: "name" },
        { langKey: "devices.addingtime", key: "addingtime" },
        { langKey: "groups.addRemove", key: "include" },
      ],
      profileData: {
        name: "",
        description: "",
      },
      progressIndicatorVisible: false,
      params: {},
      devices: [],
    };
  },
  created() {
    this.params = base64UrlObjectDecode(this.$route.params.project);

    this.progressIndicatorVisible = true;
    this.getBatchWorkingDevices({project:this.params.project})
      .then((data) => (this.devices = this.createRows(data)))
      .finally(() => (this.progressIndicatorVisible = false));
  },
  components: {
    SelectableTable,
  },
  methods: {
    ...mapActions("batchWorking", [
      "getBatchWorkingDevices",
      "addBatchWorkingProfile",
      "addBatchWorkingeDevices",
    ]),
    handleGroupCreate() {
      this.progressIndicatorVisible = true;
      this.addBatchWorkingProfile({
        project: this.params.project,
        data: {
          name: this.profileData.name,
          description: this.profileData.description,
        },
      }).then((data) => {
        this.addBatchWorkingeDevices({
          project: this.params.project,
          batchId: data.id,
          data: {
            deviceIds: this.getSelectedDeviceIds(),
          },
        })
          .then(() => {
            successToast(this.$t("messages.createdSuccesfully"));
            this.$router.push("/admin/batchWorking");
          })
          .catch((err) => {
            errorToast(this.$t("messages.failed"));
            console.error(err);
          })
          .finally(() => (this.progressIndicatorVisible = false));
      });
    },
    onItemClick(index, item) {
      this.devices[index].selected = !this.devices[index].selected;
    },
    createRows(values) {
      const devices = values.devices;
      const names = values.names;

      if (!Array.isArray(devices) || !Array.isArray(names)) return [];

      let result = [];

      for (let i = 0; i < names.length; i++) {
        const element = names[i];
        const device = devices[i];

        result.push({
          columns: [
            { serialNo: device.serialno },
            { name: element.name },
            { addingtime: moment(element.addingtime).format("YYYY-MM-DD") },
          ],
          selected: false,
          deviceId: device.id,
        });
      }

      return result;
    },
    getSelectedDeviceIds() {
      return this.devices.filter((e) => e.selected).map((e) => e.deviceId);
    },
  },
  computed: {
    createButtonEnabled() {
      return this.profileData.name.length > 0;
    },
  },
};
</script>