import { config } from "./config";
import { authHeader } from "../helpers";
import axios from "axios";

function getDevices() {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/su/admin/management/devices`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

function updateDevice({ id, data }) {

    return new Promise((resolve, reject) => {
        axios
            .put(`${config.apiUrl}/su/admin/management/devices/${id}`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

function removeDevice(id) {

    return new Promise((resolve, reject) => {
        axios
            .delete(`${config.apiUrl}/su/admin/management/devices/${id}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

function addDevice(data) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/su/admin/management/devices`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

function getUsers() {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/su/admin/management/users`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

function getProjects() {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/su/admin/management/projects`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

function updateProject({ id, databaseName, data }) {

    return new Promise((resolve, reject) => {
        axios
            .put(`${config.apiUrl}/${databaseName}/projectversion/${id}`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

function updateUser({ id, data }) {

    return new Promise((resolve, reject) => {
        axios
            .put(`${config.apiUrl}/su/admin/management/users/${id}`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

export {
    getDevices,
    updateDevice,
    removeDevice,
    addDevice,
    getProjects,
    getUsers,
    updateUser,
    updateProject
};