<template>
    <div class="w-full lg:w-6/12 px-4">
        <div class="flex w-full p-4 content-center items-center space-x-4">
            <label class="uppercase text-blueGray-600 text-sm font-bold select-none">
                {{ title }}
            </label>

            <label class="inline-flex items-center cursor-pointer">
                <input @change="handleInput" v-model="modelValue" type="checkbox" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                  disabled:text-blueGray-400
                " :disabled="disabled" ref="checkbox" />
            </label>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            required: false,
        },
        title: {
            required: false,
            default: ""
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.$refs.checkbox.checked = this.modelValue == 1;
    },
    methods: {
        handleInput() {
            this.$emit('update:modelValue', this.modelValue);
        },
    }
}
</script>