<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {{ $t("batchWorking.editPlan") }}
        </h6>
        <div>
          <button
            class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all disabled:opacity-20 duration-150"
            type="button"
            :disabled="progressIndicatorVisible"
            @click="handleRemove()"
          >
            <i class="far fa-trash-alt"></i>
            {{ $t("batchWorking.removePlan") }}
          </button>

          <button
            class="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all disabled:opacity-20 duration-150"
            type="button"
            :disabled="progressIndicatorVisible || !createButtonEnabled"
            @click="handleBatchWorkingEdit()"
          >
            <i class="far fa-save"></i>
            {{ $t("navigation.save") }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("batchWorking.profileInfo") }}
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("batchWorking.programName") }}
              </label>
              <input
                v-model.trim="data.name"
                type="text"
                maxlength="255"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :placeholder="$t('batchWorking.programName')"
                :disabled="progressIndicatorVisible"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                {{ $t("groups.description") }}
              </label>

              <input
                v-model.trim="data.description"
                type="text"
                maxlength="255"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :placeholder="$t('groups.description')"
                :disabled="progressIndicatorVisible"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" v-if="devices.length > 0">
      <div class="flex flex-wrap w-full z-10">
        <SelectableTable
          :title="$t('devices.devices')"
          :headers="headers"
          :rows="devices"
          @onItemClick="onItemClick"
        />
      </div>
    </div>

    <pulse-loader
      class="text-center mb-6"
      :loading="progressIndicatorVisible"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import SelectableTable from "../../../components/Tables/SelectableTable.vue";
import { errorToast, successToast } from "../../../helpers/toast-helper";

export default {
  data() {
    return {
      params: {},
      headers: [
        { langKey: "devices.serialNo", key: "serialNo" },
        { langKey: "devices.name", key: "name" },
        { langKey: "devices.addingtime", key: "addingtime" },
        { langKey: "groups.addRemove", key: "include" },
      ],
      data: {
        name: "",
        description: "",
      },
      devices: [],
      progressIndicatorVisible: false,
    };
  },
  components: {
    SelectableTable,
  },
  created() {
    this.params = base64UrlObjectDecode(this.$route.params.profile);
    this.data.name = this.params.name;
    this.data.description = this.params.description;

    this.progressIndicatorVisible = true;
    this.getBatchWorkingDevices({ project: this.params.project }).then(
      (data1) => {
        let devices = data1.devices;
        let names = data1.names;

        let result = [];

        for (let i = 0; i < names.length; i++) {
          result.push({
            selected: false,
            deviceid: devices[i].deviceid,
            addingtime: names[i].addingtime,
            id: devices[i].id,
            name: names[i].name,
          });
        }

        this.getBatchWorkingProfileDevices({
          project: this.params.project,
          batchId: this.params.id,
        })
          .then((data2) => {
            data2.forEach((e) => {
              const device = e.device;

              const requiredIndex = result.findIndex(e => e.deviceid == device.deviceid);

              if(requiredIndex != -1) {
                result.splice(requiredIndex,1);
              }

              result.push({
                selected: true,
                deviceid: device.deviceid,
                addingtime: device.addingtime,
                id: device.id,
                name: e.userDevice.name,
              });
            });

            this.devices = this.createRows(
              result.sort(
                (a, b) => new Date(b.addingtime) - new Date(a.addingtime)
              )
            );
          })
          .finally(() => (this.progressIndicatorVisible = false));
      }
    );
  },
  methods: {
    ...mapActions("batchWorking", [
      "updateBatchWorkingProfile",
      "removeBatchWorkingProfile",
      "getBatchWorkingDevices",
      "getBatchWorkingProfileDevices",
      "addBatchWorkingeDevices",
    ]),
    handleBatchWorkingEdit() {
      this.progressIndicatorVisible = true;

      this.updateBatchWorkingProfile({
        project: this.params.project,
        batchId: this.params.id,
        data: {
          name: this.data.name,
          description: this.data.description,
        },
      })
        .then(() => {
          this.addBatchWorkingeDevices({
            project: this.params.project,
            batchId: this.params.id,
            data: {
              deviceIds: this.getSelectedDeviceIds(),
            },
          })
            .then(() => {
              this.$router.push("/admin/batchWorking");
              successToast(this.$t("messages.updatedSuccesfully"));
            })
            .finally(() => (this.progressIndicatorVisible = false));
        })
        .catch((err) => {
          errorToast(this.$t("messages.failed"));
          console.error(err);
          this.progressIndicatorVisible = false;
        });
    },
    handleRemove() {
      this.progressIndicatorVisible = true;

      this.removeBatchWorkingProfile({
        project: this.params.project,
        batchId: this.params.id,
      })
        .then(() => {
          successToast(this.$t("messages.success"));
          this.$router.push("/admin/batchworking");
        })
        .finally(() => (this.progressIndicatorVisible = false));
    },
    createRows(values) {
      if (!Array.isArray(values)) return [];

      return values.map((x) => {
        return {
          columns: [
            { serialNo: x.serialno },
            { name: x.name },
            { addingtime: moment(x.addingtime).format("YYYY-MM-DD") },
          ],
          selected: x.selected,
          deviceId: x.id,
        };
      });
    },
    onItemClick(index, item) {
      this.devices[index].selected = !this.devices[index].selected;
    },
    getSelectedDeviceIds() {
      return this.devices.filter((e) => e.selected).map((e) => e.deviceId);
    },
  },
  computed: {
    createButtonEnabled() {
      return this.data.name.length > 0;
    },
  },
};
</script>
