<template>
  <div class="flex flex-wrap w-full z-10">
    <ClickableTable
      :title="$t('menu.mydevices')"
      tableId="myDevicesTable"
      :headers="headers"
      :rows="rows"
      @onClick="onDeviceClicked"
    />
  </div>
  <PulseLoader class="text-center" :loading="busy" />
  <div
    class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg"
  >
    <div class="flex-auto p-4">
      <div class="flex flex-wrap">
        <RouterButton
          :title="$t('devices.addNewDevice')"
          :route-to="{ name: 'addNewDevice' }"
          icon-class="fas fa-plus"
        />
        <RouterButton
          :title="$t('menu.addNewDeviceWithCode')"
          :route-to="{ name: 'addNewDeviceWithCode' }"
          icon-class="fas fa-plus"
        />
        <RouterButton
          :title="$t('devices.help')"
          route-to="/help"
          icon-class="fas fa-question-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";

import ClickableTable from "../../../components/Tables/ClickableTable.vue";
import RouterButton from "../../../components/Buttons/RouterButton.vue";
import { base64UrlObjectEncode } from "../../../helpers/encoing-helper";
import { BatchWorkingService } from "../../../services/batchworking.service";

//eğer cihazlar için apollo varsa yukarda göster. Röle kolonunu da göster.
export default {
  data() {
    return {
      headers: [
        //{ langKey: "devices.serialNo", key: "serialNo" },
        //{ langKey: "devices.mac", key: "mac" },
        { langKey: "devices.name", key: "name" },
        { langKey: "devices.status", key: "status" },
        { langKey: "info.relay", key: "relay" },
        { langKey: "devices.group", key: "group" },
        //{ langKey: "devices.projectName", key: "projectName" },
        //{ langKey: "devices.addingtime", key: "addingtime" },
        { langKey: "devices.actions", key: "actions" },
      ],
      values: [],
      busy: false,
      batches: [], //çıktığım zaman sıfırla?
    };
  },
  async created() {
    this.busy = true;
    this.getMyDevices()
      .then((res) => {
        this.values = res.filter((x) => x.device.projectInfo.hasimplementation);
        this.values.map(async (x) => {
          await this.getBatchName(x.deviceid);
        });
        this.onGetApolloSystemInfo(this.values);
      })
      .finally(() => (this.busy = false));
  },
  methods: {
    ...mapActions("users", ["getMyDevices"]),
    ...mapMutations("dashboardheader", [
      "setHeaderData",
      "setHeaderVisibility",
    ]),

    onDeviceClicked(index, item) {
      const deviceData = this.values[index];

      this.$router.push({
        name: "dashboard",
        params: {
          device: base64UrlObjectEncode({
            ...deviceData.device,
            userGivenName: deviceData.name,
            userAddingTime: deviceData.addingtime,
          }),
        },
      });
    },
    async getBatchName(deviceid) {
      const res =
        await BatchWorkingService.getApollo32DeviceBatchWorkingProfile({
          deviceId: deviceid,
        });
      const result = await BatchWorkingService.getApollo32BatchName({
        batchid: res.data.batchid,
      });

      const batch = {
        deviceid: deviceid,
        batchid: res.data.batchid,
        batchname: result.data.name,
      };

      this.batches.push(batch);
    },
    async onGetApolloSystemInfo(deviceList) {
      let systemInfo = {
        activeDeviceCount: 0,
        activeRelayCount: 0,
        deviceCount: 0,
      };

      deviceList.forEach(async (device) => {
        const dvc = device.device;

        if (dvc.project == 9) {
          systemInfo.deviceCount++;

          const now = moment();
          const duration = moment
            .duration(now.diff(dvc.lastdatatime))
            .asMinutes();
          const isDeviceActive = duration < 2;

          if (isDeviceActive) systemInfo.activeDeviceCount++;
          if (isDeviceActive && dvc.relay === 1) systemInfo.activeRelayCount++;
        }
      });
      if (systemInfo.deviceCount > 0) {
        this.setHeaderData(systemInfo);
        this.setHeaderVisibility(true);
      }
    },
  },
  components: {
    ClickableTable,
    RouterButton,
  },
  computed: {
    rows() {
      if (!Array.isArray(this.values)) return [];

      return this.values.map((x) => {
        const buttons = [];

        if (!x.subend || new Date(x.subend) > new Date()) {
          buttons.push({
            langKey: "devices.watch",
            routerLink: {
              name: "dashboard",
              params: {
                device: base64UrlObjectEncode({
                  ...x.device,
                  userGivenName: x.name,
                  userAddingTime: x.addingtime,
                }),
              },
            },
            iconClass: "far fa-chart-bar",
          });
        } else {
          buttons.push({
            langKey: "devices.refreshSubs",
            routerLink: {
              path: "mydevices/refresh",
              query: { deviceid: x.device.deviceid },
            },
            iconClass: "far fa-redo",
          });
        }

        buttons.push({
          langKey: "devices.edit",
          routerLink: {
            name: "editDevice",
            params: {
              device: base64UrlObjectEncode({
                id: x.id,
                deviceid: x.device.deviceid,
                name: x.name,
                isOpen: x.device.isOpen,
                adminUserId: x.device.userid,
              }),
            },
          },
          iconClass: "far fa-edit",
        });

        if (x.device.projectInfo.helpurlsuffix != "") {
          buttons.push({
            langKey: "devices.help",
            routerLink: {
              path: "../help",
              query: { title: x.device.projectInfo.helpurlsuffix },
            },
            iconClass: "far fa-question-circle",
          });
        }
        const now = moment();
        const duration = moment
          .duration(now.diff(x.device.lastdatatime))
          .asMinutes();
        const state = duration < 2;

        const batch = this.batches.filter(function (a) {
          return a.deviceid == x.deviceid;
        });

        const relayState = () => {
          if (state == false) {
            return "unknown"; //unknown
          }

          if (x.device.relay == 1) {
            return "open";
          } else if (x.device.relay == 0) {
            return "close";
          } else {
            return "none";
          }
        };

        return {
          columns: [
            //{ serialNo: x.device.serialno },
            //{ mac: x.device.deviceid },
            { name: x.name },
            { status: state },
            { relay: relayState() },
            {
              group:
                batch.length > 0
                  ? batch[0]["batchname"]
                  : this.$t("devices.nogroup"),
            },
            //{ projectName: x.device.projectInfo.name },
            //{ addingtime: moment(x.addingtime).format("YYYY-MM-DD") },
          ],
          buttons: buttons,
        };
      });
    },
  },
  unmounted() {
    this.setHeaderVisibility(false);
  },
};
</script>
