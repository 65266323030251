<template>
  <div>
    <navbar />
    <main>
      <section class="relative block h-500-px">
        <div class="absolute top-0 w-full h-full bg-center bg-cover" style="
            background-image: url('https://iotakecloud.com/images/banner-image-1-1920x700.jpg');
          ">
          <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black" />
        </div>
        <div class="
            top-auto
            bottom-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            h-70-px
          " style="transform: translateZ(0)">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>

      <div class="relative py-16 -mt-64">
        <ProfileCard :title="$t('admin.userInfo')">
          <Form>
            <div class="flex flex-wrap mt-6">
              <TextInput :title="$t('users.name')" v-model.trim="userInfo.name" :disabled="progressIndicatorVisible" />
              <TextInput :title="$t('users.surname')" v-model.trim="userInfo.surname"
                :disabled="progressIndicatorVisible" />
              <TextInput :title="$t('users.email')" v-model.trim="userInfo.email" disabled />
            </div>

            <div class="flex justify-end mr-1">
              <Button :title="$t('navigation.update')" icon-class="far fa-save" background-color="bg-green-500"
                :disabled="progressIndicatorVisible || !updateUserInfoEnabled" @click="onUpdateUserNameClicked" />
            </div>
          </Form>
        </ProfileCard>

        <ProfileCard :title="$t('auth.passwordUpdate')">
          <Form>
            <div class="flex flex-wrap mt-6">
              <TextInput :title="$t('auth.oldPassword')" v-model="passwordRenew.oldPassword"
                :disabled="progressIndicatorVisible" type="password" />
            </div>

            <div class="flex flex-wrap mt-6">
              <TextInput :title="$t('auth.password')" v-model="passwordRenew.password1"
                :disabled="progressIndicatorVisible" type="password" />
              <TextInput :title="$t('auth.passwordAgain')" v-model="passwordRenew.password2"
                :disabled="progressIndicatorVisible" type="password" />
            </div>

            <div class="flex justify-end mr-1">
              <Button :title="$t('navigation.update')" icon-class="far fa-save" background-color="bg-green-500"
                :disabled="progressIndicatorVisible || !updatePasswordEnabled" @click="onUpdatePasswordClicked" />
            </div>
          </Form>
        </ProfileCard>

        <ProfileCard :title="$t('profile.preferences')">
          <Form>
            <div class="flex flex-wrap mt-6">
              <SelectInput v-model="preferences.tempUnit" :title="$t('profile.tempUnit')"
                :values="[{ name: 'C' }, { name: 'F' }]" value-key="id" display-value-key="name"
                @update:modelValue="tempUnitChanged" />
            </div>
          </Form>
        </ProfileCard>
      </div>
    </main>
    <footer-component />
  </div>
</template>

<script>
import Button from "../components/Buttons/Button";
import TextInput from "../components/Forms/TextInput";
import FormSection from "../components/Forms/FormSection";
import SelectInput from "../components/Forms/SelectInput";
import Form from "../components/Forms/Form";
import ProfileCard from "../components/Profile/ProfileCard";
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/FooterAdmin.vue";
import { mapActions } from "vuex";
import { successToast, errorToast } from "@/helpers/toast-helper";

export default {
  name: "ProfilePage",
  components: {
    Button,
    TextInput,
    SelectInput,
    FormSection,
    Form,
    ProfileCard,
    Navbar,
    FooterComponent,
  },
  data() {
    return {
      progressIndicatorVisible: false,
      userInfo: {},
      passwordRenew: {
        oldPassword: "",
        password1: "",
        password2: "",
      },
      preferences: {}
    };
  },
  created() {
    const { name, surname, email, id } = JSON.parse(localStorage.getItem("user"));
    this.userInfo.name = name;
    this.userInfo.surname = surname;
    this.userInfo.email = email;
    this.userInfo.id = id;

    this.preferences.tempUnit = this.$cookies.get("tempUnit") ?? "C";
  },
  methods: {
    ...mapActions("authentication", ["changePassword", "changeNameAndSurname"]),
    onUpdateUserNameClicked() {

      this.progressIndicatorVisible = true;

      this.changeNameAndSurname({
        id: this.userInfo.id,
        data: {
          name: this.userInfo.name,
          surname: this.userInfo.surname
        }
      }).then(() => {

        successToast(this.$t("messages.updatedSuccesfully"));

        localStorage.setItem("name", this.userInfo.name);
        localStorage.setItem("surname", this.userInfo.surname);

        let user = JSON.parse(localStorage.getItem("user"));
        user.name = this.userInfo.name;
        user.surname = this.userInfo.surname;

        localStorage.setItem("user", JSON.stringify(user));
      })
        .catch(() => errorToast(this.$t("messages.updateFailed")))
        .finally(() => this.progressIndicatorVisible = false);
    },
    onUpdatePasswordClicked() {

      this.progressIndicatorVisible = true;

      this.changePassword({
        oldPassword: this.passwordRenew.oldPassword,
        newPassword: this.passwordRenew.password1,
        newPasswordConfirm: this.passwordRenew.password2,
      }).then(() => successToast(this.$t("auth.passwordChangeSuccess")))
        .catch((e) => errorToast(this.$t(`auth.${e.response.data.error}`)))
        .finally(() => {
          this.progressIndicatorVisible = false;

          this.passwordRenew.oldPassword = "";
          this.passwordRenew.password1 = "";
          this.passwordRenew.password2 = "";
        });
    },
    tempUnitChanged() {
      this.$cookies.set("tempUnit", this.preferences.tempUnit);
      successToast(this.$t("profile.settingsUpdated"));
    }
  },
  computed: {
    updateUserInfoEnabled() {
      return this.userInfo.name.length > 0 && this.userInfo.surname.length > 0;
    },
    updatePasswordEnabled() {
      return this.passwordRenew.oldPassword.length > 3 && this.passwordRenew.password1.length > 3 && this.passwordRenew.password2.length > 3;
    }
  },
};
</script>