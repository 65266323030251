<template>
    <div class="flex flex-wrap w-full z-10">
        <ClickableTable :title="$t('menu.devices')" tableId="devices" :headers="headers" :rows="rows"
            @onClick="onDeviceClicked" />
    </div>
    <PulseLoader class="text-center" :loading="busy" />

    <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      rounded
      mb-6
      xl:mb-0
      shadow-lg
    ">
        <div class="flex-auto p-4">
            <div class="flex flex-wrap">
                <RouterButton :title="$t('devices.addNewDevice')" :route-to="{ name: 'addDevices' }"
                    icon-class="fas fa-plus" />
            </div>
        </div>
    </div>
</template>

<script>
import RouterButton from '../../../components/Buttons/RouterButton'
import moment from "moment";
import ClickableTable from "../../../components/Tables/ClickableTable.vue";
import { base64UrlObjectEncode } from "../../../helpers/encoing-helper";
import { getDevices } from "../../../services/admin.service";

export default {
    data() {
        return {
            headers: [
                { langKey: "devices.id", key: "id" },
                { langKey: "devices.serialNo", key: "serialNo" },
                { langKey: "devices.mac", key: "mac" },
                { langKey: "devices.name", key: "name" },
                { langKey: "devices.projectName", key: "projectName" },
                { langKey: "devices.addingtime", key: "addingtime" },
                { langKey: "admin.adder", key: "adder" },
                { langKey: "admin.owner", key: "owner" },
                { langKey: "admin.activationCode", key: "activation" },
                { langKey: "devices.actions", key: "actions" },
            ],
            values: [],
            busy: false,
        };
    },
    created() {
        this.busy = true;
        getDevices()
            .then((res) => (this.values = res))
            .finally(() => (this.busy = false));
    },
    methods: {
        onDeviceClicked(index, item) {

            this.$router.push({
                name: "editDevices",
                params: {
                    device: base64UrlObjectEncode(this.values[index]),
                },
            });
        },
    },
    components: {
        RouterButton,
        ClickableTable,
    },
    computed: {
        rows() {
            if (!Array.isArray(this.values)) return [];

            return this.values.map((x) => {

                return {
                    columns: [
                        { id: x.id },
                        { serialNo: x.serialno },
                        { mac: x.deviceid },
                        { name: x.name },
                        { projectName: x.projectInfo.name },
                        { addingtime: moment(x.addingtime).format("YYYY-MM-DD") },
                        { adder: x.user && (`${x.user.name} ${x.user.surname}\n(${x.user.email})`) },
                        { owner: x.userAdmin && (`${x.userAdmin.name} ${x.userAdmin.surname}\n(${x.userAdmin.email})`) },
                        { activation: x.activation },
                    ],
                    buttons: [{
                        langKey: "devices.edit",
                        routerLink: {
                            name: "editDevices",
                            params: { device: base64UrlObjectEncode(x) },
                        },
                        iconClass: "far fa-edit",
                    }],
                };
            });
        },
    },
};
</script>