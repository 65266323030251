import { userService, ServerService } from "../../services";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, { username, password }) {
      commit("loginRequest", { username });
      return new Promise((resolve, reject) => {
        userService
          .login(username, password)
          .then(
            (res) => {

              const user = res.data;

              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem("hasLogged", true);
              localStorage.setItem("uid", user.id);
              localStorage.setItem("name", user.name);
              localStorage.setItem("surname", user.surname);
              localStorage.setItem("accessToken", user.accessToken);
              localStorage.setItem("refreshToken", user.refreshToken);
              localStorage.setItem("user", JSON.stringify(user));

              commit("loginSuccess", res);
              resolve(res);
            },
            (error) => {
              commit("loginFailure", error);
              reject(error.response.data);
            }
          )
          .catch((err) => reject(err));
      });
    },
    logout({ commit }) {
      userService.logout();
      commit('logout');
    },
    register({ commit }, data) {
      return new Promise((resolve, reject) => {
        userService
          .register(data)
          .then(
            (res) => {
              resolve(res);
            },
            (error) => {
              reject(error);
            }
          )
          .catch((err) => reject(err));
      });
    },
    verify({ commit }, data) {
      return userService.verify(data);
    },
    resendVerifyMail({ commit }, data) {
      return userService.resendVerifyMail(data);
    },
    forgetPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        userService
          .forgetPassword(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
    resetPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        userService
          .resetPassword(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
    changePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        userService
          .changePassword(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
    changeNameAndSurname({ commit }, data) {
      return new Promise((resolve, reject) => {
        userService
          .updateNameAndSurname(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
    checkServerisActive({ commit }) {
      return new Promise((resolve, reject) => {
        ServerService.checkIsActive()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    refreshToken({ commit }) {
      return new Promise((resolve, reject) => {
        userService
          .refreshToken()
          .then(
            (res) => {

              const data = res.data;

              localStorage.setItem("accessToken", data.newAccessToken);
              localStorage.setItem("refreshToken", user.newRefreshToken);

              resolve(res);
            },
            (error) => {
              reject(error);
            }
          )
          .catch((err) => reject(err));
      });
    }
  },
  mutations: {
    loginRequest(state, data) {
      state.status = { loggingIn: true };
      state.user = data;
    },
    loginSuccess(state, data) {
      state.status = { loggedIn: true };
      state.user = data;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
  getters: {
    isLogined: (state) => {
      return state.status.loggedIn;
    },
    loginState: (state) => {
      return state.user == null ? false : true;
    },
    userRole: (state) => {
      // console.info(state);

      const { role } = JSON.parse(localStorage.getItem("user"));
      return role;
    },
  },
};
