import { app } from '../main'

export function successToast(message) {

    app.$toast.success(message, {
        // position: 'top-right'
    })
}

export function errorToast(message) {

    app.$toast.error(message, {
        // position: 'top-right'
    })
}

export function infoToast(message) {

    app.$toast.info(message, {
        // position: 'top-right'
    })
}

export function warningToast(message) {

    app.$toast.warning(message, {
        // position: 'top-right'
    })
}