import { ProgramsService } from "../../services";

export const programs = {
    namespaced: true,
    state: {
        failedError: null,
    },
    actions: {
        getApollo32Schedules({ commit }) {

            return new Promise((resolve, reject) => {

                ProgramsService.getApollo32Schedules()
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        addNewApollo32Schedule({ commit }, data) {

            return new Promise((resolve, reject) => {

                ProgramsService.postApollo32Schedule(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        updateApollo32Schedule({ commit }, { id, data }) {

            return new Promise((resolve, reject) => {

                ProgramsService.putApollo32Schedule({ id, data })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    })
            })
        },
        removeApollo32Schedule({ commit }, id) {

            return new Promise((resolve, reject) => {

                ProgramsService.deleteApollo32Schedule(id)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getApollo32ScheduleData({ commit }, id) {

            return new Promise((resolve, reject) => {

                ProgramsService.getApollo32ScheduleData(id)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        addApollo32ScheduleData({ commit }, { id, data }) {

            return new Promise((resolve, reject) => {

                ProgramsService.postApollo32ScheduleData({ id, data })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getApollo32ExceptionsData({ commit }, id) {

            return new Promise((resolve, reject) => {

                ProgramsService.getApollo32ExceptionsData(id)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        getAllFailure(state, data) {
            state.failedError = data;
        },
    },
    getters: {
    },
};