export const config = {
  //apiUrl: "http://localhost:3000",
  //socketUrl: "http://localhost",
  apiUrl: "https://iotakecloud.com:3000",
  socketUrl: "https://iotakecloud.com",
  //apiUrl: "http://192.168.24.207:3000",
  //socketUrl:"http://192.168.25.118",
  //apiUrl:"http://192.168.25.118:3000",
  //socketUrl: "http://192.168.24.241",
};

export const api_routes = {
  Subscription: { url: "/subscription", method: { post: true } },
  Subscriptions: { url: "/subscription/:deviceid", method: { post: true } },
  UserDevices: { url: "/userdevices", method: { get: true } },
  GetUserDevices: { url: "/userdevices/:id", method: { get: true } },
  UpdateUserDevice: { url: "/userdevices/:id", method: { put: true } },
  DeleteUserDevice: { url: "/userdevices/:id", method: { delete: true } },
  AddDevice: { url: "/adddevice", method: { post: true } },
  Login: { url: "/login", method: { post: true } },
  Register: { url: "/register", method: { post: true } },
  Verify: { url: "/activations", method: { post: true } },
  Activations: { url: "/activations", method: { post: true } },
  RefreshToken: { url: "/auth/refreshtoken", method: { post: true } },
  Products: { url: "/products", method: { get: true } },
  ProductId: { url: "/product/:id", method: { get: true } },

  userProjects: { url: "/user/projects", method: { get: true } },

  grouping: {
    url: "/user/groups",
    devicesUrl: "/user/group/devices",
    method: { get: true, post: true, delete: true, put: true },
  },
  batchWorking: {
    batchWorkingProfiles:
    {
      url: "/user/batches",
      method: { get: true },
    },
  },
  apollo32: {
    schedules: {
      url: "/apollo32/schedules",
    },
    schedulesData: {
      url: "/apollo32/scheduledata",
    },
    exceptions: {
      url: "/apollo32/exceptions",
    },
    profile: {
      get: "/apollo32/get/schedule",
      set: "/apollo32/set/schedule",
    },
    settemp: {
      get: "/apollo32/get/temp",
      set: "/apollo32/set/temp",
    },
    getBatch: {
      get: "/apollo32/get/batch",
    },
    getBatchName: {
      get: "/apollo32/get/batchname",
    },
    getSystemInfo: {
      get: "/apollo32/apolloSystemInfo",
    }
  }
};

export function selectAxios(type, axios, url, options) {
  switch (type) {
    case "get":
      return axios.get(url, options);
    case "post":
      return axios.post(url, options);
    case "put":
      return axios.put(url, options);
    case "patch":
      return axios.patch(url, options);
    default:
      break;
  }
}