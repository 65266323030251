<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="block w-full overflow-x-auto">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-blueGray-700 pt-2 pb-2">
              {{ $t("menu.apollo32.program.edit") }}
            </h3>
            <form>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-2/6 px-4">
                  <div class="relative w-full mb-3 pt-4">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t("programs.name") }}
                    </label>
                    <select
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="selectedProgram"
                      :disabled="progressIndicatorVisible"
                      @change="handleProgramSave"
                    >
                      <option :value="null">{{ $t("navigation.off") }}</option>

                      <option
                        v-for="program in programs"
                        :value="program.id"
                        :key="program.id"
                      >
                        {{ program.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>

            <form>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-2/6 px-4">
                  <div class="relative w-full mb-3 pt-4">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {{ $t("parameters.setTemp") }}
                    </label>
                    <input
                      type="number"
                      :min="formatTemp(0)"
                      :max="formatTemp(240)"
                      step="1"
                      maxlength="3"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 text-right"
                      v-model="setTemp"
                    />
                  </div>
                </div>
                <div
                  class="w-full lg:w-6/12 px-2 mt-3 flex justify-start items-center"
                >
                  <div class="flex items-center">
                    <span class="self-center text-ellipsis">
                      ° {{ tempUnit }}
                    </span>
                    <button
                      class="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 ease-linear transition-all disabled:opacity-20 duration-150"
                      type="button"
                      :disabled="progressIndicatorVisible"
                      @click="handleSetTempSave"
                    >
                      <i class="far fa-save"></i>
                      {{ $t("navigation.apply") }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ChartWithDataLoader
    chartIndex=""
    chartPrefix="batchAvgs"
    project="apollo32"
    :deviceId="params.id.toString()"
    :config="chartConfig"
  />

  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <div class="flex flex-wrap w-full z-10">
      <ViewTable
        :title="$t('devices.devices')"
        :headers="headers"
        :rows="devices"
      />
    </div>
  </div>

  <div
    class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg"
  >
    <div class="flex-auto p-4">
      <div class="flex flex-wrap">
        <RouterButton
          :title="$t('programs.createProgram')"
          :route-to="{ name: 'apollo32.program.create' }"
          icon-class="fas fa-plus"
        />
        <RouterButton
          :title="$t('parameters.Programlar')"
          :route-to="{ name: 'apollo32.programs' }"
          icon-class="fas fa-list"
        />
        <RouterButton
          :title="$t('devices.help')"
          route-to="/help"
          icon-class="fas fa-question-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { base64UrlObjectDecode } from "../../../../helpers/encoing-helper";
import { successToast } from "../../../../helpers/toast-helper";
import RouterButton from "../../../../components/Buttons/RouterButton.vue";
import ChartWithDataLoader from "../../../../components/DashboardV2/Chart/ChartWithDataLoader.vue";
import ViewTable from "../../../../components/Tables/ViewTable.vue";
import moment from "moment";

export default {
  data() {
    return {
      params: {},
      selectedProgram: "",
      setTemp: 0,
      programs: [],
      progressIndicatorVisible: false,
      chartConfig: {
        scales: {
          x: {
            time: true,
          },
          y: {
            auto: true,
          },
        },
        series: [
          {
            label: {
              tr: "Tarih",
              en: "Date Time",
            },
          },
          {
            label: {
              tr: "Sıcaklık",
              en: "Temperature",
            },
            width: 2,
            stroke: "red",
            type: "temp",
          },
        ],
        titles: {
          en: "Average Temperature Time Graph",
          tr: "Ortalama Sıcaklık Zaman Grafiği",
        },
      },
      tempUnit: this.$cookies.get("tempUnit") ?? "C",
      headers: [
        { langKey: "devices.mac", key: "deviceid" },
        { langKey: "devices.name", key: "name" },
        { langKey: "devices.addingtime", key: "addingtime" },
      ],
      devices: [],
    };
  },
  components: {
    RouterButton,
    ChartWithDataLoader,
    ViewTable,
  },
  created() {
    this.params = base64UrlObjectDecode(this.$route.params.profile);

    this.setHeader({ title: this.params.name, visibility: true , image: this.params.projectName });

    this.progressIndicatorVisible = true;
    this.getApollo32Schedules().then((data) => {
      this.programs = data;

      this.getApollo32BatchProfile({
        batchId: this.params.id,
      }).then(
        (profile) => (this.selectedProgram = profile ? profile.id : null)
      );
    });

    this.getApollo32BatchSetTemp({
      batchId: this.params.id,
    }).then((data) => {
      this.setTemp = this.round(this.formatTemp(data.settemp ?? 0));
    });

    this.getBatchWorkingProfileDevices({
      project: this.params.project,
      batchId: this.params.id,
    })
      .then((data) => {
        let result = [];

        data.forEach((e) => {
          const device = e.device;
          result.push({
            deviceid: device.deviceid,
            addingtime: device.addingtime,
            id: device.id,
            name: e.userDevice.name,
          });
        });

        this.devices = this.createRows(
          result.sort((a, b) => new Date(b.addingtime) - new Date(a.addingtime))
        );
      })
      .finally(() => (this.progressIndicatorVisible = false));
  },
  methods: {
    ...mapMutations("headerStats", ["setHeader"]),
    ...mapActions("programs", ["getApollo32Schedules"]),
    ...mapActions("batchWorking", [
      "setApollo32BatchProfile",
      "getApollo32BatchProfile",
      "setApollo32BatchSetTemp",
      "getApollo32BatchSetTemp",
      "getBatchWorkingDevices",
      "getBatchWorkingProfileDevices",
    ]),
    handleProgramSave() {
      const programId = this.selectedProgram;

      this.progressIndicatorVisible = true;
      this.setApollo32BatchProfile({
        batchId: this.params.id,
        scheduleTypeId: programId ? programId : null,
      })
        .then(() => successToast(this.$t("messages.settingsUpdated")))
        .finally(() => (this.progressIndicatorVisible = false));
    },
    handleSetTempSave() {
      this.progressIndicatorVisible = true;

      this.setApollo32BatchSetTemp({
        batchId: this.params.id,
        temperature:
          this.tempUnit == "F" ? this.toCelsius(this.setTemp) : this.setTemp,
      })
        .then(() => successToast(this.$t("messages.settingsUpdated")))
        .finally(() => (this.progressIndicatorVisible = false));
    },
    createRows(values) {
      if (!Array.isArray(values)) return [];

      return values.map((x) => {
        return {
          columns: [
            { deviceid: x.deviceid },
            { name: x.name },
            { addingtime: moment(x.addingtime).format("YYYY-MM-DD") },
          ],
          deviceId: x.id,
        };
      });
    },
    getSelectedDeviceIds() {
      return this.devices.filter((e) => e.selected).map((e) => e.deviceId);
    },
    formatTemp(temp) {
      return this.tempUnit == "F" ? this.toFahrenheit(temp) : temp;
    },
    toCelsius(temp) {
      return (temp - 32) / 1.8;
    },
    toFahrenheit(temp) {
      return temp * 1.8 + 32;
    },
    round(number) {
      return Math.round(number * 10) / 10;
    },
  },
  unmounted() {
    this.setHeader({ title: "", visibility: false });
  },
};
</script>
