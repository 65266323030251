<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="block w-full overflow-x-auto">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-blueGray-700 pt-2 pb-2">
              {{ title }}
            </h3>
          </div>

          <div class="rounded">
            <VMenu :delay="{ show: 100, hide: 100 }">
              <button
                class="border px-3 py-1 rounded bg-blueGray-50 border-indigo-500 text-base focus:outline-none fas fa-file-export text-center"
              ></button>

              <template #popper>
                <div
                  v-for="(value, key, index) in ExportTypes"
                  class="text-center p-1"
                  :key="index"
                >
                  <button
                    class="py-2 px-5 text-sm hover:bg-warmGray-200 rounded transition-all w-full"
                    @click="exportClickableTable(value)"
                    v-close-popper
                  >
                    {{ key }}
                  </button>
                </div>
              </template>
            </VMenu>
          </div>

          <div class="px-1"></div>

          <div class="rounded">
            <VMenu :delay="{ show: 100, hide: 100 }">
              <button
                class="border px-4 py-1 rounded text-black placeholder-blueGray-300 bg-blueGray-50 border-indigo-500 text-base focus:outline-none"
              >
                {{ rowCount }}
              </button>

              <template #popper>
                <div
                  v-for="value in [5, 10, 15, 25, 50, 100]"
                  class="text-center p-1"
                  :key="value"
                >
                  <button
                    class="py-2 px-5 text-sm hover:bg-warmGray-200 rounded transition-all w-full"
                    @click="updateRowCount(value)"
                    v-close-popper
                  >
                    {{ value }}
                  </button>
                </div>
              </template>
            </VMenu>
          </div>

          <div class="px-1"></div>

          <div class="rounded">
            <input
              v-model.trim="searchKeyword"
              type="text"
              maxlength="255"
              class="border text-black italic placeholder-blueGray-300 bg-blueGray-50 border-indigo-500 rounded text-sm focus:outline-none w-full"
              :placeholder="$t('navigation.search')"
            />
          </div>
          <div class="px-1"></div>
          <PageNavigator :page-count="numPages" v-model="activeIndex" />
        </div>
      </div>

      <table
        :id="tableId"
        class="items-center w-full bg-transparent border-collapse"
      >
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="'header' + index"
              class="px-0 sm:px-1 md:px-2 lg:px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 text-xs uppercase border-l-0 border-r-0 whitespace-normal font-semibold"
            >
              <h5>{{ $t(header.langKey) }}</h5>
            </th>
          </tr>
        </thead>
        <TransitionGroup name="list" tag="tbody">
          <tr
            v-for="(row, index) in values"
            :key="'row' + index"
            class="hover:bg-gray-200 hover:shadow-xl cursor-pointer transition-all"
            @click="$emit('onClick', rows.indexOf(row), row)"
          >
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0+ text-xs whitespace-nowrap p-4"
              v-for="(column, index1) in row.columns"
              :key="'column' + index1"
              :data-label="$t(headers[index1].langKey)"
            >
              <div v-if="headers[index1].key == 'status'">
                <p
                  v-if="column[headers[index1].key] == true"
                  class="block text-blueGray-600 text-xs font-bold mb-2 whitespace-pre-line"
                >
                  <span class="dot green-background"></span>
                </p>
                <p
                  v-else
                  class="block text-blueGray-600 text-xs font-bold mb-2 whitespace-pre-line"
                >
                  <span class="dot red-background"></span>
                </p>
              </div>
              <div v-else-if="headers[index1].key == 'relay'">
                <p
                  v-if="column[headers[index1].key] == 'open'"
                  class="block text-blueGray-600 text-xs font-bold mb-2 whitespace-pre-line"
                >
                  <span class="dot green-background"></span>
                </p>
                <p
                  v-else-if="column[headers[index1].key] == 'close'"
                  class="block text-blueGray-600 text-xs font-bold mb-2 whitespace-pre-line"
                >
                  <span class="dot red-background"></span>
                </p>
                <p
                  v-else-if="column[headers[index1].key] == 'unknown'"
                  class="block text-blueGray-600 text-xs font-bold mb-2 whitespace-pre-line"
                >
                  <span class="dot gray-background"></span>
                </p>
                <p
                  v-else
                  class="block text-blueGray-600 text-xs font-bold mb-2 whitespace-pre-line"
                >
                  ---
                </p>
              </div>
              <div v-else>
                <p
                  class="block text-blueGray-600 text-xs font-bold mb-2 whitespace-pre-line"
                >
                  {{ column[headers[index1].key] }}
                </p>
              </div>
            </td>

            <td v-if="row.buttons" class="flex justify-center space-x-4">
              <div
                v-for="(button, index2) in row.buttons"
                :key="'button' + index2"
              >
                <router-link :to="{ ...button.routerLink }" @click.stop>
                  <i :class="button.iconClass" :title="$t(button.langKey)"></i>
                </router-link>
              </div>
            </td>
          </tr>
        </TransitionGroup>
      </table>
    </div>
  </div>
</template>

<script>
import PageNavigator from "./PageNavigator";
import { exportClickableTable, ExportTypes } from "../../helpers/data-exporter";

export default {
  components: { PageNavigator },
  props: ["title", "tableId", "headers", "rows"],
  data() {
    return {
      numPages: 0,
      activeIndex: 1,
      userId: 0,
      searchKeyword: "",
      rowCount: 5,
      ExportTypes: ExportTypes,
    };
  },
  created() {
    this.userId = JSON.parse(localStorage.getItem("user")).id;
    this.rowCount = parseInt(
      this.$cookies.get(`table_${this.userId}_${this.tableId}_rowCount`) ?? 5
    );
  },
  watch: {
    rows() {
      const length = this.rows.length;

      this.numPages =
        parseInt(length / this.rowCount) + (length % this.rowCount > 0 ? 1 : 0);

      const storedPageIndex = parseInt(
        this.$cookies.get(`table_${this.userId}_${this.tableId}`)
      );

      if (isNaN(storedPageIndex) || storedPageIndex > this.numPages)
        this.activeIndex = 1;
      else this.activeIndex = storedPageIndex;
    },
    searchKeyword() {
      this.activeIndex = 1;
    },
    activeIndex(newValue) {
      if (this.searchKeyword == "")
        this.$cookies.set(`table_${this.userId}_${this.tableId}`, newValue);
    },
  },
  computed: {
    startIndex: function () {
      return (this.activeIndex - 1) * this.rowCount;
    },
    endIndex: function () {
      return this.startIndex + this.rowCount;
    },
    values() {
      if (this.searchKeyword == "") {
        this.numPages =
          parseInt(this.rows.length / this.rowCount) +
          (this.rows.length % this.rowCount > 0 ? 1 : 0);

        return this.rows.slice(this.startIndex, this.endIndex);
      }

      let result = [];

      for (let i = 0; i < this.rows.length; i++) {
        const element = this.rows[i].columns;

        if (this.searchObject(element) == true) result.push(this.rows[i]);
      }

      this.numPages =
        parseInt(result.length / this.rowCount) +
        (result.length % this.rowCount > 0 ? 1 : 0);

      return result.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    searchObject(obj) {
      for (var x in obj) {
        if (typeof obj[x] === "string") {
          if (obj[x].toLowerCase().includes(this.searchKeyword.toLowerCase()))
            return true;
        } else if (this.searchObject(obj[x]) === true) return true;
      }

      return false;
    },
    updateRowCount(value) {
      this.rowCount = value;
      this.$cookies.set(
        `table_${this.userId}_${this.tableId}_rowCount`,
        this.rowCount
      );

      this.activeIndex = 1;
    },
    exportClickableTable(type) {
      exportClickableTable(this, type);
    },
  },
};
</script>

<style>
table {
  width: 100%;
  overflow: hidden;
}

th {
  border: 0px solid black;
  border-bottom: 2px solid white;
  border-collapse: collapse;
  font-size: 32px;
  font-weight: bold;
}

table,
td {
  border: 0px solid black;
  border-collapse: collapse;
  font-size: 20px;
  font-weight: bold;
}

th,
td {
  padding: 15px;
  text-align: left;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

/* table responsive */

table {
  border: 0px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table button {
  font-size: 0.7rem;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  border-bottom: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.green-background {
  background-color: #26d123;
}

.red-background {
  background-color: rgb(240, 106, 106);
}

.gray-background {
  background-color: gray;
}

@media screen and (max-width: 800px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.15s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(10px);
}
</style>
