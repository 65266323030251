<template>
    <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-6/12 px-4">
                <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                    <div class="flex-auto px-4 lg:px-10 py-10">
                        <div class="text-blueGray-800 text-center font-bold text-xl">
                            <h1>{{ $t("auth.activationInProgress") }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions } from "vuex";
export default {
    methods: {
        ...mapActions("authentication", ["verify"]),
    },
    created() {
        const params = this.$route.query;

        this.verify({ email: params.email, salt: params.salt })
            .then(() => this.$router.push("/auth/login"))
            .catch(() => this.$swal({ icon: 'error', title: this.$t('errors.errorOccured') }));
    }
}
</script>