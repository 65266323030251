import { BatchWorkingService } from "../../services";

export const batchWorking = {
    namespaced: true,
    state: {
        profiles: [],
        failedError: null,
    },
    actions: {
        getBatchWorkingProfiles({ commit }) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.getBatchWorkingProfiles()
                    .then((res) => {
                        commit("setProfiles", res.data);
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getBatchWorkingDevices({ commit }, project) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.getBatchWorkingDevices({ project: project })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        addBatchWorkingProfile({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.postBatchWorkingProfile(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        updateBatchWorkingProfile({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.putBatchWorkingProfile(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        removeBatchWorkingProfile({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.removeBatchWorkingProfile(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        addBatchWorkingeDevices({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.postBatchWorkingDevices(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getBatchWorkingProfileDevices({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.getBatchWorkingProfileDevices(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        setApollo32BatchProfile({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.postApollo32BatchWorkingProfile(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getApollo32BatchProfile({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.getApollo32BatchWorkingProfile(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        setApollo32BatchSetTemp({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.postApollo32BatchWorkingSetTemp(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getApollo32BatchSetTemp({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.getApollo32BatchWorkingSetTemp(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getApollo32DeviceBatchProfile({ commit }, data) {

            return new Promise((resolve, reject) => {

                BatchWorkingService.getApollo32DeviceBatchWorkingProfile(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        setProfiles(state, data) {
            state.profiles = data;
        },
        getAllFailure(state, data) {
            state.failedError = data;
        },
    },
    getters: {
        allProfiles: (state) => {
            return state.profiles;
        },
    },
};