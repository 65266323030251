<template>
  <div>
    <div class="
        relative
        flex flex-col
        min-w-0
        break-words
        w-full
        h-full
        shadow-md
        rounded
        p-4
        hover:shadow-lg
        outline-none
        focus:outline-none
        ease-linear
        transition-all
        duration-150
      " :class="editEnabled ? ' cursor-pointer' : 'cursor-not-allowed'" @click="$emit('editEntry', index)">
      {{ index + 1 }}: {{ $t("programs.date") }}:
      {{ new Date(date).toLocaleDateString() }}.
      {{ $t("programs.description") }}:
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: 0,
    date: null,
    description: null,
    editEnabled: {
      type: Boolean,
      default: true
    }
  },
};
</script>