<template>
    <Card>
        <Toolbar :title="$t('menu.editDevice')">
            <Button :title="$t('devices.removeDevice')" icon-class="far fa-trash-alt" background-color="bg-red-500"
                :disabled="progressIndicatorVisible" @click="$router.push({ name: 'removeDevices' })" />

            <Button :title="$t('navigation.save')" icon-class="far fa-save" background-color="bg-blue-500"
                :disabled="progressIndicatorVisible || !saveButtonEnabled" @click="handleSave" />
        </Toolbar>

        <Form>
            <FormSection :title="$t('devices.deviceInfo')">
                <TextInput v-model.trim="params.deviceid" :maxLength="255" :title="$t('devices.mac')"
                    placeHolder="12:34:56:78:90:00" :disabled="progressIndicatorVisible" @keyup="fixedMacAddress()" />

                <TextInput v-model.trim="params.name" :maxLength="255" :title="$t('devices.name')"
                    :placeHolder="$t('devices.name')" :disabled="progressIndicatorVisible" />

                <TextInput v-model.trim="params.projectInfo.name" :maxLength="255" :title="$t('devices.projectName')"
                    :placeHolder="$t('devices.projectName')" :disabled="true" />

                <TextInput v-model.trim="params.activation" :maxLength="32" :title="$t('devices.acitvationCode')"
                    :placeHolder="$t('devices.acitvationCode')" :disabled="progressIndicatorVisible" />

                <TextInput v-model.trim="params.serialno" :maxLength="512" :title="$t('devices.serialNo')"
                    :placeHolder="$t('devices.serialNo')" :disabled="progressIndicatorVisible" />
            </FormSection>

            <FormSection :title="$t('admin.userInfo')">
                <TextInput v-model.trim="adderEmail" :maxLength="255" :title="$t('admin.adder')"
                    :placeHolder="$t('admin.adder')" type="email" :disabled="true" />

                <TextInput v-model.trim="userAdminEmail" :maxLength="255" :title="$t('admin.owner')"
                    :placeHolder="$t('admin.owner')" type="email" :disabled="progressIndicatorVisible" />
            </FormSection>

            <PulseLoader class="text-center" :loading="progressIndicatorVisible" />
        </Form>
    </Card>
</template>

<script>
import Card from "../../../components/Cards/Card";
import Button from "../../../components/Buttons/Button";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import TextInput from "../../../components/Forms/TextInput.vue";
import Toolbar from "../../../components/Forms/Toolbar.vue";
import FormSection from "../../../components/Forms/FormSection.vue";
import Form from "../../../components/Forms/Form.vue";
import { successToast, errorToast } from "../../../helpers/toast-helper";
import { updateDevice } from "../../../services/admin.service";

export default {
    components: {
        Card,
        Button,
        TextInput,
        Toolbar,
        FormSection,
        Form,
    },
    data() {
        return {
            params: {},
            adderEmail: "",
            userAdminEmail: "",
            progressIndicatorVisible: false,
        };
    },
    created() {
        this.params = base64UrlObjectDecode(this.$route.params.device);

        if (this.params.user) this.adderEmail = this.params.user.email;

        if (this.params.userAdmin)
            this.userAdminEmail = this.params.userAdmin.email;
    },
    methods: {
        handleSave() {
            this.progressIndicatorVisible = true;

            updateDevice({
                id: this.params.id,
                data: {
                    deviceId: this.params.deviceid,
                    name: this.params.name,
                    serialNo: this.params.serialno,
                    activation: this.params.activation,
                    admin: this.userAdminEmail,
                },
            })
                .then(() => successToast(this.$t("messages.updatedSuccesfully")))
                .catch((err) => {
                    if (
                        err.response.data &&
                        err.response.data.errorCode == "userNotFound"
                    )
                        errorToast(this.$t("admin.userNotFound"));
                    else errorToast(this.$t("messages.updateFailed"));
                })
                .finally(() => (this.progressIndicatorVisible = false));
        },
        fixedMacAddress() {
            let data = this.params.deviceid;
            data = data.replace(/([^0-9a-fA-F])*/g, "");

            if (data.length > 0)
                data = data
                    .match(/.{1,2}/g) // ["4a", "89", "26", "c4", "45", "78"]
                    .join(":");

            this.params.deviceid = data.slice(0, 17).toUpperCase();
        },
        validateEmail() {
            return this.userAdminEmail.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        },
    },
    computed: {
        saveButtonEnabled() {
            return this.params.name.length > 0 && this.params.deviceid.length == 17;
        },
    },
};
</script>