<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div class="
            relative
            flex flex-col
            min-w-0
            break-words
            w-full
            mb-6
            shadow-lg
            rounded-lg
            bg-blueGray-200
            border-0
          ">
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold mt-4">
              <small>{{ $t("auth.loginTitle") }}</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  " htmlFor="grid-password">
                  {{ $t("auth.email") }}
                </label>
                <input v-model="mail" type="email" @keypress.enter="handleSubmit()" class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  " :placeholder="$t('auth.email')" />
              </div>

              <div class="relative w-full mb-3">
                <label class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  " htmlFor="grid-password">
                  {{ $t("auth.password") }}
                </label>
                <input v-model="password" type="password" @keypress.enter="handleSubmit()" class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  " :placeholder="$t('auth.password')" />
              </div>
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input id="customCheckLogin" type="checkbox" class="
                      form-checkbox
                      border-0
                      rounded
                      text-blueGray-700
                      ml-1
                      w-5
                      h-5
                      ease-linear
                      transition-all
                      duration-150
                    " />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    {{ $t("auth.rememberme") }}
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button class="
                    bg-blueGray-800
                    text-white
                    active:bg-blueGray-600
                    text-sm
                    font-bold
                    uppercase
                    px-6
                    py-3
                    rounded
                    shadow
                    hover:shadow-lg
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                    disabled:opacity-20
                  " type="button" @click="handleSubmit()" :disabled="!validate">
                  {{ $t("auth.signin") }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/auth/forget" class="text-blueGray-200 hover:text-blue-400 transition-all">
              <small>{{ $t("auth.forgetpassword") }}</small>
            </router-link>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200 hover:text-blue-400 transition-all">
              <small>{{ $t("auth.createnew") }}</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      ...mapState("authentication", ["user"]),
      mail: "",
      password: "",
    };
  },
  created() {
    if (localStorage.getItem("accessToken")) this.$router.push("/");
  },
  methods: {
    ...mapActions("authentication", ["login", "logout", "resendVerifyMail"]),
    handleSubmit() {
      this.login({ username: this.mail, password: this.password })
        .then(() => {
          this.$router.push("/admin/mydevices");
        })
        .catch((e) => {

          if (e.type == "authError") {
            this.$swal({
              icon: 'error',
              title: this.$t("auth.authError")
            });
            return;
          }

          if (e.type == "verifyError") {
            this.$swal({
              icon: 'error',
              title: this.$t("auth.verifyRequired"),
              text: this.$t("auth.resendVerifyMail"),
              showDenyButton: true,
              denyButtonText: this.$t("contact.send"),
            }).then((result) => {
              if (result.isDenied) {

                this.resendVerifyMail({ email: this.mail })
                  .then(() => {
                    this.$swal({
                      title: this.$t("auth.verifyMailSended")
                    });
                  })
                  .catch(() => {
                    this.$swal({
                      icon: 'error',
                      title: this.$t("errors.errorOccured")
                    });
                  });
              }
            })
            return;
          }

          this.$swal({
            icon: 'error',
            title: this.$t("errors.errorOccured")
          });
        });
    },
    validateEmail() {
      return this.mail.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
  },
  watch: {
    loginState() {
      if (this.loginState) this.$router.push("/admin/mydevices");
    },
  },
  computed: {
    ...mapGetters("authentication", ["loginState"]),
    validate() {
      return this.validateEmail() && this.password.length >= 4;
    }
  },
};
</script>