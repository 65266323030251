<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    ">
    <div class="rounded bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        {{ $t("errors.errorOccured") }}
      </div>
    </div>
  </div>
</template>