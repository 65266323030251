<template>
    <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            {{ title }}
        </h6>

        <div class="flex flex-wrap">
            <slot></slot>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            reqired: true
        }
    }
}
</script>