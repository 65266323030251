import { config, api_routes } from "./config";
import { authHeader } from "../helpers";
const currentUserSubject = JSON.parse(localStorage.getItem("user"));
const getData = () => {
  return JSON.parse(localStorage.getItem("user"));
};

import axios from "axios";
import i18n from "../i18n";

export const userService = {
  login,
  logout,
  register,
  verify,
  resendVerifyMail,
  resetPassword,
  getAll,
  forgetPassword,
  changePassword,
  getAllUserProjects,
  refreshToken,
  updateNameAndSurname,
  currentUser: getData(),
};

function login(username, password) {

  return axios.post(`${config.apiUrl}/auth/login`, {
    data: { email: username, password: password }
  });
}

function logout() {
  // remove user from local storage to log user out
  const lang = localStorage.getItem("lang");
  localStorage.clear();

  localStorage.setItem("lang", lang);
}

function register(data) {
  return axios.post(`${config.apiUrl}/auth/register`, {
    data
  });
}

function verify(data) {

  return axios.post(`${config.apiUrl}/auth/activations`, {
    data
  });
}

function resendVerifyMail(data) {

  return axios.post(`${config.apiUrl}/auth/resendVerifyMail`, {
    data
  });
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function forgetPassword(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/auth/forgetPassword`, {
        data,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function resetPassword(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/auth/renewPassword`, {
        data
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function changePassword(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/auth/change-password`, {
        headers: authHeader(),
        data
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateNameAndSurname({ id, data }) {

  return new Promise((resolve, reject) => {
    axios
      .put(`${config.apiUrl}/auth/user/${id}`, {
        headers: authHeader(),
        data
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function handleResponse(response) {
  return response.json().then((json) => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (json && json.message[i18n.global.locale]) || response.statusText;
      return Promise.reject(error);
    }

    return json;
  });
}

function getAllUserProjects() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}${api_routes.userProjects.url}`, {
        headers: authHeader(),
      })
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
}

function refreshToken() {

  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}${api_routes.RefreshToken.url}`, {
        data: {
          refreshToken: localStorage.getItem("refreshToken")
        }
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}