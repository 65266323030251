<template>
  <nav class="
      md:left-0
      md:block
      md:fixed
      md:top-0
      md:bottom-0
      md:overflow-y-auto
      md:flex-row
      md:flex-nowrap
      md:overflow-hidden
      shadow-xl
      bg-white
      flex flex-wrap
      items-center
      justify-between
      relative
      md:w-64
      z-10
      py-4
      px-6
    ">
    <div class="
        md:flex-col md:items-stretch md:min-h-full md:flex-nowrap
        px-0
        flex flex-wrap
        items-center
        justify-between
        w-full
        mx-auto
      ">
      <button class="
          cursor-pointer
          text-white
          opacity-50
          md:hidden
          px-3
          py-1
          text-xl
          leading-none
          bg-transparent
          rounded
          border border-solid border-transparent
        " type="button" v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')">
        <i class="fas fa-bars text-black"></i>
      </button>

      <router-link class="
          md:block
          text-left
          md:pb-2
          text-blueGray-600
          mr-0
          flex
          whitespace-nowrap
          text-sm
          uppercase
          font-bold
          p-4
          px-0
        " to="/">
        <img alt="logo" src="/images/logo.png" style="height: 20px" height="20" /><span> IoT Cloud</span>
      </router-link>

      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>

      <!-- Collapse -->
      <div class="
          md:flex
          md:flex-col
          md:items-stretch
          md:opacity-100
          md:relative
          md:mt-4
          md:shadow-none
          shadow
          absolute
          top-0
          left-0
          right-0
          z-40
          overflow-y-auto overflow-x-hidden
          h-auto
          items-center
          flex-1
          rounded
        " v-bind:class="collapseShow">
        <!-- Collapse header -->
        <div class="
            md:min-w-full md:hidden
            block
            pb-4
            mb-4
            border-b border-solid border-blueGray-200
          ">
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link class="
                  md:block
                  text-left
                  md:pb-2
                  text-blueGray-600
                  mr-0
                  inline-block
                  whitespace-nowrap
                  text-sm
                  uppercase
                  font-bold
                  p-4
                  px-0
                " to="/">
                IoT Ake Cloud
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button type="button" class="
                  cursor-pointer
                  text-black
                  opacity-50
                  md:hidden
                  px-3
                  py-1
                  text-xl
                  leading-none
                  bg-transparent
                  rounded
                  border border-solid border-transparent
                " v-on:click="toggleCollapseShow('hidden')">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6 class="
            md:min-w-full
            text-blueGray-500 text-xs
            uppercase
            font-bold
            block
            pt-1
            pb-4
            no-underline
          ">
          {{ $t("menu.menu") }}
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link to="/admin/mydevices" v-slot="{ href, navigate, isActive }">
              <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block transition-all" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
                <i class="fas fa-tv mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                {{ $t("menu.mydevices") }}
              </a>
            </router-link>
          </li>

          <!-- <li class="items-center">
            <router-link to="/admin/groups" v-slot="{ href, navigate, isActive }">
              <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block transition-all" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
                <i class="fas fa-layer-group mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                {{ $t("menu.groups") }}
              </a>
            </router-link>
          </li> -->

          <li class="items-center">
            <router-link to="/admin/batchworking" v-slot="{ href, navigate, isActive }">
              <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block transition-all" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
                <i class="fas fa-link mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                {{ $t("menu.groups") }}
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <router-link to="/profile" v-slot="{ href, navigate, isActive }">
              <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block transition-all" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
                <i class="fas fa-user mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                {{ $t("menu.account") }}
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <router-link to="/help" v-slot="{ href, navigate, isActive }">
              <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block transition-all" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
                <i class="fas fa-question-circle mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                {{ $t("devices.help") }}
              </a>
            </router-link>
          </li>
        </ul>

        <span v-if="isSuperUser || isStaff">
          <!-- Divider -->
          <hr class="my-4 md:min-w-full" />
          <!-- Heading -->
          <h6 class="
              md:min-w-full
              text-blueGray-500 text-xs
              uppercase
              font-bold
              block
              pt-1
              pb-4
              no-underline
            ">
            {{ $t("menu.devicesmenu") }}
          </h6>
          <!-- Navigation -->
          <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">

            <li class="items-center">
              <router-link to="/admin/panel/devices" v-slot="{ href, navigate, isActive }">
                <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block transition-all" :class="[
                  isActive
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]">
                  <i class="fas fa-tv mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                  {{ $t("menu.devices") }}
                </a>
              </router-link>
            </li>

            <li v-if="isSuperUser" class="items-center">
              <router-link :to="{ name: 'projects' }" v-slot="{ href, navigate, isActive }">
                <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block transition-all" :class="[
                  isActive
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]">
                  <i class="fas fa-ribbon mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                  {{ $t("menu.projects") }}
                </a>
              </router-link>
            </li>

            <li v-if="isSuperUser" class="items-center">
              <router-link to="/admin/panel/users" v-slot="{ href, navigate, isActive }">
                <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block transition-all" :class="[
                  isActive
                    ? 'text-red-500 hover:text-red-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]">
                  <i class="fas fa-user mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                  {{ $t("menu.users") }}
                </a>
              </router-link>
            </li>
          </ul>
        </span>
      </div>
      <!-- <locale-changer></locale-changer> -->
    </div>
  </nav>
</template>

<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import LocaleChanger from "../LocaleChanger.vue";
import { mapGetters } from "vuex";
import * as jose from "jose";

export default {
  data() {
    return {
      collapseShow: "hidden",
      isSuperUser: false,
      isStaff: false
    };
  },
  created() {
    const token = jose.decodeJwt(localStorage.getItem("accessToken"));

    if (token) {

      this.isSuperUser = token.isSuperUser;
      this.isStaff = token.isStaff;
    }
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
  },
  components: {
    UserDropdown,
    LocaleChanger,
  },
  computed: {
    ...mapGetters("authentication", ["userRole"]),
  },
};
</script>