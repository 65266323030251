<template>
  <!-- component -->
  <!-- component -->
  <div
    class="relative md:pt-32 pb-32 pt-12"
    style="background-color: rgb(5, 150, 105)"
  >
    <dl class="grid grid-cols-1 gap-4 sm:grid-cols-3" style="background-color: #ddfff7">
      <div
        class="flex flex-col ml-3 rounded-lg border border-gray-100 px-4 py-8 text-center"
      >
        <dt class="order-last text-lg font-medium text-gray-500">
          {{ $t("dashboardheader.deviceCount") }}
        </dt>

        <dd class="text-4xl font-medium text-blue-600 md:text-5xl">{{ getHeaderData.deviceCount }}</dd>
      </div>

      <div
        class="flex flex-col rounded-lg border border-gray-100 px-4 py-8 text-center"
      >
        <dt class="order-last text-lg font-medium text-gray-500">
          {{ $t("dashboardheader.activeDeviceCount") }}
        </dt>

        <dd class="text-4xl font-medium text-blue-600 md:text-5xl">{{ getHeaderData.activeDeviceCount }}</dd>
      </div>

      <div
        class="flex flex-col mr-3 rounded-lg border border-gray-100 px-4 py-8 text-center"
      >
        <dt class="order-last text-lg font-medium text-gray-500">
          {{ $t("dashboardheader.activeRelayCount") }}
        </dt>

        <dd class="text-4xl font-medium text-blue-600 md:text-5xl">{{ getHeaderData.activeRelayCount }}</dd>
      </div>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("dashboardheader", ["getHeaderData", "getDashboardHeaderVisibility"]),
  },
};
</script>
