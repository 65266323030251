<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {{ $t("navigation.add") }}
        </h6>
        <button class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            disabled:opacity-20
            duration-150
          " type="button" :disabled="progressIndicatorVisible || !addButtonEnabled" @click="handleDeviceAdd()">
          <i class="fas fa-plus"></i>
          {{ $t("navigation.add") }}
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form autocomplete="off">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("devices.deviceInfo") }}
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("devices.deviceId") }}
              </label>
              <input v-model="newDeviceInfo.mac" @keyup="fixedMacAddress()" type="text" maxlength="17" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :disabled="progressIndicatorVisible" placeholder="12:34:56:78:90:00" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("devices.deviceName") }}
              </label>
              <input v-model.trim="newDeviceInfo.name" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :disabled="progressIndicatorVisible" :placeholder="$t('devices.deviceNamePlaceholder')" />
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("devices.deviceVerification") }}
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("devices.acitvationCode") }}
              </label>
              <input v-model="newDeviceInfo.acitvationCode" :disabled="progressIndicatorVisible"
                :placeholder="$t('devices.acitvationCode')" maxlength="10" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " />
            </div>
          </div>
        </div>
      </form>
      <PulseLoader class="text-center" :loading="progressIndicatorVisible" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { successToast } from "../../../helpers/toast-helper";

export default {
  data() {
    return {
      newDeviceInfo: {
        mac: "",
        name: "",
        acitvationCode: "",
      },
      progressIndicatorVisible: false,
    };
  },
  methods: {
    ...mapActions("users", ["addNewDeviceWithCode"]),
    fixedMacAddress() {
      let data = this.newDeviceInfo.mac;
      data = data.replace(/([^0-9a-fA-F])*/g, "");

      if (data.length > 0)
        data = data
          .match(/.{1,2}/g) // ["4a", "89", "26", "c4", "45", "78"]
          .join(":");

      this.newDeviceInfo.mac = data.slice(0, 17).toUpperCase();
    },
    handleDeviceAdd() {
      this.progressIndicatorVisible = true;

      this.addNewDeviceWithCode({
        name: this.newDeviceInfo.name,
        deviceId: this.newDeviceInfo.mac,
        code: this.newDeviceInfo.acitvationCode,
      })
        .then(() => {
          successToast(this.$t("messages.deviceAdded"));
          this.$router.push({ name: "mydevices" });
        })
        .catch((error) => {
          if (error == "deviceNotFound") {

            this.$swal({
              title: this.$t("devices.deviceNotFound"),
              icon: "error",
            });
            return;
          }

          if (error == "wrongActivationCode") {

            this.$swal({
              title: this.$t("devices.wrongActivationCode"),
              icon: "error",
            });
            return;
          }

          if (error == "alreadyAdded") {
            this.$swal({
              title: this.$t("devices.alreadyAdded"),
              icon: "info",
            });
            return;
          }

          if (error == "deviceHasHost") {
            this.$swal({
              title: this.$t("devices.deviceHasHost"),
              icon: "info",
            });
            return;
          }

          this.$swal({
            title: this.$t("errors.errorOccured"),
            icon: "error",
          });
        })
        .finally(() => (this.progressIndicatorVisible = false));
    },
  },
  computed: {
    addButtonEnabled() {
      const macValidate = this.newDeviceInfo.mac.length == 17;
      const nameValidate = this.newDeviceInfo.name.length > 0;
      const codeValidate = this.newDeviceInfo.acitvationCode.length > 0;

      return macValidate && nameValidate && codeValidate;
    },
  },
};
</script>