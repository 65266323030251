<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {{ $t("menu.removeDevice") }}
        </h6>
      </div>
    </div>

    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("devices.deviceInfo") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("devices.name") }}
              </label>
              <label class="
                  block
                  uppercase
                  text-blueGray-600 text-base
                  font-bold
                  mb-2
                ">
                {{ deviceName }}
              </label>
            </div>
          </div>
        </div>
      </form>

      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("devices.removeDevice") }}
        </h6>

        <div class="flex flex-wrap items-center gap-4">
          <div class="w-full lg:w-4/10 px-4">
            <div class="relative w-full mb-3">
              <label class="text-blueGray-600 text-sm font-bold">
                {{ $t("devices.cannotBeUndone2") }}
              </label>

              <div class="mt-5 mb-2 my-3">
                <router-link :to="{ name: 'editDevice', params: this.$route.params }">
                  <button class="
                      bg-blue-500
                      text-white
                      active:bg-blue-600
                      font-bold
                      uppercase
                      text-xs
                      px-4
                      py-2
                      rounded
                      shadow
                      hover:shadow-md
                      outline-none
                      focus:outline-none
                      mr-1
                      ease-linear
                      transition-all
                      duration-150
                    " type="button" :disabled="progressIndicatorVisible">
                    <i class="fas fa-arrow-left"></i>
                    {{ $t("navigation.back") }}
                  </button>
                </router-link>

                <button class="
                    bg-red-500
                    text-white
                    active:bg-red-600
                    font-bold
                    uppercase
                    text-xs
                    px-4
                    py-2
                    rounded
                    shadow
                    hover:shadow-md
                    outline-none
                    focus:outline-none
                    mr-1
                    ease-linear
                    transition-all
                    duration-150
                  " type="button" :disabled="progressIndicatorVisible" @click="handleDeviceRemove()">
                  <i class="far fa-trash-alt"></i>
                  {{ $t("devices.removeDevice") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <PulseLoader class="text-center" :loading="progressIndicatorVisible" />
    </div>
  </div>
</template> 

<script>
import { mapActions } from "vuex";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import { successToast } from "../../../helpers/toast-helper";

export default {
  data() {
    return {
      entryId: 0,
      deviceName: "",
      progressIndicatorVisible: false,
    };
  },
  created() {
    const params = base64UrlObjectDecode(this.$route.params.device);
    this.entryId = params.id;
    this.deviceName = params.name;
  },
  methods: {
    ...mapActions("users", ["removeDevice"]),
    handleDeviceRemove() {
      this.progressIndicatorVisible = true;

      this.removeDevice({
        device: {
          id: this.entryId,
        },
      })
        .then(() => {
          successToast(this.$t("messages.success"));
          this.$router.push("/admin/mydevices");
        })
        .catch((err) => {
          this.$swal(this.$t("errors.errorOccured"));
          console.log(err);
        })
        .finally(() => (this.progressIndicatorVisible = false));
    },
  },
};
</script>