<template>
    <div class="m-8">
        <Card class="p-8">
            <p class="text-center font-bold">401 - Unauthorised</p>
            <p class="text-center font-bold underline cursor-pointer" @click="$router.go(-2)">Go Back</p>
        </Card>
    </div>
</template>

<script>
import Card from "../../components/Cards/Card";

export default {
    components: {
        Card
    }
};
</script>