<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      h-full
      mb-6
      mt-6
      shadow-lg
      rounded
      bg-white
      border-none
      p-4
      box-border
    ">
    <div class="box-border border-none mt-1 ml-1 rounded">
      <div class="
          grid
          divide-gray-400
          border-none
          box-border
          xl:grid-cols-7 xl:divide-x
        ">
        <SchedulerItem v-for="(item, index) in days" :key="index" :day="index" :title="$t(`programs.${item}`)"
          ref="schedulerItems" :editEnabled="editEnabled" />
      </div>
    </div>
  </div>
</template>

<script>
import SchedulerItem from "../../components/Scheduler/ScheduleItem.vue";

export default {
  props: {
    editEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      schedulerItems: [],
    };
  },
  methods: {
    setData(data) {
      this.$refs.schedulerItems.forEach((element) =>
        element.setData(data[element.day])
      );
    },
    getData() {
      let result = {};

      this.$refs.schedulerItems.forEach(
        (element) => (result[element.day] = element.getData())
      );

      return result;
    },
  },
  components: {
    SchedulerItem,
  },
};
</script>