import { userService, MyDevicesService } from "../../services";

export const users = {
  namespaced: true,
  state: {
    all: {},
  },
  actions: {
    getAll({ commit }) {
      commit("getAllRequest");

      userService.getAll().then(
        (users) => commit("getAllSuccess", users),
        (error) => commit("getAllFailure", error)
      );
    },
    getMyDevices({ commit }) {
      commit("getAllRequest");
      return new Promise((resolve, reject) => {
        MyDevicesService.getMyDevices().then(
          (devices) => {
            commit("getMyDevicesSuccess", devices);
            resolve(devices);
          },
          (error) => {
            commit("getAllFailure", error);
            reject(error);
          }
        );
      });
    },
    addNewDevice({ commit }, deviceInfo) {
      return new Promise((resolve, reject) => {
        MyDevicesService.addNewDevice(deviceInfo)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addNewDeviceWithCode({ commit }, deviceInfo) {
      return new Promise((resolve, reject) => {
        MyDevicesService.addNewDeviceWithCode(deviceInfo)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editDevice({ commit }, data) {
      return new Promise((resolve, reject) => {
        MyDevicesService.editDevice(data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    removeDevice({ commit }, { device }) {
      return new Promise((resolve, reject) => {
        MyDevicesService.removeDevice(device)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    getAllUserProjects({ commit }) {
      return new Promise((resolve, reject) => {
        userService.getAllUserProjects().then(
          (projects) => {
            resolve(projects);
          },
          (error) => {
            commit("getAllFailure", error);
            reject(error);
          }
        );
      });
    },
    handDevice({ commit }, data) {
      return new Promise((resolve, reject) => {
        MyDevicesService.handDevice(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    handRemoveDevice({ commit }, data) {
      return new Promise((resolve, reject) => {
        MyDevicesService.handRemoveDevice(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDeviceOwners({ commit }, mac) {
      return new Promise((resolve, reject) => {
        MyDevicesService.getDeviceOwners(mac)
          .then((res) => resolve(res))
          .catch((error) => reject(error));
      });
    }
  },
  mutations: {
    getAllRequest(state) {
      state.all = { loading: true };
    },
    getAllSuccess(state, users) {
      state.all = { items: users };
    },
    getAllFailure(state, error) {
      state.all = { error };
    },
    getMyDevicesSuccess(state, devicesList) {
      state.devices = devicesList;
    },
  },
  getters: {
    devicesCount: (state) => {
      return state.devices.length;
    },
  },
};
