<template>
  <div class="
      flex flex-col
      justify-center
      content-center
      hover:text-blue-500
      cursor-pointer
      py-4
      px-1 md:px-2 lg:px-4
    " @click="onClick">
    <button class="focus:outline-none"></button>
    <img class="mx-auto w-8 md:w-10 lg:w-12" :src="'/images/dashboard/' + icon" :alt="title" />
    <p class="text-sm md:text-base lg:text-lg">{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  emits: ["onClick"],
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>