<template>
  <div
    class="relative md:pt-32 pb-32 pt-12"
    style="background-color: rgb(5, 150, 105)"
  >
  </div>
</template>

<script>
export default {
  
};
</script>
