<template>
  <footer class="relative bg-blueGray-200 pt-8 pb-6">
    <div
      class="
        bottom-auto
        top-0
        left-0
        right-0
        w-full
        absolute
        pointer-events-none
        overflow-hidden
        -mt-20
        h-20
      "
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full md:w-6/12 lg:w-3/12 px-4">
          <h4 class="text-3xl font-semibold">{{ $t("footer.contactus") }}</h4>
          <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
            {{ $t("footer.subtitle") }}
          </h5>
          <div class="mt-6 lg:mb-0 mb-6">
            <button
              class="
                bg-white
                text-sky-400
                shadow-lg
                font-normal
                h-10
                w-10
                items-center
                justify-center
                align-center
                rounded-full
                outline-none
                focus:outline-none
                mr-2
              "
              type="button"
              @click="socialMedia('https://twitter.com/aketroniks')"
            >
              <i class="fab fa-twitter"></i>
            </button>
            <button
              class="
                bg-white
                text-sky-600
                shadow-lg
                font-normal
                h-10
                w-10
                items-center
                justify-center
                align-center
                rounded-full
                outline-none
                focus:outline-none
                mr-2
              "
              type="button"
              @click="socialMedia('https://www.facebook.com/aketroniks/')"
            >
              <i class="fab fa-facebook-square"></i>
            </button>
            <button
              class="
                bg-white
                text-pink-400
                shadow-lg
                font-normal
                h-10
                w-10
                items-center
                justify-center
                align-center
                rounded-full
                outline-none
                focus:outline-none
                mr-2
              "
              type="button"
              @click="socialMedia('https://www.instagram.com/aketroniks/')"
            >
              <i class="fab fa-instagram"></i>
            </button>
          </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-9/12 px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span
                class="
                  block
                  uppercase
                  text-blueGray-500 text-sm
                  font-semibold
                  mb-2
                "
              >
                {{ $t("contact.address") }}
              </span>
              <p>
                Ahmet Petekçi Cad. Kobisan San. Sit. A Blok No:17/O
                Karatay/Konya/TÜRKİYE
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <span
                class="
                  block
                  uppercase
                  text-blueGray-500 text-sm
                  font-semibold
                  mb-2
                "
              >
                {{ $t("contact.email") }}
              </span>
              <p>
                <a href="mailto:info@aketroniks.com.tr"
                  >info@aketroniks.com.tr</a
                >
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <span
                class="
                  block
                  uppercase
                  text-blueGray-500 text-sm
                  font-semibold
                  mb-2
                "
              >
                {{ $t("contact.phone") }}
              </span>
              <p><a href="tel:+90 (332) 239 04 03">+90 (332) 239 04 03</a></p>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-blueGray-500 font-semibold py-1">
            Copyright © {{ date }}
            <a
              href="https://aketroniks.com.tr/"
              class="text-blueGray-500 hover:text-blueGray-800"
            >
              Aketroniks
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  methods: {
    socialMedia: function (link) {
      location.href = link;
    },
  },
};
</script>
