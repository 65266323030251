import { config, api_routes } from "./config";
import { authHeader } from "../helpers";
import axios from "axios";
const currentUserSubject = JSON.parse(localStorage.getItem("user"));

export const GroupingService = {
    getUserGroups,
    postUserGroup,
    putUserGroup,
    deleteUserGroup,
    getGroupDevices,
    postGroupDevice,
    deleteGroupDevice,
    currentUser: currentUserSubject,
};

function getUserGroups() {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}${api_routes.grouping.url}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postUserGroup(data) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${api_routes.grouping.url}`, {
                headers: authHeader(),
                data,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function putUserGroup(data) {

    return new Promise((resolve, reject) => {
        axios
            .put(`${config.apiUrl}${api_routes.grouping.url}/${data.id}`, {
                headers: authHeader(),
                data,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function deleteUserGroup(id) {

    return new Promise((resolve, reject) => {
        axios
            .delete(`${config.apiUrl}${api_routes.grouping.url}/${id}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getGroupDevices(groupId) {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}${api_routes.grouping.devicesUrl}/${groupId}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postGroupDevice(data) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${api_routes.grouping.devicesUrl}`, {
                headers: authHeader(),
                data,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function deleteGroupDevice(deviceId) {

    return new Promise((resolve, reject) => {
        axios
            .delete(`${config.apiUrl}${api_routes.grouping.devicesUrl}/${deviceId}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}
