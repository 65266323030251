import { config } from "./config";
import axios from "axios";
const currentUserSubject = JSON.parse(localStorage.getItem("user"));

export const paymentService = {
  getToken,
  currentUser: currentUserSubject,
};

async function getToken() {
  return new Promise((resolve, reject) => {
    axios
      .get(config.apiUrl + "/user/get-token")
      .then((result) => {
        resolve(result.data.token);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
