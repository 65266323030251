<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {{ $t("menu.apollo32.program.edit") }}
        </h6>
        <div v-if="!params.isDefault">
          <button class="
              bg-red-500
              text-white
              active:bg-red-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              disabled:opacity-20
              duration-150
            " type="button" :disabled="progressIndicatorVisible" @click="handleProgramRemove()">
            <i class="far fa-trash-alt"></i>
            {{ $t("programs.removeProgram") }}
          </button>

          <button class="
              bg-blue-500
              text-white
              active:bg-blue-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              disabled:opacity-20
              duration-150
            " type="button" :disabled="progressIndicatorVisible || !createButtonEnabled" @click="handleProgramEdit()">
            <i class="far fa-save"></i>
            {{ $t("navigation.save") }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("programs.programInfo") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("programs.name") }}
              </label>
              <input v-model.trim="data.name" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :placeholder="$t('programs.name')" :disabled="progressIndicatorVisible || params.isDefault" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("programs.description") }}
              </label>

              <input v-model.trim="data.description" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :placeholder="$t('programs.description')" :disabled="progressIndicatorVisible || params.isDefault" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="flex-auto px-4 lg:px-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
          {{ $t("programs.weeklySchedule") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <Scheduler ref="scheduler" :editEnabled="!params.isDefault" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="flex-auto px-4 lg:px-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 font-bold uppercase">
          {{ $t("programs.exceptionDays") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <ExceptionDays ref="exceptions" :editEnabled="!params.isDefault" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <PulseLoader class="text-center p-4" :loading="progressIndicatorVisible" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { base64UrlObjectDecode } from "../../../../helpers/encoing-helper";
import Scheduler from "../../../../components/Scheduler/Scheduler.vue";
import ExceptionDays from "../../../../components/Scheduler/ExceptionDays.vue";
import { successToast, errorToast } from "../../../../helpers/toast-helper";

export default {
  data() {
    return {
      data: {
        name: "",
        description: "",
      },
      params: {},
      progressIndicatorVisible: false,
    };
  },
  created() {
    this.params = base64UrlObjectDecode(this.$route.params.program);
    this.data.name = this.params.name;
    this.data.description = this.params.description;

    this.progressIndicatorVisible = true;
    this.getApollo32ScheduleData(this.params.id).then((data) => {
      this.$refs.scheduler.setData(this.groupBy(data, "day"));

      this.getApollo32ExceptionsData(this.params.id)
        .then((data) => this.$refs.exceptions.setData(data))
        .finally(() => (this.progressIndicatorVisible = false));
    });
  },
  methods: {
    ...mapActions("programs", [
      "getApollo32ScheduleData",
      "addApollo32ScheduleData",
      "updateApollo32Schedule",
      "removeApollo32Schedule",
      "getApollo32ExceptionsData",
    ]),
    handleProgramEdit() {
      this.progressIndicatorVisible = true;

      this.updateApollo32Schedule({
        id: this.params.id,
        data: {
          name: this.data.name,
          description: this.data.description,
        },
      }).then(() => {
        this.addApollo32ScheduleData({
          id: this.params.id,
          data: {
            scheduleTypeId: this.params.id,
            durations: this.$refs.scheduler.getData(),
            exceptions: this.$refs.exceptions.getData(),
          },
        })
          .then(() => {
            successToast(this.$t("messages.updatedSuccesfully"))
            this.$router.push({ name: "apollo32.programs" });
          })
          .finally(() => (this.progressIndicatorVisible = false));
      }).catch((error) => {
        console.error(error);
        this.progressIndicatorVisible = false;

        errorToast(this.$t("messages.updateFailed"));
      });
    },
    handleProgramRemove() {
      this.progressIndicatorVisible = true;

      this.removeApollo32Schedule(this.params.id)
        .then(() => {
          successToast(this.$t("messages.success"));
          this.$router.push({ name: "apollo32.programs" });
        })
        .finally(() => (this.progressIndicatorVisible = false))
        .catch((error) => {
          console.error(error);
          this.progressIndicatorVisible = false;

          errorToast(this.$t("messages.updateFailed"));
        });
    },
    groupBy(array, group) {
      let hash = Object.create(null);
      let result = {};

      array.forEach((a) => {
        if (!hash[a[group]]) {
          hash[a[group]] = [];
          result[a[group]] = hash[a[group]];
        }

        hash[a[group]].push(a);
      });

      return result;
    },
  },
  components: {
    Scheduler,
    ExceptionDays,
  },
  computed: {
    createButtonEnabled() {
      return this.data.name.length > 0;
    },
  },
};
</script>