<template>
  <div class="
      bg-indigo-500
      disabled:bg-indigo-300
      px-2
      py-1
      rounded
      
      text-sm
      font-bold
    ">
    <span class="
        hover:text-blueGray-300
        text-white
        px-4
        py-2
        flex
        items-center
        cursor-pointer
        transition-all
      " ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
      {{ selectedItem }}
    </span>
    <div ref="popoverDropdownRef" class="
        bg-white
        z-50
        float-left
        py-2
        px-1
        list-none
        text-left
        rounded
        shadow-xl
        min-w-48
        transition-all
      " :class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">
      <span class="
          p-3
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          hover:bg-gray-200
          cursor-pointer
          rounded
          transition-all
        " @click="
          onDropdownItemSelected(
            { value: 1, type: 'hours' },
            $t('dashboard.last1hour')
          )
        ">
        {{ $t("dashboard.last1hour") }}
      </span>
      <span class="
          p-3
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          hover:bg-gray-200
          cursor-pointer
          rounded
          transition-all
        " @click="
          onDropdownItemSelected(
            { value: 1, type: 'days' },
            $t('dashboard.last1day')
          )
        ">
        {{ $t("dashboard.last1day") }}
      </span>
      <span class="
          p-3
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          hover:bg-gray-200
          cursor-pointer
          rounded
          transition-all
        " @click="
          onDropdownItemSelected(
            { value: 1, type: 'weeks' },
            $t('dashboard.last1week')
          )
        ">
        {{ $t("dashboard.last1week") }}
      </span>
      <span class="
          p-3
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          hover:bg-gray-200
          cursor-pointer
          rounded
          transition-all
        " @click="
          onDropdownItemSelected(
            { value: 1, type: 'months' },
            $t('dashboard.last1month')
          )
        ">
        {{ $t("dashboard.last1month") }}
      </span>
      <span class="
          p-3
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          hover:bg-gray-200
          cursor-pointer
          rounded
          transition-all
        " @click="
          onDropdownItemSelected(
            { value: 3, type: 'months' },
            $t('dashboard.last3months')
          )
        ">
        {{ $t("dashboard.last3months") }}
      </span>
      <span class="
          p-3
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          hover:bg-gray-200
          cursor-pointer
          rounded
          transition-all
        " @click="
          onDropdownItemSelected(
            { value: 1, type: 'years' },
            $t('dashboard.last1year')
          )
        ">
        {{ $t("dashboard.last1year") }}
      </span>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import moment from "moment";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      selectedItem: this.$t("dashboard.last1hour"),
    };
  },
  emits: ["onItemSelected"],
  methods: {
    toggleDropdown() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
        return;
      }

      this.dropdownPopoverShow = true;
      createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
        placement: "bottom-start",
      });
    },
    onDropdownItemSelected({ value, type }, item) {
      this.toggleDropdown();

      if (this.selectedItem === item) return;
      this.selectedItem = item;

      const startDate = moment()
        .subtract(value, type)
        .format("YYYY-MM-DD HH:mm:ss");
      const endDate = moment().format("YYYY-MM-DD HH:mm:ss");

      this.$emit("onItemSelected", {
        selectedItem: item,
        value: {
          startDate,
          endDate,
        },
      });
    },
  },
};
</script>
