import { createApp } from "vue";
import { store } from "./stores";
import { router } from "./helpers";
// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/customswitch.css";

// mouting point for the whole app

import App from "@/App.vue";
import i18n from "./i18n";
import "./registerServiceWorker";

import VueContentPlaceholders from "./components/VueContentPlaceholders";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Toaster from '@meforma/vue-toaster';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import VueCookies from 'vue-cookies'

import VueNumberInput from '@chenfengyuan/vue-number-input';


const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(VueContentPlaceholders)
  .use(VueSweetalert2)
  .use(Toaster)
  .use(FloatingVue)
  .use(VueCookies, { expire: '7d' })
  .component('PulseLoader', PulseLoader)
  .component('Datepicker', Datepicker)
  .component(VueNumberInput.name, VueNumberInput)
  .mount("#app");

export { app };