import i18n from "../i18n";
import { writeFileXLSX, utils } from "xlsx";
import moment from "moment";

export const ExportTypes = {
    CSV: 'csv',
    XLSX: 'xlsx',
    TXT: 'txt'
}

export function exportClickableTable(table, exportType = ExportTypes.CSV) {

    const fileName = `${table.title} - ${(new Date().toLocaleDateString())}-${(new Date().toLocaleTimeString())}.${exportType}`;

    if (exportType == ExportTypes.CSV || exportType == ExportTypes.TXT)
        exportTableText(table, fileName);
    else if (exportType == ExportTypes.XLSX)
        exportTableXLS(table, fileName, table.title);
}

function exportTableXLS(table, fileName, tableName) {

    let headers = table.headers.map((e) => i18n.global.t(e.langKey));

    if (table.rows.length && table.rows[0].buttons && table.rows[0].buttons.length)
        headers.splice(-1);

    const headerCount = headers.length;

    const rows = table.rows.map((e) => {

        const columnDataArray = e.columns.map((c => Object.values(c)[0]));

        let data = {};

        for (let i = 0; i < headerCount; i++)
            data[headers[i]] = columnDataArray[i];

        return data;
    });

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, tableName);
    writeFileXLSX(workbook, fileName);
}

function exportTableText(table, fileName) {

    let headers = table.headers.map((e) => i18n.global.t(e.langKey));

    if (table.rows.length && table.rows[0].buttons && table.rows[0].buttons.length)
        headers.splice(-1);

    headers = headers.join(',');

    const data = table.rows.map((e) => e.columns.map((c => Object.values(c)[0])).join(',')).join('\r\n');

    let csvContent = `data:text/csv;charset=utf-8,${headers}\r\n${data}`;

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);

    link.click();
}

export function exportChart(data, config, exportType = ExportTypes.CSV) {

    const title = typeof config.titles == "string" ? config.titles : config.titles[i18n.global.locale];
    const fileName = `${title} - ${(new Date().toLocaleDateString())}-${(new Date().toLocaleTimeString())}.${exportType}`;

    if (exportType == ExportTypes.CSV || exportType == ExportTypes.TXT)
        exportChartText(data, config, fileName);
    else if (exportType == ExportTypes.XLSX)
        exportChartXLS(data, config, fileName, title);
}

function exportChartText(data, config, fileName) {

    let headers = config.series.map((e) => e.label).join(',');
    let rows = [];

    for (let i = 0; i < data[0].length; i++) {

        let rowData = [];
        rowData.push(moment(data[0][i] * 1000).toLocaleString());

        for (let j = 1; j < data.length; j++)
            rowData.push(data[j][i]);

        rows.push(rowData.join(','));
    }

    let csvContent = `data:text/csv;charset=utf-8,${headers}\r\n${rows.join('\r\n')}`;

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);

    link.click();
}

function exportChartXLS(data, config, fileName, tableName) {

    let headers = config.series.map((e) => e.label);
    let rows = [];

    for (let i = 0; i < data[0].length; i++) {

        let rowData = {};
        rowData[headers[0]] = moment(data[0][i] * 1000).toLocaleString();

        for (let j = 1; j < data.length; j++)
            rowData[headers[j]] = data[j][i];

        rows.push(rowData);
    }

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, tableName);
    writeFileXLSX(workbook, fileName);
}