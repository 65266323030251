<template>
    <Card>
        <Toolbar :title="$t('menu.editProject')">
            <Button :title="$t('navigation.save')" icon-class="far fa-save" background-color="bg-blue-500"
                :disabled="progressIndicatorVisible || !saveButtonEnabled" @click="handleSave" />
        </Toolbar>

        <Form>
            <FormSection :title="$t('projects.projectInfo')">
                <TextInput v-model.trim="params.name" :maxLength="255" :title="$t('users.name')"
                    :placeHolder="$t('users.name')" :disabled="progressIndicatorVisible" />

                <TextInput v-model.trim.number="params.version" :maxLength="8" :title="$t('projects.version')"
                    :placeHolder="$t('projects.version')" :disabled="progressIndicatorVisible" />
            </FormSection>

            <PulseLoader class="text-center" :loading="progressIndicatorVisible" />
        </Form>
    </Card>
</template>

<script>
import Card from "../../../components/Cards/Card";
import Button from "../../../components/Buttons/Button";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import TextInput from "../../../components/Forms/TextInput.vue";
import Toolbar from "../../../components/Forms/Toolbar.vue";
import FormSection from "../../../components/Forms/FormSection.vue";
import Form from "../../../components/Forms/Form.vue";
import { successToast, errorToast } from "../../../helpers/toast-helper";
import { updateProject } from "../../../services/admin.service";

export default {
    components: {
        Card,
        Button,
        TextInput,
        Toolbar,
        FormSection,
        Form,
    },
    data() {
        return {
            params: {},
            progressIndicatorVisible: false,
        };
    },
    created() {
        this.params = base64UrlObjectDecode(this.$route.params.project);
    },
    methods: {
        handleSave() {
            this.progressIndicatorVisible = true;

            updateProject({
                id: this.params.id,
                databaseName: this.params.databasename,
                data: {
                    name: this.params.name,
                    version: this.params.version
                },
            })
                .then(() => {
                    successToast(this.$t("messages.updatedSuccesfully"));
                    this.$router.push({ name: "projects" });
                })
                .catch(() => errorToast(this.$t("messages.updateFailed")))
                .finally(() => (this.progressIndicatorVisible = false));
        },
    },
    computed: {
        saveButtonEnabled() {
            return this.params.name.length > 0 && this.params.version.toString().length > 0;
        },
    },
};
</script>