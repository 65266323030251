<template>
  <div v-if="!isLoading" id="app">
    <router-view v-slot="{ Component, route }">
      <transition name="route" mode="out-in">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </div>
  <div v-else id="app" class="bg-purple h-screen flex items-center justify-center" style="
      background-image: linear-gradient(
        50deg,
        #179e6c 0%,
        #0776bd 100%
      ) !important;
    ">
    <pulse-loader class="text-center" :loading="isLoading"></pulse-loader>
  </div>
</template>

<script>
import axios from "axios";
import * as jose from "jose";
import { mapActions } from "vuex";

const tokenVersion = 1;

export default {
  data() {
    return { isLoading: true };
  },
  watch: {
    "$i18n.locale"(prev, next) {
      localStorage.setItem("lang", this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions("authentication", ["refreshToken"])
  },
  beforeCreate() {
    if (localStorage.getItem("lang") === "tr" || localStorage.getItem("lang") === "en")
      this.$i18n.locale = localStorage.getItem("lang");
    else {
      this.$i18n.locale = "tr";
    }

    let locale = this.$i18n.locale;

    if (!locale || locale == "") {
      locale = "tr";
      this.$i18n.locale = "tr";
    }

    document.body.lang = locale;
  },
  mounted() {
    this.isLoading = false;

    if (localStorage.getItem("hasLogged") && localStorage.getItem("uid")) {
      const token = jose.decodeJwt(localStorage.getItem("accessToken"));

      if (token.jwtTokenVer < tokenVersion || Date.now() >= token.exp * 1000) {

        this.$swal({
          icon: 'info',
          title: this.$t("auth.loginExpired")
        });

        this.$store.dispatch("authentication/logout");
        this.$router.push("/auth/login");
      }
    }

    axios.interceptors.response.use(function (response) {
      return response;
    }, (error) => {

      if (error && error.response && error.response.data && error.response.data.message == "Expired") {

        this;

        this.$swal({
          icon: 'info',
          title: this.$t("auth.loginExpired")
        });

        this.$store.dispatch("authentication/logout");
        this.$router.push("/auth/login");
      }

      return Promise.reject(error);
    });
  }
};
</script>

<style>
.bg-blue-400 {
  background: cyan;
}

.bg-green-400 {
  background: darkgreen;
}

/* route transitions */
.route-enter-from {
  opacity: 0.6;
  /* transform: translateX(10px); */
}

.route-enter-active {
  transition: all 0.4s;
}
</style>
