<template>
  <div
    class="relative md:pt-32 pb-32 pt-12"
    style="background-color: rgb(5, 150, 105)"
  >
    <div class="flex py-10 flex-col md:flex-row md:justify-start" style="background-color: #ddfff7">
      <img :src="imgSrc" class="h-auto max-w-full mx-auto" alt="Logo Bulunamadı!" />
      <h1 class="text-black mt-10 md:mt-5 mx-auto text-left text-xl md:text-3xl break-all font-bold">{{ getHeaderTitle }}</h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      imageSrc: "",
    };
  },
  computed: {
    ...mapGetters("headerStats", ["getHeaderTitle", "getHeaderImage"]),
    imgSrc() {
      try {
        var images = require.context(
          "../../assets/projectIconImg/",
          false,
          /\.(svg|png|jpg|jpeg)$/
        );
        images.keys().forEach((element) => {
          if (element.includes(this.getHeaderImage)) this.imageSrc = element;
        });
        return images(this.imageSrc);
      } catch (error) {}
    },
  },
};
</script>
