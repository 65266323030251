<template>
  <div class="flex flex-wrap w-full z-10">
    <ClickableTable :title="$t('menu.groups')" tableId="groupsTable" :headers="headers" :rows="rows"
      @onClick="onGroupClick" />
  </div>
  <pulse-loader class="text-center" :loading="progressIndicatorVisible"></pulse-loader>

  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      rounded
      mb-6
      xl:mb-0
      shadow-lg
    ">
    <div class="flex-auto p-4">
      <div class="flex flex-wrap">
        <RouterButton :title="$t('menu.createGroup')" route-to="/admin/groups/new" icon-class="fas fa-plus" />
        <RouterButton :title="$t('devices.help')" route-to="/help" icon-class="fas fa-question-circle" />
      </div>
    </div>
  </div>

  <div class="relative flex flex-col min-w-0 break-words mt-6 mb-6 xl:mb-0">
    <div class="flex-auto">
      <div class="flex flex-wrap">
        <div class="flex flex-wrap w-full z-10">

          <Transition name="fade">
            <div v-if="selectedGroup">
              <ClickableTable :title="$t('menu.mydevices')" :headers="deviceHeaders" :rows="devicesRows"
                @onClick="onDeviceClick" v-if="devicesData.length > 0" />
              <div v-else-if="!deviceDataLoading" class="flex flex-wrap">
                <div class="p-4">
                  <h4 class="text-blueGray-500">{{ $t("groups.emptyGroup") }}</h4>
                </div>

                <router-link :to="{
                  name: 'addGroupDevice',
                  params: {
                    group: base64UrlObjectEncode({
                      id: selectedGroup.id,
                      name: selectedGroup.name,
                      description: selectedGroup.description,
                    }),
                  },
                }" v-slot="{ href, navigate }" class="px-4 py-2">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block">
                    <i class="fas fa-plus"></i>
                    {{ $t("groups.addDevice") }}
                  </a>
                </router-link>
              </div>
            </div>
          </Transition>

          <pulse-loader class="text-center w-full mb-4" :loading="deviceDataLoading"></pulse-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import ClickableTable from "../../../components/Tables/ClickableTable.vue";
import RouterButton from "../../../components/Buttons/RouterButton.vue";
import { base64UrlObjectEncode } from "../../../helpers/encoing-helper";

export default {
  data() {
    return {
      ...mapState("grouping", ["groups"]),
      headers: [
        { langKey: "groups.name", key: "name" },
        { langKey: "groups.description", key: "description" },
        { langKey: "groups.actions", key: "actions" },
      ],
      deviceHeaders: [
        { langKey: "devices.serialNo", key: "serialNo" },
        { langKey: "devices.name", key: "name" },
        { langKey: "devices.projectName", key: "projectName" },
        { langKey: "devices.addingtime", key: "addingtime" },
        { langKey: "devices.actions", key: "actions" },
      ],
      selectedGroup: null,
      values: [],
      devicesData: [],
      progressIndicatorVisible: true,
      deviceDataLoading: false,
      selectedIndex: -1
    };
  },
  created() {
    this.progressIndicatorVisible = true;
    this.getGroups()
      .then((data) => (this.values = data))
      .finally(() => (this.progressIndicatorVisible = false));
  },
  methods: {
    ...mapActions("grouping", ["getGroups", "getGroupDevices"]),
    base64UrlObjectEncode: base64UrlObjectEncode,
    onGroupClick(index, item) {

      if (this.selectedIndex == index)
        return;

      this.selectedIndex = index;

      this.devicesData = [];
      this.deviceDataLoading = true;
      this.selectedGroup = this.values[index];

      this.getGroupDevices(this.values[index].id)
        .then((data) => (this.devicesData = data.filter((x) => x.device.device.projectInfo.hasimplementation)))
        .finally(() => (this.deviceDataLoading = false));
    },
    onDeviceClick(index, item) {
      this.$router.push({
        name: "dashboard",
        params: {
          device: base64UrlObjectEncode(this.devicesData[index].device.device),
        },
      });
    },
  },
  components: {
    ClickableTable,
    RouterButton
  },
  computed: {
    rows() {
      if (!Array.isArray(this.values)) return;

      return this.values.map((x) => {
        const buttons = [];

        buttons.push({
          langKey: "devices.edit",
          routerLink: {
            name: "editGroup",
            params: {
              group: base64UrlObjectEncode({
                id: x.id,
                name: x.name,
                description: x.description,
              }),
            },
          },
          iconClass: "far fa-edit",
        });

        buttons.push({
          langKey: "groups.addDevice",
          routerLink: {
            name: "addGroupDevice",
            params: {
              group: base64UrlObjectEncode({
                id: x.id,
                name: x.name,
                description: x.description,
              }),
            },
          },
          iconClass: "fas fa-plus",
        });

        return {
          columns: [{ name: x.name }, { description: x.description }],
          buttons: buttons,
        };
      });
    },
    devicesRows() {
      if (!Array.isArray(this.devicesData)) return;

      return this.devicesData.map((x) => {
        const buttons = [];

        const device = x.device;

        if (!device.subend || new Date(device.subend) > new Date()) {
          buttons.push({
            langKey: "devices.watch",
            routerLink: {
              name: "dashboard",
              params: {
                device: base64UrlObjectEncode(x.device),
              },
            },
            iconClass: "far fa-chart-bar",
          });
        } else {
          buttons.push({
            langKey: "devices.refreshSubs",
            routerLink: {
              path: "mydevices/refresh",
              query: { deviceid: device.device.deviceid },
            },
            iconClass: "far fa-redo",
          });
        }

        buttons.push({
          langKey: "devices.edit",
          routerLink: {
            name: "editDevice",
            params: {
              device: base64UrlObjectEncode({
                id: x.id,
                deviceid: x.device.deviceid,
                name: x.name,
              }),
            },
          },
          iconClass: "far fa-edit",
        });

        if (device.device.projectInfo.helpurlsuffix != "") {
          buttons.push({
            langKey: "devices.help",
            routerLink: {
              path: "../help",
              query: { title: device.device.projectInfo.helpurlsuffix },
            },
            iconClass: "far fa-question-circle",
          });
        }

        return {
          columns: [
            { serialNo: device.device.serialno },
            { name: device.name },
            { projectName: device.device.projectInfo.name },
            { addingtime: moment(device.addingtime).format("YYYY-MM-DD") },
          ],
          buttons: buttons,
        };
      });
    },
  },
};
</script>