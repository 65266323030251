<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
    v-if="isActive || !hideAll"
  >
    <div class="rounded bg-white mb-0 px-6 py-6">
      <PulseLoader class="text-center" :loading="isActive" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
    hideAll: {
      type: Boolean,
      default: true,
    },
  },
};
</script>