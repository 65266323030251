import { config } from "./config";
import { authHeader } from "../helpers";
import axios from "axios";

export const DaashboardService = {

    getDashboardMetadata,
    getChartData,
    getChartJsData,
    getDeviceParams,
    postDeviceParams,
    getDeviceLogs,
    updateDeviceFirmware,
    callButtonParameterURL,
    currentUser: () => JSON.parse(localStorage.getItem("user")),
};

function getDashboardMetadata({ project }) {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/${project}/dashboard`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getChartData({ project, prefix, chartIndex, options }) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/${project}/${prefix}/${chartIndex}`, {
                headers: authHeader(),
                data: {
                    options
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getChartJsData({ project, deviceId, begin, end, language, tz }) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/${project}/chartjs`, {
                headers: authHeader(),
                data: {
                    deviceId,
                    begin,
                    end,
                    tz,
                    language
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getDeviceParams({ project, deviceId }) {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/${project}/deviceparams/${deviceId}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postDeviceParams({ project, deviceId, params, epoch }) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/${project}/deviceparams/${deviceId}`, {
                headers: authHeader(),
                data: {
                    params,
                    epoch
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getDeviceLogs({ project, deviceId, data }) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/${project}/logs/${deviceId}`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function updateDeviceFirmware({ project, deviceId }) {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/${project}/update/${deviceId}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function callButtonParameterURL({ method, url, deviceId }) {

    return new Promise((resolve, reject) => {
        axios[method](`${config.apiUrl}${url}/${deviceId}`, {
            headers: authHeader(),
        })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}