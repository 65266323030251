<template>
  <div>
    <navbar />
    <main>
      <div
        class="
          relative
          pt-16
          pb-32
          flex
          content-center
          items-center
          justify-center
          min-h-screen-75
        "
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://iotakecloud.com/images/banner-image-1-1920x700.jpg');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          />
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  {{ $t("landing.welcome") }}
                </h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  {{ $t("landing.summary") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            top-auto
            bottom-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            h-70-px
          "
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>

      <section class="pb-20 bg-blueGray-200 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  bg-white
                  w-full
                  mb-8
                  shadow-lg
                  rounded-lg
                "
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="
                      text-white
                      p-3
                      text-center
                      inline-flex
                      items-center
                      justify-center
                      w-12
                      h-12
                      mb-5
                      shadow-lg
                      rounded-full
                      bg-red-400
                    "
                  >
                    <i class="fas fa-award" />
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ $t("landing.feature_1_title") }}
                  </h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    {{ $t("landing.feature_1_content") }}
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  bg-white
                  w-full
                  mb-8
                  shadow-lg
                  rounded-lg
                "
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="
                      text-white
                      p-3
                      text-center
                      inline-flex
                      items-center
                      justify-center
                      w-12
                      h-12
                      mb-5
                      shadow-lg
                      rounded-full
                      bg-sky-400
                    "
                  >
                    <i class="fas fa-retweet" />
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ $t("landing.feature_2_title") }}
                  </h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    {{ $t("landing.feature_2_content") }}
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  bg-white
                  w-full
                  mb-8
                  shadow-lg
                  rounded-lg
                "
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="
                      text-white
                      p-3
                      text-center
                      inline-flex
                      items-center
                      justify-center
                      w-12
                      h-12
                      mb-5
                      shadow-lg
                      rounded-full
                      bg-emerald-400
                    "
                  >
                    <i class="fas fa-fingerprint" />
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ $t("landing.feature_3_title") }}
                  </h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    {{ $t("landing.feature_3_content") }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="
                  text-blueGray-500
                  p-3
                  text-center
                  inline-flex
                  items-center
                  justify-center
                  w-16
                  h-16
                  mb-6
                  shadow-lg
                  rounded-full
                  bg-white
                "
              >
                <i class="fas fa-user-friends text-xl" />
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                {{ $t("landing.resourceTitle") }}
              </h3>
              <p
                class="
                  text-lg
                  font-light
                  leading-relaxed
                  mt-4
                  mb-4
                  text-blueGray-600
                "
              >
                {{ $t("landing.resourceContent_2") }}
              </p>
              <p
                class="
                  text-lg
                  font-light
                  leading-relaxed
                  mt-0
                  mb-4
                  text-blueGray-600
                "
              >
                {{ $t("landing.resourceContent_3") }}
              </p>
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  w-full
                  mb-6
                  shadow-lg
                  rounded-lg
                  bg-emerald-500
                "
              >
                <img
                  alt="..."
                  src="https://iotakecloud.com/images/agm.png"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-emerald-500 fill-current"
                    />
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    {{ $t("landing.resourceTitle") }}
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    {{ $t("landing.resourceContent_1") }}
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="relative py-20">
        <div
          class="
            bottom-auto
            top-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            -mt-20
            h-20
          "
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="https://aketroniks.com.tr/resimler/kategori/yardimci-ekipmanlar.jpg?w=958&h=480&fit=crop&q=95&s=KYtEAoB3VDJAGbWf2DsJec1s7vGiiJevvCBH3xiU"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <div
                  class="
                    text-emerald-600
                    p-3
                    text-center
                    inline-flex
                    items-center
                    justify-center
                    w-16
                    h-16
                    mb-6
                    shadow-lg
                    rounded-full
                    bg-emerald-300
                  "
                >
                  <i class="fas fa-rocket text-xl" />
                </div>
                <h3 class="text-3xl font-semibold">
                  {{ $t("landing.feature.title") }}
                </h3>
                <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  {{ $t("landing.feature.subtitle") }}
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="
                            text-xs
                            font-semibold
                            inline-block
                            py-1
                            px-2
                            uppercase
                            rounded-full
                            text-emerald-600
                            bg-emerald-200
                            mr-3
                          "
                        >
                          <i class="fas fa-fingerprint" />
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          {{ $t("landing.feature.content_1") }}
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="
                            text-xs
                            font-semibold
                            inline-block
                            py-1
                            px-2
                            uppercase
                            rounded-full
                            text-emerald-600
                            bg-emerald-200
                            mr-3
                          "
                        >
                          <i class="fab fa-html5" />
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          {{ $t("landing.feature.content_2") }}
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="
                            text-xs
                            font-semibold
                            inline-block
                            py-1
                            px-2
                            uppercase
                            rounded-full
                            text-emerald-600
                            bg-emerald-200
                            mr-3
                          "
                        >
                          <i class="far fa-paper-plane" />
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          {{ $t("landing.feature.content_3") }}
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-20 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="w-full md:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  :src="team1"
                  class="shadow-lg rounded-full mx-auto max-w-120-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Web</h5>
                  <p
                    class="
                      mt-1
                      text-sm text-blueGray-400
                      uppercase
                      font-semibold
                    "
                  >
                    {{ $t("landing.solution_web") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  :src="team2"
                  class="shadow-lg rounded-full mx-auto max-w-120-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Android</h5>
                  <p
                    class="
                      mt-1
                      text-sm text-blueGray-400
                      uppercase
                      font-semibold
                    "
                  >
                    {{ $t("landing.solution_android") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  :src="team3"
                  class="shadow-lg rounded-full mx-auto max-w-120-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Ios</h5>
                  <p
                    class="
                      mt-1
                      text-sm text-blueGray-400
                      uppercase
                      font-semibold
                    "
                  >
                    {{ $t("landing.solution_ios") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pb-20 relative block bg-blueGray-800">
        <div
          class="
            bottom-auto
            top-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            -mt-20
            h-20
          "
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-800 fill-current"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>

        <div class="container mx-auto px-4 md:pt-24 lg:pb-64" />
      </section>
      <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center md:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  w-full
                  mb-6
                  shadow-lg
                  rounded-lg
                  bg-blueGray-200
                "
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">
                    {{ $t("contact.title") }}
                  </h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    {{ $t("contact.subtitle") }}
                  </p>
                  <div class="relative w-full mb-3 mt-8">
                    <label
                      class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      "
                      htmlFor="full-name"
                    >
                      {{ $t("contact.fullname") }}
                    </label>
                    <input
                      type="text"
                      required
                      v-model="contact.name"
                      class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      "
                      :placeholder="$t('contact.title')"
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      "
                      htmlFor="email"
                    >
                      {{ $t("contact.email") }}
                    </label>
                    <input
                      type="email"
                      required
                      v-model="contact.email"
                      class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      "
                      :placeholder="$t('contact.title')"
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="
                        block
                        uppercase
                        text-blueGray-600 text-xs
                        font-bold
                        mb-2
                      "
                      htmlFor="message"
                    >
                      {{ $t("contact.message") }}
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                      "
                      :placeholder="$t('contact.message')"
                      @change="
                        (e) => {
                          contact.message = e.target.value;
                        }
                      "
                    />
                  </div>
                  <div class="text-center mt-6">
                    <button
                      class="
                        bg-blueGray-800
                        text-white
                        active:bg-blueGray-600
                        text-sm
                        font-bold
                        uppercase
                        px-6
                        py-3
                        rounded
                        shadow
                        hover:shadow-lg
                        outline-none
                        focus:outline-none
                        mr-1
                        mb-1
                        ease-linear
                        transition-all
                        duration-150
                      "
                      type="button"
                      @click="sendContact()"
                    >
                      {{ $t("contact.send") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

export default {
  components: {
    Navbar,
    FooterComponent,
  },
  data() {
    return {
      team1: "https://iotakecloud.com/images/blog-1-720x480.jpg",
      team2: "https://iotakecloud.com/images/blog-2-720x480.jpg",
      team3: "https://iotakecloud.com/images/blog-3-720x480.jpg",
      contact: {
        email: "",
        name: "",
        message: "",
      },
    };
  },
  methods: {
    sendContact: function () {
      if (
        this.contact.name.length > 5 &&
        this.contact.email.length > 15 &&
        this.contact.message.length > 20
      )
        this.$swal(this.$i18n.t("contact.sentMessage"));
      else {
        this.$swal(this.$i18n.t("contact.checkYourMessage"));
      }
    },
  },
};
</script>
