<template>
  <div class="container mx-auto px-4 h-full ">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="flex-auto px-4 lg:px-10 py-10">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>{{ $t("auth.signupTitle") }}</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  {{ $t("auth.name") }}
                </label>
                <input type="text" v-model="formData.name"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('auth.name')" />
              </div>
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  {{ $t("auth.lastname") }}
                </label>
                <input type="text" v-model="formData.surname"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('auth.lastname')" />
              </div>

              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  {{ $t("auth.email") }}
                </label>
                <input type="email" v-model="formData.email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('auth.email')" />
              </div>

              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  {{ $t("auth.password") }}
                </label>
                <input type="password" v-model="formData.password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('auth.password')" />
              </div>
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  {{ $t("auth.passwordAgain") }}
                </label>
                <input type="password" v-model="formData.password2"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('auth.passwordAgain')" />
              </div>

              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input id="customCheckLogin" v-model="formData.privacyPolicy" type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    {{ $t("auth.acceptpolicy") }}
                    <a href="javascript:void(0)" class="text-emerald-500" @click="showPrivacyPolicy">
                      {{ $t("auth.privatypolicy") }}
                    </a>
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button class="
                    bg-blueGray-800
                    text-white
                    active:bg-blueGray-600
                    text-sm
                    font-bold
                    uppercase
                    px-6
                    py-3
                    rounded
                    shadow
                    hover:shadow-lg
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                    disabled:opacity-20
                  " type="button" @click="onRegisterClick" :disabled="!validate">
                  {{ $t("auth.registerTitle") }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/auth/login" class="text-blueGray-200 hover:text-blue-400 transition-all">
              <small>{{ $t("auth.loginTitle") }}</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import github from "@/assets/img/github.svg";
import google from "@/assets/img/google.svg";
import { mapActions } from "vuex";

export default {
  name: "RegisterPage",
  data() {
    return {
      github,
      google,
      formData: {}
    };
  },
  created() {
    if (localStorage.getItem("accessToken"))
      this.$router.push("/");
  },
  methods: {
    ...mapActions("authentication", ["register"]),
    onRegisterClick() {

      if (this.formData.password !== this.formData.password2) {

        this.$swal({
          title: this.$t("auth.passwordsNotMatching"),
          icon: 'error',
        });

        return;
      }

      this.register(this.formData).then((data) => {
        this.$swal(this.$t("auth.registerSuccess"));
        this.$router.push("/auth/login");
      }).catch(() => this.$swal({
        title: this.$t("errors.errorOccured"),
        icon: 'error',
      }));
    },
    validateEmail(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    showPrivacyPolicy() {
      this.$swal({
        text: this.$t("privacyPolicy"),
        icon: 'info',
      })
    }
  },
  computed: {
    validate() {

      if (!this.formData.name || this.formData.name.length < 3)
        return false;

      if (!this.formData.email || this.formData.email.length < 3 || !this.validateEmail(this.formData.email))
        return false;

      if (!this.formData.password || this.formData.password.length < 4)
        return false;

      if (!this.formData.password2 || this.formData.password2.length < 4)
        return false;

      if (!this.formData.privacyPolicy)
        return false;

      return true;
    }
  },
};
</script>
