<template>
  <Transition name="fade">
    <Error v-if="error" />
    <div v-else class="
        relative
        flex flex-col
        min-w-0
        break-words
        bg-white
        w-full
        mb-6
        shadow-lg
        rounded
      ">
      <div class="block w-full overflow-x-auto">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 class="font-semibold text-base text-blueGray-700 pt-2 pb-2">
                {{ config.titles[$i18n.locale] }}
              </h3>
            </div>

            <div class="rounded">
              <VMenu :delay="{ show: 100, hide: 100 }" :disabled="loading || error">
                <button class="
                border
                px-3
                py-1.5
                rounded
                bg-blueGray-50
                border-indigo-500
                text-base
                focus:outline-none
                fas fa-file-export
                text-center"></button>

                <template #popper>
                  <div v-for="(value, key, index) in ExportTypes" class="text-center p-1" :key="index">
                    <button class="py-2 px-5 text-sm hover:bg-warmGray-200 rounded transition-all w-full"
                      @click="exportChartInternal(value)" v-close-popper>{{ key }}</button>
                  </div>
                </template>
              </VMenu>
            </div>

            <DatePicker @onQuickRangeSelected="onQuickRangeSelected" @onRangeSelected="onRangeSelected"
              :disabled="loading" :chartId="`${deviceId}-${chartIndex}`" />
          </div>
        </div>
        <PulseLoader v-if="loading" class="text-center mb-6" />
        <div v-else ref="chartContainer" class="flex justify-center mb-4 relative float-left w-full">
          <UplotVue :data="data" :options="config" @create="onCreate" @delete="onDelete"
            :class="(data.length > 0 && data[0].length == 0) ? 'cursor-not-allowed' : ''" />
          <div v-if="data.length > 0 && data[0].length == 0" class="absolute top-1/3 place-content-center text-center ">
            <p class="font-bold italic text-xl text-blueGray-700">{{ $t("dashboard.noChartDataFound") }}</p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions } from "vuex";
import UplotVue from "uplot-vue";
import uPlot from "uplot";
import "uplot/dist/uPlot.min.css";
import { chartHeight, getMargin } from "./chart-constants";
import Loading from "../../Common/Loading.vue";
import Error from "../../Common/Error.vue";
import StartEndTimeSelector from "./StartEndTimeSelector.vue";
import DatePicker from "./DatePicker.vue";
import { exportChart, ExportTypes } from "../../../helpers/data-exporter"

export default {
  props: {
    deviceId: {
      required: true,
      type: Number,
    },
    project: {
      required: true,
      type: String,
    },
    chartIndex: {
      required: true,
      type: Number,
    },
    config: {
      required: true,
      type: Object,
    },
    chartPrefix: {
      required: false,
      type: String,
      default: "chart"
    }
  },
  data() {
    return {
      loading: true,
      error: false,
      chart: null,
      data: [],
      tempUnit: this.$cookies.get("tempUnit") ?? "C",
      ExportTypes: ExportTypes
    };
  },
  created() {

    this.initConfig();

    const selected = this.$cookies.get(`selected-date-time-${this.deviceId}-${this.chartIndex}`);

    if (!selected) {

      this.onQuickRangeSelected({
        value: 0,
        type: "week",
      });

      return;
    }

    if (selected.item) {
      this.selectedItem = selected.item;
      this.onQuickRangeSelected(selected.value);
    }
    else if (selected.startTime) {
      this.selectedItem = `${selected.startTime} -> ${selected.endTime}`;
      this.onRangeSelected(selected);
    } else {
      this.onQuickRangeSelected({
        value: 0,
        type: "week",
      });
    }
  },
  methods: {
    ...mapActions("dashboard", ["getChartData"]),
    onCreate(chart) {
      this.chart = chart;
      this.chart.setSize(this.getSize());

      window.addEventListener("resize", this.onWindowResize);
    },
    onDelete(chart) {
      this.chart = chart;
      window.removeEventListener("resize", this.onWindowResize);
    },
    onWindowResize() {
      this.chart.setSize(this.getSize());
    },
    getSize() {
      const container = this.$refs.chartContainer;

      return {
        width: container.clientWidth - getMargin(),
        height: chartHeight,
      };
    },
    onQuickRangeSelected(value) {
      this.loading = true;

      this.getChartData({
        project: this.project,
        prefix: this.chartPrefix,
        chartIndex: this.chartIndex,
        options: {
          values: [this.deviceId],
          interval: { text: "minute", value: 1 },
          ...value,
        },
      })
        .then((data) => {
          this.data = this.updateTempUnitFields(data.data);
        })
        .catch(() => (this.error = true))
        .finally(() => (this.loading = false));
    },
    onRangeSelected({ startTime, endTime }) {
      this.loading = true;

      this.getChartData({
        project: this.project,
        prefix: this.chartPrefix,
        chartIndex: this.chartIndex,
        options: {
          values: [this.deviceId],
          interval: { text: "minute", value: 1 },
          startTime,
          endTime,
        },
      })
        .then((data) => {
          this.data = this.updateTempUnitFields(data.data);
        })
        .catch(() => (this.error = true))
        .finally(() => (this.loading = false));
    },
    updateTempUnitFields(data) {

      if (this.tempUnit != 'F')
        return data;

      for (let i = 0; i < this.config.series.length; i++) {
        const element = this.config.series[i];

        if (element.type != 'temp')
          continue;

        data[i] = data[i].map(e => {
          
          if (e == null){
            return null;
          }

          return this.toFahrenheit(e);
        });
      }

      return data;
    },
    toFahrenheit(temp) {
      return (temp * 1.8) + 32;
    },
    initConfig() {

      this.prepareConfigTitiles();

      const lineInterpolations = {
        linear: 0,
        stepAfter: 1,
        stepBefore: 2,
        spline: 3,
      };

      const drawStyles = {
        line: 0,
        bars: 1,
        points: 2,
        barsLeft: 3,
        barsRight: 4,
      };

      const { linear, stepped, bars, spline, spline2 } = uPlot.paths;

      const _bars60_100 = bars({ size: [0.6, 100] });
      const _bars100Left = bars({ size: [1], align: 1 });
      const _bars100Right = bars({ size: [1], align: -1 });
      const _stepBefore = stepped({ align: -1 }); //, ascDesc: true
      const _stepAfter = stepped({ align: 1 }); //, ascDesc: true
      const _linear = linear();
      const _spline = spline();

      function paths(u, seriesIdx, idx0, idx1, extendGap, buildClip) {
        let s = u.series[seriesIdx];
        let style = s.drawStyle;
        let interp = s.lineInterpolation;

        let renderer = (
          style == drawStyles.line ? (
            interp == lineInterpolations.linear ? _linear :
              interp == lineInterpolations.stepAfter ? _stepAfter :
                interp == lineInterpolations.stepBefore ? _stepBefore :
                  interp == lineInterpolations.spline ? _spline :
                    //	interp == lineInterpolations.spline2    ? _spline2 :
                    null
          ) :
            style == drawStyles.bars ? (
              _bars60_100
            ) :
              style == drawStyles.barsLeft ? (
                _bars100Left
              ) :
                style == drawStyles.barsRight ? (
                  _bars100Right
                ) :
                  style == drawStyles.points ? (
                    () => null
                  ) : () => null
        );

        return renderer(u, seriesIdx, idx0, idx1, extendGap, buildClip);
      }

      for (let i = 0; i < this.config.series.length; i++) {

        let series = this.config.series[i];

        if (!series.drawStyle && !series.lineInterpolation)
          continue;

        series.paths = paths;
      }
    },
    prepareConfigTitiles() {

      for (let i = 0; i < this.config.series.length; i++) {
        let element = this.config.series[i];

        if (typeof element.label === 'object')
          element.label = element.label[this.$i18n.locale];

        if (element.type === 'temp')
          element.label += ` (°${this.tempUnit})`
      }
    },
    exportChartInternal(type) {
      exportChart(this.data, this.config, type);
    }
  },
  components: {
    UplotVue,
    Loading,
    Error,
    StartEndTimeSelector,
    DatePicker,
  },
};
</script>

<style>
.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}

.fade-leave-to {
  visibility: hidden;
}
</style>