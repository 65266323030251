import { paymentService } from "../../services";

const initialState = {
  activeToken: undefined,
};

export const payment = {
  namespaced: true,
  state: initialState,
  actions: {
    activeToken({ commit }) {
      return new Promise((resolve, reject) => {
        paymentService
          .getToken()
          .then((res) => {
            commit("token", res);
            resolve(res);
          })
          .catch((error) => {
            commit("token", undefined);
            reject(error);
          });
      });
    },
  },
  mutations: {
    token(state, data) {
      state.activeToken = data;
    },
  },
  getters: {
    getActiveToken: (state) => {
      return state.activeToken;
    },
  },
};
