export default {
  namespaced: true,
  state() {
    return {
      headerTitle: "Selam",
      headerVisibility: false,
      headerImage: "",
    };
  },
  actions: {},
  mutations: {
    setHeader: (state, payload) => {
      state.headerTitle = payload.title;
      state.headerVisibility = payload.visibility;
      state.headerImage = payload.image;
    },
  },
  getters: {
    getHeaderTitle: (state) => {
      return state.headerTitle;
    },
    getHeaderVisibility: (state) => {
      return state.headerVisibility;
    },
    getHeaderImage: (state) => {
      return state.headerImage;
    },
  },
};
