<template>
  <div class="">
    <div class="text-center ">{{ this.title }}</div>
    <div class="text-center" v-if="editEnabled">
      <button @click="addNewEntryClicked" type="button" class="
          outline-none
          hover:bg-warmGray-200
          rounded-full
          cursor-pointer
          disabled:opacity-20
          transition-all
        " :disabled="entries.length >= 10">
        <i class="fas fa-plus-circle p-3"></i>
      </button>
    </div>
    <div class="p-2">
      <div class="
          relative
          flex flex-col
          min-w-0
          break-words
          w-full
          h-full
          mb-2
          shadow-md
          rounded
          p-4
          outline-none
          focus:outline-none
          ease-linear
          transition-all
          duration-150
          text-center
          hover:shadow-xl
        " :class="editEnabled ? ' cursor-pointer' : 'cursor-not-allowed'" v-for="(item, index) in entries" :key="index"
        @click="editEntyClicked(index, item)">
        {{ padTimeItem(item.startTime) }} - {{ padTimeItem(item.endTime) }}
      </div>
    </div>
    <ScheduleDialog ref="scheduleDialog" @addNewEntry="onAddNewEntry" @editEntry="onEditEntry"
      @removeEntry="onRemoveEntry" />
  </div>
</template>

<script>
import ScheduleDialog from "./ScheduleDialog.vue";

export default {
  props: {
    day: 0,
    title: String,
    editEnabled: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ScheduleDialog,
  },
  data: function () {
    return {
      initialData: null,
      entries: [],
    };
  },
  methods: {
    setData(data) {
      if (data) data.forEach((data) => this.insertTile(data));
    },
    getData() {
      return this.entries;
    },
    insertTile(data) {
      if (!data) return;

      const startTimeResult = data.starttime.split(":");
      const endTimeResult = data.endtime.split(":");

      this.entries.push({
        startTime: {
          hours: parseInt(startTimeResult[0]),
          minutes: parseInt(startTimeResult[1]),
        },
        endTime: {
          hours: parseInt(endTimeResult[0]),
          minutes: parseInt(endTimeResult[1]),
        },
      });
    },
    padTimeItem(time) {
      return `${time.hours.toString().padStart(2, "0")}:${time.minutes
        .toString()
        .padStart(2, "0")}`;
    },
    addNewEntryClicked() {
      if (this.entries.length >= 10) return;
      this.$refs.scheduleDialog.open(this.entries[this.entries.length - 1]);
    },
    editEntyClicked(index, item) {

      if (!this.editEnabled)
        return;

      this.$refs.scheduleDialog.openEdit(
        index,
        item,
        this.entries[index - 1],
        this.entries[index + 1]
      );
    },
    onAddNewEntry(value) {
      this.entries.push(value);
    },
    onEditEntry(index, value) {
      this.entries[index] = value;
    },
    onRemoveEntry(index) {
      this.entries.splice(index, 1);
    },
  },
};
</script>