<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {{ $t("menu.createGroup") }}
        </h6>
        <button class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            disabled:opacity-20
            duration-150
          " type="button" :disabled="progressIndicatorVisible || !createButtonEnabled" @click="handleGroupCreate()">
          <i class="fas fa-plus"></i>
          {{ $t("navigation.create") }}
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("groups.groupInfo") }}
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("groups.name") }}
              </label>
              <input v-model.trim="groupData.name" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :disabled="progressIndicatorVisible" :placeholder="$t('groups.name')" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                {{ $t("groups.description") }}
              </label>

              <input v-model.trim="groupData.description" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :placeholder="$t('groups.description')" :disabled="progressIndicatorVisible" />
            </div>
          </div>
        </div>
      </form>
      <pulse-loader class="text-center" :loading="progressIndicatorVisible"></pulse-loader>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { successToast } from "../../../helpers/toast-helper";

export default {
  data() {
    return {
      groupData: {
        name: "",
        description: "",
      },
      progressIndicatorVisible: false,
    };
  },
  methods: {
    ...mapActions("grouping", ["addNewGroup"]),
    handleGroupCreate() {
      this.progressIndicatorVisible = true;

      this.addNewGroup({
        name: this.groupData.name,
        description: this.groupData.description,
      })
        .then(() => {
          successToast(this.$t("messages.createdSuccesfully"));
          this.$router.push("/admin/groups");
        })
        .finally(() => (this.progressIndicatorVisible = false));
    },
  },
  computed: {
    createButtonEnabled() {
      return this.groupData.name.length > 0;
    },
  },
};
</script>