<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded bg-blueGray-700 px-2 sm:px-4 lg:px-6 py-2">
      <div class="text-center flex justify-between">
        <nav v-if="tabs.length" class="flex overflow-auto text-white">
          <TabItem
            v-for="(tab, index) in tabs"
            :key="index"
            :title="$t(`dashboard.${tab.title}`)"
            :icon="tab.icon"
            @onClick="onTabSelected(index, tab)"
          />
        </nav>
        <PulseLoader v-else class="p-10 text-center w-full" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TabItem from "./TabItem";

export default {
  props: {
    projectInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    this.getDashbordMetadata({
      project: this.projectInfo.databasename,
    }).then((data) => {
      this.tabs = data;

      const lastTab = parseInt(
        this.$cookies.get(this.projectInfo.databasename)
      );
      const tab = !isNaN(lastTab) && lastTab < this.tabs.length ? lastTab : 0;

      this.onTabSelected(tab, this.tabs[tab]); // initial tab selection
    });
  },
  emits: ["onSelect"],
  methods: {
    ...mapActions("dashboard", ["getDashbordMetadata"]),
    onTabSelected(index, item) {
      this.$emit("onSelect", index, item);
      this.$cookies.set(this.projectInfo.databasename, index);
    },
  },
  components: { TabItem },
};
</script>
