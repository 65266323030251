<template>
    <button class="
      text-white
      font-bold
      uppercase
      text-xs
      px-4
      py-2
      rounded
      shadow
      hover:shadow-md
      outline-none
      focus:outline-none
      mr-1
      ease-linear
      transition-all
      disabled:opacity-20
      duration-150
    " :class="backgroundColor" type="button" :disabled="disabled" @click="$emit('click')">
        <i :class="iconClass"></i>
        {{ title }}
    </button>
</template>

<script>
export default {
    emits: ['click'],
    props: {
        title: {
            type: String,
            required: true
        },
        iconClass: {
            type: String,
            required: false
        },
        backgroundColor: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>