import { createStore } from "vuex";

import { authentication } from "./modules/authentication";
import { users } from "./modules/users";
import { payment } from "./modules/payment";
import { programs } from "./modules/programs";
import { grouping } from "./modules/grouping";
import { batchWorking } from "./modules/batchworking";
import { dashboard } from "./modules/dashboard";
import { dashboardsetted } from "./modules/dashboardsetted";
import headerStats from "./modules/view-stores/headerstats";
import dashboardheader from "./modules/view-stores/dashboardheader";

export const store = createStore({
  modules: {
    authentication,
    users,
    payment,
    programs,
    grouping,
    batchWorking,
    dashboard,
    headerStats,
    dashboardheader,
    dashboardsetted,
  },
});
