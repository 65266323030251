import { config, api_routes } from "./config";
import { authHeader } from "../helpers";
import axios from "axios";
const currentUserSubject = JSON.parse(localStorage.getItem("user"));

export const MyDevicesService = {
  getMyDevices,
  addNewDevice,
  addNewDeviceWithCode,
  editDevice,
  removeDevice,
  handDevice,
  handRemoveDevice,
  getDeviceOwners,
  currentUser: currentUserSubject
};

function addNewDevice({ name, deviceId }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/user/devices/${deviceId}`, {
        headers: authHeader(),
        data: {
          name: name,
        },
      })
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((err) => reject(err.response.data.errorCode));
  });
}

function addNewDeviceWithCode({ name, deviceId, code }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/user/devices`, {
        headers: authHeader(),
        data: {
          name: name,
          activation: code,
          deviceId: deviceId,
        },
      })
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((err) => reject(err.response.data.errorCode));
  });
}

function handDevice(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/user/handDevice`, {
        headers: authHeader(),
        data: data,
      })
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((err) => reject(err.response.data.errorCode));
  });
}

function handRemoveDevice(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/user/handDeviceRemove`, {
        headers: authHeader(),
        data: data,
      })
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((err) => reject(err.response.data));
  });
}

function getMyDevices() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/user/devices`, {
        headers: authHeader(),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getDeviceOwners(mac) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/user/device/users/${mac}`, {
        headers: authHeader(),
      })
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
}

function editDevice({ id, name, isOpen, mac }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${config.apiUrl}/user/devices/${id}`, {
        headers: authHeader(),
        data: {
          name: name,
          open: isOpen,
          mac: mac,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

function removeDevice(device) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${config.apiUrl}/user/devices/${device.id}`, {
        headers: authHeader(),
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}