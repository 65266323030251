<template>
    <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ title }}
            </label>
            <input @input="handleInput" @keyup="$emit('keyup', [this])" :value="modelValue" :type="type"
                :maxlength="maxLength" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                  disabled:text-blueGray-400
                " :placeholder="placeHolder" :disabled="disabled" />
        </div>
    </div>
</template>

<script>
export default {
    emits: ['keyup', 'update:modelValue'],
    props: {
        modelValue: {
            required: false,
        },
        title: {
            required: false,
            default: ""
        },
        placeHolder: {
            required: false,
        },
        maxLength: {
            required: false,
            type: Number,
            default: 255
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        type: {
            required: false,
            type: String,
            default: "text"
        }
    },
    methods: {
        handleInput(e) {
            this.$emit('update:modelValue', e.target.value);
        }
    }
}
</script>