<template>
  <div>
    <div v-if="showModal" class="
        overflow-x-hidden overflow-y-auto
        fixed
        inset-0
        z-50
        outline-none
        focus:outline-none
        justify-center
        items-center
        flex
      ">
      <div class="relative w-full my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="
            border-0
            rounded-lg
            shadow-lg
            relative
            flex flex-col
            w-full
            bg-white
            outline-none
            focus:outline-none
          ">
          <!--header-->
          <div class="
              flex flex-wrap
              items-start
              justify-between
              p-5
              border-b border-solid border-blueGray-200
              rounded-t
              content-center
            ">
            <h3 class="text-xl font-semibold">
              {{ editMode ? $t("navigation.edit") : $t("navigation.add") }}
            </h3>
            <button class="
                p-1
                ml-auto
                bg-transparent
                border-0
                text-black
                opacity-80
                float-right
                text-3xl
                leading-none
                font-semibold
                outline-none
                focus:outline-none
              " :title="$t('close')" v-on:click="toggleModal()">
              <span class="
                  bg-transparent
                  text-black
                  h-6
                  w-6
                  text-2xl
                  block
                  outline-none
                  focus:outline-none
                ">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
              <label>{{ $t("navigation.selectDay") }}</label>
              <Datepicker v-model="date" datePicker :enableTimePicker="false" :minDate="new Date()"
                :maxDate="new Date(new Date().getFullYear(), 11, 31)" :placeholder="$t('navigation.selectDay')"
                :clearable="false" />
            </p>
            <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
              <label>{{ $t("navigation.description") }}</label>
            </p>

            <form>
              <div class="flex flex-wrap">
                <div class="relative w-full mb-3">
                  <input v-model.trim="description" type="text" maxlength="255" class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      text-blueGray-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    " :placeholder="$t('navigation.description')" />
                </div>
              </div>
            </form>
          </div>
          <div class="
              flex
              items-center
              justify-end
              p-6
              border-t border-solid border-blueGray-200
              rounded-b
            ">
            <button class="
                bg-red-500
                text-white
                active:bg-red-600
                font-bold
                uppercase
                text-sm
                px-6
                py-3
                rounded
                shadow
                hover:shadow-lg
                outline-none
                focus:outline-none
                mr-1
                mb-1
                ease-linear
                transition-all
                duration-150
              " type="button" v-show="editMode" @click="remove()">
              {{ $t("navigation.remove") }}
            </button>
            <button class="
                bg-emerald-500
                text-white
                active:bg-emerald-600
                font-bold
                uppercase
                text-sm
                px-6
                py-3
                rounded
                shadow
                hover:shadow-lg
                outline-none
                disabled:opacity-20
                focus:outline-none
                mr-1
                mb-1
                ease-linear
                transition-all
                duration-150
              " type="button" @click="accept()" :disabled="!validate">
              {{ editMode ? $t("navigation.save") : $t("navigation.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      editMode: false,
      index: -1,
      date: new Date(),
      description: "",
    };
  },
  methods: {
    open: function () {
      this.editMode = false;
      this.showModal = !this.showModal;

      this.date = new Date();
      this.description = "";
    },
    openEdit: function (index, item) {
      this.editMode = true;
      this.index = index;

      this.date = new Date(item.date);
      this.description = item.description;

      this.showModal = !this.showModal;
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    accept: function () {
      const data = {
        date: this.date,
        description: this.description,
      };

      if (this.editMode) {
        this.$emit("editEntry", this.index, data);
        this.toggleModal();

        return;
      }

      this.$emit("addNewEntry", data);
      this.toggleModal();
    },
    remove: function () {
      this.$emit("removeEntry", this.index);
      this.toggleModal();
    },
  },
  computed: {
    validate() {
      return this.date;
    },
  },
};
</script>