<template>
    <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ title }}
            </label>
            <select class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                  disabled:text-blueGray-400" :value="modelValue" @change="handleInput" :disabled="disabled">
                <option v-for="(item, index) in values" :key="index" :value="item[valueKey]">{{
                        typeof item[displayValueKey] === 'string' ? item[displayValueKey] :
                            item.displayValueKey[$i18n.locale]
                }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            required: false,
        },
        title: {
            required: false,
            default: ""
        },
        valueKey: {
            required: true,
            default: ""
        },
        displayValueKey: {
            required: true,
            default: ""
        },
        values: {
            required: true,
            type: Array
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
    },
    methods: {
        handleInput(e) {
            this.$emit('update:modelValue', e.target.value);
        }
    }
}
</script>