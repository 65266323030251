import { DaashboardService } from "../../services";

export const dashboard = {
    namespaced: true,
    state: {
        failedError: null,
    },
    actions: {
        getDashbordMetadata({ commit }, data) {

            return new Promise((resolve, reject) => {

                DaashboardService.getDashboardMetadata(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getChartData({ commit }, data) {

            return new Promise((resolve, reject) => {

                DaashboardService.getChartData(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getChartJsData({ commit }, data) {
            return new Promise((resolve, reject) => {

                DaashboardService.getChartJsData(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getDeviceParameters({ commit }, data) {

            return new Promise((resolve, reject) => {

                DaashboardService.getDeviceParams(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        setDeviceParameters({ commit }, data) {

            return new Promise((resolve, reject) => {

                DaashboardService.postDeviceParams(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        getDeviceLogs({ commit }, data) {

            return new Promise((resolve, reject) => {

                DaashboardService.getDeviceLogs(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        },
        updateDeviceFirmware({ commit }, data) {

            return new Promise((resolve, reject) => {

                DaashboardService.updateDeviceFirmware(data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        commit("getAllFailure", error);
                        reject(error);
                    });
            });
        }
    },
    mutations: {
        getAllFailure(state, data) {
            state.failedError = data;
        },
    },
    getters: {
    },
};