export const Loventi = [
  {
    answer: `Waffle cihazınızı uzaktan kontrol edebilmek için internet bağlantısını yapmış olmanız
    gerekmektedir. Kurulumun detaylı olarak anlatıldığı kılavuza <a
        href="../assets/files/help/Waffle Wi-Fİ Bağlantı Klavuzu.pdf" target="_blank"
        class="bold italic">buradan</a> ulaşabilirsiniz.
    <br> Kılavuzdaki adımları uyguladığınızda cihazınız internete bağlanmış olacaktır.
    Sonrasında <a href="../profile.html" target="_blank" class="bold italic">Hesabım</a>
    sayfasına giderek ilgili cihaz üzerinde işlem yapabilirsiniz.
`,
    question: "Cihazımı Nasıl Uzaktan Kontrol Ederim ?",
  },
  {
    answer: `İnternet bağlantısı kurulumunun detalı olarak anlatıldığı kılavuza <a
    href="../assets/files/help/Waffle Wi-Fİ Bağlantı Klavuzu.pdf" target="_blank"
    class="bold italic">buradan</a> ulaşabilirsiniz. Kılavuzdaki adımları
uyguladığınızda
cihazınız internete bağlanmış olacaktır.`,
    question: "Cihaz İnternet Bağlantısı Nasıl Yapılır ?",
  },
  {
    answer: ` E ile başlayan kodlar cihazın hata durumuna geçtiğini gösterir. Cihaz hata kodları
    listesi aşağıdadır;
    <br>
    <ul style="margin-left: 16px;">
        <li>E-1 Açık Termokupl (TC takılı değil).</li>
        <li>E-2 Ünite 35 dakika içinde SetTemp'e ulaşamadı.</li>
        <li>E-3 Sıcaklığı 520 °F (271 °C) üzeri.</li>
        <li>E-5 Ters Termokupl hatası.</li>
        <li>E-6 Termokupl okuma hatası.</li>
        <li>E-7 PCB çok sıcak 176°F (80 °C).</li>
        <li>E-8 Teknik servis (hayat sayacı).</li>
        <li>E-9 Teknik servis.</li>
    </ul>

    <br> İlgili hatayı giderdiğinizde hata ekrandan kaybolacaktır.
    <br>
    <span class="italic" style="font-size: medium;">Hataların çözülmemesi durumunda yetkili
        servise başvurunuz.</span>`,
    question: "Cihaz Ekranında E İle Başlayan Kod Görünüyor",
  },
];
export const Helps = [
  {
    answer: `
        Hesabınızda cihaz olmaması durumunda cihazlarınız görünmeyecektir. <a
                                    href="../admin/mydevices" target="_blank" class="bold italic">Hesabım</a> sayfasına
                                giderek
                                <span class="bold italic">Yeni Cihaz Ekle</span> butonuna basarak cihazınızı
                                ekleyebilirsiniz.`,
    question: "Cihazlarım hesabımda görünmüyor?",
  },
  {
    answer: `Satın almış olduğunuz cihaz yanında gelen kart üzerinde bulunan <span
      class="italic">cihaz kimliğini</span> <a href="../admin/mydevices" target="_blank"
      class="bold italic">Hesabım</a> sayfasına giderek <span class="italic">Yeni Cihaz
      Ekle</span> butonuna basarak açılan alana cihaz kimliğini ve cihazınıza vereceğiniz
  bir isim ile kayıt edebilirsiniz.
  <br>
  <br>
  <span class="italic" style="font-size: medium;">Not: Bu işlem ücrete tabi
      olabilir.</span>`,
    question: "Cihazımı Nasıl Kayıt Ederim ?",
  },
  {
    answer: `Cihaz kimliği satın aldığınız cihazın hangi cihaz olduğunu anlayabilmek için üretilmiş
    benzersiz bir numara ve karakter grubudur.`,
    question: "Cihaz Kimliği Nedir ?",
  },
  {
    answer: `Cihaz kayıt esnasında girilen <span class="italic">cihaz kimliğinin</span> sitemde
    kayıtlı olmadığı anlamına gelmektedir. Girilen <span class="italic">cihaz
        kimliğini</span> doğru ve eksiksiz şekilde girmiş olduğunuzdan
    emin olunuz. Sorunun çözülmemesi halinde yöneticinize danışınız.`,
    question: "Girilen Kimlikte Cihaz Bulunamadı Hatası Nedir ?",
  },
  {
    answer: `<a href="../admin/mydevices" target="_blank" class="bold italic">Hesabım</a> sayfasına
    giderek izlemek istediğiniz cihazı listede bulup <span class="italic">izle</span>
    butonuna basarak izleyebilirsiniz.`,
    question: "Cihaz Verilerimi Nasıl İzlerim ?",
  },
  {
    answer: `<a href="../admin/mydevices" target="_blank" class="bold italic">Hesabım</a> sayfasına
    giderek ayarlarını görmek veya değiştirmek istediğiniz cihazı listede bulup <span
        class="italic">izle</span> butonuna basarak cihaz sayfasına
    girederek
    <span class="italic">Parametreler</span> sekmesine basarak ulaşabilirsiniz.
`,
    question: "Cihaz Ayarlarını Nasıl Görürüm Veya Değiştiririm ?",
  },
  {
    answer: `Cihaz kimliğinizi satın almış olduğunuz cihaz yanında gelen kart üzerinde
    bulabilirsiniz. Kartın kaybolması veya cihazla birlikte gelmemesi halinde yöneticinize
    danışınız.`,
    question: "Cihaz Kimliğini Nerden Bulabilirim ? ",
  },
  {
    answer: `Cihazınızı kaydederken yazdığınız cihaz adını <a href="../admin/mydevices" target="_blank"
    class="bold italic">Hesabım</a> sayfasına giderek cihaz listesinden değiştirmek
istediğiniz cihazın yanında bulunan
<span class="italic">düzenle</span> butonuna basarak değiştirebilirsiniz.
`,
    question: "Cihazıma Veridiğim Adı Değiştirebilir miyim ?",
  },
  {
    answer: `Cihazlarınızı <a href="../admin/mydevices" target="_blank" class="bold italic">Hesabım</a>
    sayfasında ilgili cihaz kısmında bulunan <span class="italic">düzenle</span> butonu
    yardımı ile kaldırabilirsiniz. İsterseniz sonradan
    cihazınızı tekrar hesabınıza ekleyebilirsiniz.
`,
    question: "Hesabımdaki Cihazı Kaldırabilir miyim ?",
  },
  {
    answer: `Cihazlarınızın kurulumunu <a href="../admin/mydevices" target="_blank"
    class="bold italic">Hesabım</a> sayfasında ilgili cihaz kısmında bulunnan <span
    class="italic">kurulum</span> butonu yardımı ile yapabilirsiniz.
`,
    question: "Cihaz Kurulumu Nasıl Yaparım ?",
  },
];
