export default {
  namespaced: true,
  state() {
    return {
      headerVisibility: false,
      headerData: {},
    };
  },
  actions: {},
  mutations: {
    setHeaderData: (state, payload) => {
      state.headerData = payload;
    },
    setHeaderVisibility: (state, payload) => {
      state.headerVisibility = payload;
    },
  },
  getters: {
    getHeaderData: (state) => {
      return state.headerData;
    },
    getDashboardHeaderVisibility: (state) => {
      return state.headerVisibility;
    },
  },
};
