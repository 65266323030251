import { config } from "./config";
import { authHeader } from "../helpers";
import axios from "axios";

export const DashBoardSettedService = {

    setTemperatureForMail,
    getTemperatureForMail,
    currentUser: () => JSON.parse(localStorage.getItem("user")),
};

function setTemperatureForMail({project, params}) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/${project}/temperaturedata`, {
                headers: authHeader(),
                data: {
                    params,
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getTemperatureForMail({project, params}) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/${project}/temperaturedata/${params.mac}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}