<template>
    <div class="container mx-auto px-4">
        <div class="relative flex flex-col min-w-0 break-words bg-blueGray-100 w-full mb-6 shadow-xl rounded-lg">
            <div class="rounded-t bg-white mb-0 px-6 py-6">
                <div class="text-center flex justify-between">
                    <h6 class="text-blueGray-700 text-xl font-bold">
                        {{ title }}
                    </h6>
                </div>
            </div>

            <div class="border-blueGray-200">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: true,
            tpye: String
        }
    }
}
</script>