<template>
  <div class="relative">
    <router-link to="/profile" class="
        lg:text-white lg:hover:text-blueGray-200
        text-blueGray-700
        px-3
        py-4
        lg:py-2
        flex
        items-center
        text-xs
        uppercase
        font-bold
      " v-on:click="toggleDropdown($event)">
      <i class="
          lg:text-blueGray-200
          text-blueGray-400
          fas
          fa-user
          text-lg
          leading-lg
        " />
      <span class="inline-block ml-2">{{ `${userInfo.name} ${userInfo.surname}` }}</span>
    </router-link>

    <div ref="popoverDropdownRef" class="
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        min-w-32
        right-0
      " v-bind:class="{
        hidden: !dropdownPopoverShow,
        absolute: dropdownPopoverShow,
      }">
      <router-link to="/profile" class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700 text-center
          z-50
        ">
        {{ $t("menu.account") }}
      </router-link>
      <button v-on:click="logout($event)" class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        ">
        {{ $t("menu.logout") }}
      </button>
    </div>
  </div>
</template>

<script>
import image from "@/assets/img/team-1-800x800.jpg";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
      userInfo: {},
    };
  },
  created() {
    const { name, surname } = JSON.parse(localStorage.getItem("user"));
    this.userInfo.name = name;
    this.userInfo.surname = surname;
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
      }
    },
    logout: function (event) {
      // console.info(event)
      this.$store.dispatch("authentication/logout");
      this.$router.push("/");
    },
  },
};
</script>
