<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {{ $t("menu.editGroup") }}
        </h6>
        <div>
          <button class="
              bg-red-500
              text-white
              active:bg-red-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              disabled:opacity-20
              duration-150
            " type="button" :disabled="progressIndicatorVisible" @click="handleGroupRemove()">
            <i class="far fa-trash-alt"></i>
            {{ $t("groups.removeGroup") }}
          </button>

          <button class="
              bg-blue-500
              text-white
              active:bg-blue-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              disabled:opacity-20
              duration-150
            " type="button" :disabled="progressIndicatorVisible || !createButtonEnabled" @click="handleGroupEdit()">
            <i class="far fa-save"></i>
            {{ $t("navigation.save") }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t("groups.groupInfo") }}
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("groups.name") }}
              </label>
              <input v-model.trim="groupData.name" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :placeholder="$t('groups.name')" :disabled="progressIndicatorVisible" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                {{ $t("groups.description") }}
              </label>

              <input v-model.trim="groupData.description" type="text" maxlength="255" class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                " :placeholder="$t('groups.description')" :disabled="progressIndicatorVisible" />
            </div>
          </div>
        </div>
      </form>
      <pulse-loader class="text-center" :loading="progressIndicatorVisible"></pulse-loader>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import { successToast } from "../../../helpers/toast-helper";

export default {
  data() {
    return {
      id: 0,
      groupData: {
        name: "",
        description: "",
      },
      progressIndicatorVisible: false,
    };
  },
  created() {
    const params = base64UrlObjectDecode(this.$route.params.group);
    this.id = params.id;
    this.groupData.name = params.name;
    this.groupData.description = params.description;
  },
  methods: {
    ...mapActions("grouping", ["updateGroup", "removeGroup"]),
    handleGroupEdit() {
      this.progressIndicatorVisible = true;

      this.updateGroup({
        id: this.id,
        name: this.groupData.name,
        description: this.groupData.description,
      })
        .then(() => {
          this.$router.push("/admin/groups");
          successToast(this.$t("messages.updatedSuccesfully"))
        })
        .finally(() => (this.progressIndicatorVisible = false));
    },
    handleGroupRemove() {
      this.progressIndicatorVisible = true;

      this.removeGroup(this.id)
        .then(() => {
          successToast(this.$t("messages.success"));
          this.$router.push("/admin/groups");
        })
        .finally(() => (this.progressIndicatorVisible = false));
    },
  },
  computed: {
    createButtonEnabled() {
      return this.groupData.name.length > 0;
    },
  },
};
</script>