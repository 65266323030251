<template>
  <div>
    <div v-if="showModal" class="
        overflow-x-hidden overflow-y-auto
        fixed
        inset-0
        z-50
        outline-none
        focus:outline-none
        justify-center
        items-center
        flex
      ">
      <div class="relative w-full my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="
            border-0
            rounded-lg
            shadow-lg
            relative
            flex flex-col
            w-full
            bg-white
            outline-none
            focus:outline-none
          ">
          <!--header-->
          <div class="
              flex flex-wrap
              border-b border-solid border-blueGray-200
              rounded-t
            ">
            <h3 class="p-5 text-xl font-semibold">
              {{ editMode ? $t("navigation.edit") : $t("navigation.add") }}
            </h3>
            <button class="
                p-1
                px-6
                ml-auto
                bg-transparent
                border-0
                text-black
                opacity-80
                float-right
                text-3xl
                leading-none
                font-semibold
                outline-none
                focus:outline-none
              " :title="$t('close')" v-on:click="toggleModal()">
              <span class="
                  bg-transparent
                  text-black
                  w-6
                  text-2xl
                  block
                  outline-none
                  focus:outline-none
                  scale-150
                ">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
              <label>{{ $t("programs.startTime") }}</label>
              <Datepicker v-model="startTime" timePicker :minTime="minTime"
                :placeholder="$t('programs.startTimePlaceholder')" :clearable="false" />
            </p>
            <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
              <label>{{ $t("programs.endTime") }}</label>
              <Datepicker v-model="endTime" timePicker :minTime="startTime" :maxTime="maxTime"
                :placeholder="$t('programs.endTimePlaceholder')" :clearable="false" />
            </p>
          </div>
          <div class="
              flex
              items-center
              justify-end
              p-6
              border-t border-solid border-blueGray-200
              rounded-b
            ">
            <button class="
                bg-red-500
                text-white
                active:bg-red-600
                font-bold
                uppercase
                text-sm
                px-6
                py-3
                rounded
                shadow
                hover:shadow-lg
                outline-none
                focus:outline-none
                mr-1
                mb-1
                ease-linear
                transition-all
                duration-150
              " type="button" v-show="editMode" @click="remove()">
              {{ $t("navigation.remove") }}
            </button>
            <button class="
                bg-emerald-500
                text-white
                active:bg-emerald-600
                font-bold
                uppercase
                text-sm
                px-6
                py-3
                rounded
                shadow
                hover:shadow-lg
                outline-none
                disabled:opacity-20
                focus:outline-none
                mr-1
                mb-1
                ease-linear
                transition-all
                duration-150
              " type="button" @click="accept()" :disabled="!validate">
              {{ editMode ? $t("navigation.save") : $t("navigation.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      editMode: false,
      index: -1,
      minTime: { hours: 0, minutes: 0 },
      maxTime: { hours: 23, minutes: 59 },
      startTime: {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
      },
      endTime: {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
      },
    };
  },
  methods: {
    open: function (lastTile) {
      if (lastTile) {
        this.minTime = lastTile.endTime;
        this.maxTime = { hours: 23, minutes: 59 };
      } else {
        this.minTime = { hours: 0, minutes: 0 };
        this.maxTime = { hours: 23, minutes: 59 };
      }

      if (lastTile) {
        this.startTime = this.minTime;
      } else {
        this.startTime = {
          hours: new Date().getHours(),
          minutes: new Date().getMinutes(),
        };
      }

      this.endTime = this.startTime;

      this.editMode = false;
      this.showModal = !this.showModal;
    },
    openEdit: function (index, item, previusTile, nextTile) {
      this.minTime = previusTile
        ? previusTile.endTime
        : { hours: 0, minutes: 0 };

      this.maxTime = nextTile ? nextTile.startTime : { hours: 23, minutes: 59 };

      this.showModal = !this.showModal;
      this.editMode = true;

      this.index = index;
      this.startTime = item.startTime;
      this.endTime = item.endTime;
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    accept: function () {
      if (this.editMode) {
        this.$emit("editEntry", this.index, {
          startTime: this.startTime,
          endTime: this.endTime,
        });

        this.toggleModal();

        return;
      }

      this.$emit("addNewEntry", {
        startTime: this.startTime,
        endTime: this.endTime,
      });

      this.toggleModal();
    },
    remove: function () {
      this.$emit("removeEntry", this.index);
      this.toggleModal();
    },
    toMinutes(time) {
      return time.hours * 60 + time.minutes;
    },
  },
  computed: {
    validate() {
      const fields = this.startTime && this.endTime;

      if (fields) {
        const startMinutes = this.toMinutes(this.startTime);
        const endMinutes = this.toMinutes(this.endTime);

        if (startMinutes >= endMinutes) return false;
      }

      return fields;
    },
  },
};
</script>