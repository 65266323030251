<template>
    <Card>
        <Toolbar :title="$t('menu.editUsers')">
            <Button :title="$t('navigation.save')" icon-class="far fa-save" background-color="bg-blue-500"
                :disabled="progressIndicatorVisible || !saveButtonEnabled" @click="handleSave" />
        </Toolbar>

        <Form>
            <FormSection :title="$t('admin.userInfo')">
                <TextInput v-model.trim="params.name" :maxLength="255" :title="$t('users.name')"
                    :placeHolder="$t('users.name')" :disabled="progressIndicatorVisible" />

                <TextInput v-model.trim="params.surname" :maxLength="255" :title="$t('users.surname')"
                    :placeHolder="$t('users.surname')" :disabled="progressIndicatorVisible" />

                <TextInput v-model.trim="params.email" :maxLength="255" :title="$t('users.email')"
                    :placeHolder="$t('users.email')" :disabled="true" />
            </FormSection>

            <FormSection :title="$t('users.userPrivileges')">
                <CheckBoxInput v-model="params.isSuperUser" :title="$t('users.isSuperAdmin')"
                    :disabled="progressIndicatorVisible" />

                <CheckBoxInput v-model="params.isStaff" :title="$t('users.isStaff')"
                    :disabled="progressIndicatorVisible" />

                <CheckBoxInput v-model="params.status" :title="$t('users.status')"
                    :disabled="progressIndicatorVisible" />
            </FormSection>

            <PulseLoader class="text-center" :loading="progressIndicatorVisible" />
        </Form>
    </Card>
</template>

<script>
import Card from "../../../components/Cards/Card";
import Button from "../../../components/Buttons/Button";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import TextInput from "../../../components/Forms/TextInput.vue";
import CheckBoxInput from "../../../components/Forms/CheckBoxInput.vue";
import Toolbar from "../../../components/Forms/Toolbar.vue";
import FormSection from "../../../components/Forms/FormSection.vue";
import Form from "../../../components/Forms/Form.vue";
import { successToast, errorToast } from "../../../helpers/toast-helper";
import { updateUser } from "../../../services/admin.service";

export default {
    components: {
        Card,
        Button,
        TextInput,
        Toolbar,
        FormSection,
        Form,
        CheckBoxInput
    },
    data() {
        return {
            params: {},
            progressIndicatorVisible: false,
        };
    },
    created() {
        this.params = base64UrlObjectDecode(this.$route.params.user);
    },
    methods: {
        handleSave() {
            this.progressIndicatorVisible = true;

            updateUser({
                id: this.params.id,
                data: {
                    email: this.params.email,
                    name: this.params.name,
                    surname: this.params.surname,
                    isStaff: this.params.isStaff,
                    isSuperUser: this.params.isSuperUser,
                    status: this.params.status,
                },
            })
                .then(() => successToast(this.$t("messages.updatedSuccesfully")))
                .catch(() => errorToast(this.$t("messages.updateFailed")))
                .finally(() => (this.progressIndicatorVisible = false));
        },
    },
    computed: {
        saveButtonEnabled() {
            return this.params.name.length > 0 && this.params.surname.length > 0;
        },
    },
};
</script>