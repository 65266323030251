<template>
  <TabNavbar :projectInfo="project" @onSelect="onTabSelected" />
  <component
    v-if="selectedTab"
    :is="component"
    :key="selectedTab.component"
    :config="selectedTab.config"
    :device="device"
    :project="project"
  />
  <Loading :isActive="progressIndicatorVisible" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import TabNavbar from "../../../components/DashboardV2/TabNavbar.vue";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import Loading from "../../../components/Common/Loading.vue";
import Error from "../../../components/Common/Error.vue";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      project: {},
      device: {},
      progressIndicatorVisible: false,
      selectedTab: null,
    };
  },
  components: {
    TabNavbar,
    Loading,
    Error,
  },
  created() {
    let params = base64UrlObjectDecode(this.$route.params.device);

    this.project = params.projectInfo;
    delete params.projectInfo;
    this.device = params;
  },
  methods: {
    ...mapMutations("headerStats", ["setHeader"]),

    onTabSelected(index, tab) {
      this.selectedTab = tab;
    },
  },
  computed: {
    component() {
      this.selectedTab;
      this.setHeader({ title: this.device.userGivenName, visibility: true, image: this.project.databasename });

      return defineAsyncComponent({
        loader: () =>
          import(
            `../../../components/DashboardV2/Tabs/${this.selectedTab.component}.vue`
          ),
        loadingComponent: Loading,
        delay: 200,
        errorComponent: Error,
      });
    },
  },
  unmounted() {
    this.setHeader({ title: "", visibility: false });
  },
};
</script>
