<template>
  <div class="flex flex-wrap w-full z-10">
    <ClickableTable :title="$t('menu.apollo32.programs')" tableId="apolloProgramsTable" :headers="headers" :rows="rows"
      @onClick="onProgramClick" />
  </div>
  <pulse-loader class="text-center" :loading="progressIndicatorVisible"></pulse-loader>

  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      rounded
      mb-6
      xl:mb-0
      shadow-lg
    ">
    <div class="flex-auto p-4">
      <div class="flex flex-wrap">
        <RouterButton :title="$t('programs.createProgram')" :route-to="{ name: 'apollo32.program.create' }"
          icon-class="fas fa-plus" />
        <RouterButton :title="$t('devices.help')" route-to="/help" icon-class="fas fa-question-circle" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ClickableTable from "../../../../components/Tables/ClickableTable.vue";
import RouterButton from "../../../../components/Buttons/RouterButton.vue";
import { base64UrlObjectEncode } from "../../../../helpers/encoing-helper";

export default {
  data() {
    return {
      headers: [
        { langKey: "programs.name", key: "name" },
        { langKey: "programs.description", key: "description" },
        { langKey: "groups.actions", key: "actions" },
      ],
      values: [],
      progressIndicatorVisible: false,
    };
  },
  created() {
    this.progressIndicatorVisible = true;
    this.getApollo32Schedules()
      .then((data) => (this.values = data))
      .finally(() => (this.progressIndicatorVisible = false));
  },
  methods: {
    ...mapActions("programs", ["getApollo32Schedules"]),
    onNewProgramClicked(index, item) {
      this.$router.push({
        name: "apollo32.program.create",
        params: {
          project: base64UrlObjectEncode(this.values[index]),
        },
      });
    },
    onProgramClick(index, item) {
      this.$router.push({
        name: "apollo32.program.edit",
        params: {
          program: base64UrlObjectEncode(this.values[index]),
        },
      });
    },
  },
  components: {
    ClickableTable,
    RouterButton
  },
  computed: {
    rows: function () {
      if (!Array.isArray(this.values)) return;

      return this.values.map((x) => {
        const buttons = [];

        if (!x.isDefault) {
          buttons.push({
            langKey: "menu.apollo32.program.edit",
            routerLink: {
              name: "apollo32.program.edit",
              params: {
                program: base64UrlObjectEncode({
                  id: x.id,
                  name: x.name,
                  description: x.description,
                }),
              },
            },
            iconClass: "far fa-edit",
          });
        }

        return {
          columns: [{ name: x.name }, { description: x.description }],
          buttons: buttons,
          isDefault: x.isDefault
        };
      });
    },
  },
};
</script>