<template>
  <div class="flex flex-wrap w-full z-10">
    <SelectableTable :title="
      params.name +
      (params.description ? ' (' + params.description + ')' : '')
    " :headers="headers" :rows="values" @onItemClick="onItemClick" />
  </div>
  <pulse-loader class="text-center" :loading="progressIndicatorVisible"></pulse-loader>

  <div class="flex justify-end">
    <button class="
      bg-emerald-500
      text-white
      active:bg-emerald-600
      font-bold
      uppercase
      text-xs
      px-4
      py-2
      rounded
      shadow
      hover:shadow-md
      outline-none
      focus:outline-none
      mr-1
      ease-linear
      transition-all
      disabled:opacity-20
      duration-150
    " type="button" @click="handleSaveChanges()" :disabled="progressIndicatorVisible">
      <i class="fas fa-save"></i>
      {{ $t("navigation.save") }}
    </button>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import SelectableTable from "../../../components/Tables/SelectableTable.vue";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import { successToast } from "../../../helpers/toast-helper";

export default {
  data() {
    return {
      ...mapState("users", ["devices"]),
      headers: [
        { langKey: "devices.serialNo", key: "serialNo" },
        { langKey: "devices.name", key: "name" },
        { langKey: "devices.projectName", key: "projectName" },
        { langKey: "devices.addingtime", key: "addingtime" },
        { langKey: "groups.addRemove", key: "include" },
      ],
      values: [],
      progressIndicatorVisible: true,
      params: {},
    };
  },
  created() {
    this.params = base64UrlObjectDecode(this.$route.params.group);

    this.progressIndicatorVisible = true;
    this.getGroupDevices(this.params.id).then((groupDevices) => {
      this.getMyDevices()
        .then(
          (data) =>
          (this.values = this.createRows(
            data.filter((x) => x.device.projectInfo.hasimplementation),
            groupDevices.map((e) => e.device.id)
          ))
        )
        .finally(() => (this.progressIndicatorVisible = false));
    });
  },
  methods: {
    ...mapActions("users", ["getMyDevices"]),
    ...mapActions("grouping", ["getGroupDevices", "setGroupDevices"]),
    createRows(values, groupDevices) {
      if (!Array.isArray(values)) return [];
      return values.map((x) => {
        return {
          columns: [
            { serialNo: x.device.serialno },
            { name: x.name },
            { projectName: x.device.projectInfo.name },
            { addingtime: moment(x.addingtime).format("YYYY-MM-DD") },
          ],
          selected: groupDevices.includes(x.id),
          deviceId: x.id,
        };
      });
    },
    onItemClick(index) {
      this.values[index].selected = !this.values[index].selected;
    },
    handleSaveChanges() {
      this.progressIndicatorVisible = true;

      this.setGroupDevices({
        groupId: this.params.id,
        deviceIds: this.getSelectedDevicesIds(),
      })
        .then(() => {
          this.$router.push("/admin/groups");
          successToast(this.$t("messages.addedSuccesfully"))
        })
        .finally(() => (this.progressIndicatorVisible = false));
    },
    getSelectedDevicesIds() {
      return this.values.filter((e) => e.selected).map((e) => e.deviceId);
    },
  },
  components: {
    SelectableTable,
  },
};
</script>