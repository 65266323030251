<template>
    <router-link :to="routeTo" v-slot="{ href, navigate }">
        <a :href="href" @click="navigate"
            class=" px-4 py-5 text-xs uppercase font-bold block hover:text-blueGray-500 transition-all">
            <i :class="iconClass"></i>
            {{ title }}
        </a>
    </router-link>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        routeTo: {
            required: true
        },
        iconClass: {
            type: String,
            required: true
        }
    }
}
</script>