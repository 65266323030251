import { config, api_routes } from "./config";
import { authHeader } from "../helpers";
import axios from "axios";
const currentUserSubject = JSON.parse(localStorage.getItem("user"));

export const ProgramsService = {
    getApollo32Schedules,
    postApollo32Schedule,
    putApollo32Schedule,
    deleteApollo32Schedule,
    getApollo32ScheduleData,
    postApollo32ScheduleData,
    getApollo32ExceptionsData,
    currentUser: currentUserSubject,
};

function getApollo32Schedules() {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}${api_routes.apollo32.schedules.url}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postApollo32Schedule(data) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${api_routes.apollo32.schedules.url}`, {
                headers: authHeader(),
                data,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function putApollo32Schedule({ id, data }) {

    return new Promise((resolve, reject) => {
        axios
            .put(`${config.apiUrl}${api_routes.apollo32.schedules.url}/${id}`, {
                headers: authHeader(),
                data,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function deleteApollo32Schedule(id) {

    return new Promise((resolve, reject) => {
        axios
            .delete(`${config.apiUrl}${api_routes.apollo32.schedules.url}/${id}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getApollo32ScheduleData(id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}${api_routes.apollo32.schedulesData.url}/${id}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postApollo32ScheduleData({ id, data }) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${api_routes.apollo32.schedulesData.url}/${id}`, {
                headers: authHeader(),
                data,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getApollo32ExceptionsData(id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}${api_routes.apollo32.exceptions.url}/${id}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}