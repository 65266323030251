<template>
  <Card>
    <Toolbar :title="$t('menu.editDevice')">
      <button class="
          bg-blue-500
          text-white
          active:bg-blue-600
          font-bold
          uppercase
          text-xs
          px-4
          py-2
          rounded
          shadow
          hover:shadow-md
          outline-none
          focus:outline-none
          mr-1
          ease-linear
          transition-all
          duration-150
          disabled:opacity-20
        " type="button" :disabled="!saveButtonEnabled || progressIndicatorVisible" @click="handleDeviceEdit()">
        <i class="far fa-save"></i>
        {{ $t("navigation.save") }}
      </button>
    </Toolbar>

    <Form>
      <FormSection :title="$t('devices.deviceInfo')">
        <TextInput v-model.trim="deviceName" :maxLength="255" :title="$t('devices.name')"
          :placeHolder="$t('devices.name')" :disabled="progressIndicatorVisible" />
      </FormSection>

      <FormSection :title="$t('devices.shareDevice')" v-if="isAdmin">
        <TextInput v-model.trim="newOwnership" :maxLength="320" :title="$t('devices.shareDevice')"
          placeHolder="email@address.com" :disabled="progressIndicatorVisible" />

        <div class="grid place-items-center mt-5 mb-2">
          <button class="
              bg-blue-500
              text-white
              active:bg-blue-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
              disabled:opacity-20
            " type="button" :disabled="progressIndicatorVisible || !validateEmail(newOwnership)"
            @click="shareDevice()">
            <i class="far fa-paper-plane"></i>
            {{ $t("devices.shareDevice") }}
          </button>
        </div>

        <TextInput v-model.trim="handRemove" :maxLength="320" :title="$t('devices.handRemoveDevice')"
          placeHolder="email@address.com" :disabled="progressIndicatorVisible" />

        <div class="grid place-items-center mt-5 mb-2">
          <button class="
              bg-red-500
              text-white
              active:bg-red-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
              disabled:opacity-20
            " type="button" :disabled="progressIndicatorVisible || !validateEmail(handRemove)"
            @click="handRemoveDeviceonClick()">
            <i class="far fa-paper-plane"></i>
            {{ $t("navigation.remove") }}
          </button>
        </div>

        <CheckBoxInput v-model="isOpen" :title="$t('devices.openDeviceToEveryone')"
          :disabled="progressIndicatorVisible" />
      </FormSection>

      <FormSection :title="$t('devices.removeDevice')">
        <div class="relative w-full mb-3">
          <label class="uppercase text-blueGray-600 text-sm font-bold">
            {{ $t("devices.cannotBeUndone") }}
          </label>

          <router-link :to="{ name: 'removeDevice', params: this.$route.params }">
            <button class="
                bg-red-500
                text-white
                active:bg-red-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
                ml-2
              " type="button" :disabled="progressIndicatorVisible">
              <i class="far fa-trash-alt"></i>

              {{ $t("devices.removeDevice") }}
            </button>
          </router-link>
        </div>
      </FormSection>
    </Form>
  </Card>

  <pulse-loader class="text-center my-4" :loading="progressIndicatorVisible" />

  <ClickableTable v-if="isAdmin" :title="$t('menu.users')" :headers="headers" :rows="rows" />
</template> 

<script>
import Card from "../../../components/Cards/Card";
import TextInput from "../../../components/Forms/TextInput";
import Toolbar from "../../../components/Forms/Toolbar";
import Form from "../../../components/Forms/Form";
import FormSection from "../../../components/Forms/FormSection";
import { mapActions } from "vuex";
import { base64UrlObjectDecode } from "../../../helpers/encoing-helper";
import { errorToast, successToast } from "../../../helpers/toast-helper";
import CheckBoxInput from "../../../components/Forms/CheckBoxInput";
import ClickableTable from "../../../components/Tables/ClickableTable.vue";

export default {
  data() {
    return {
      entryId: 0,
      deviceId: "",
      deviceName: "",
      newOwnership: "",
      handRemove: "",
      isOpen: false,
      isAdmin: false,
      progressIndicatorVisible: false,
      headers: [
        { langKey: "users.email", key: "email" },
      ],
      values: [],
    };
  },
  created() {
    const params = base64UrlObjectDecode(this.$route.params.device);
    this.entryId = params.id;
    this.deviceId = params.deviceid;
    this.deviceName = params.name;
    this.isOpen = params.isOpen;
    this.isAdmin = parseInt(localStorage.getItem("uid")) == params.adminUserId;

    if (this.isAdmin)
      this.loadDeviceOwners();
  },
  methods: {
    ...mapActions("users", ["editDevice", "handDevice", "handRemoveDevice", "getDeviceOwners"]),
    handleDeviceEdit() {
      this.progressIndicatorVisible = true;

      this.editDevice({
        id: this.entryId,
        mac: this.deviceId,
        name: this.deviceName,
        isOpen: this.isAdmin ? this.isOpen : undefined,
      })
        .then((_) => {
          this.deviceName = this.deviceName;
          this.$router.push({ name: "mydevices" });

          successToast(this.$t("messages.updatedSuccesfully"));
        })
        .catch((error) => errorToast(this.$t("messages.updateFailed")))
        .finally(() => (this.progressIndicatorVisible = false));
    },
    shareDevice() {
      if (this.newOwnership === "") {
        this.$swal(this.$t("errors.enterAnEmail"));
        return;
      }

      this.progressIndicatorVisible = true;

      this.handDevice({
        name: this.deviceName,
        deviceId: this.deviceId,
        email: this.newOwnership,
      })
        .then(() => {
          this.newOwnership = "";
          successToast(this.$t("messages.success"));
        })
        .catch((error) => {
          this.newOwnership = "";

          if (error == "userNotFound") {
            this.$swal({
              title: this.$t("admin.userNotFound"),
              icon: "error",
            });
          } else {
            errorToast(this.$t("messages.failed"));
          }
        }).finally(() => {
          this.progressIndicatorVisible = false;
          this.loadDeviceOwners();
        });
    },
    handRemoveDeviceonClick() {
      if (this.handRemove === "") {
        this.$swal(this.$t("errors.enterAnEmail"));
        return;
      }
      this.progressIndicatorVisible = true;

      this.handRemoveDevice({
        deviceId: this.deviceId,
        email: this.handRemove,
      })
        .then(() => {
          this.handRemove = "";
          successToast(this.$t("messages.success"));
        })
        .catch((error) => {
          this.handRemove = "";

          if (error == "userNotFound") {
            this.$swal({
              title: this.$t("admin.userNotFound"),
              icon: "error",
            });
          } else {
            errorToast(this.$t("messages.failed"));
          }
        }).finally(() => {
          this.progressIndicatorVisible = false;
          this.loadDeviceOwners();
        });
    },
    validateEmail(mail) {
      return mail.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    loadDeviceOwners() {

      this.progressIndicatorVisible = true;
      this.getDeviceOwners(this.deviceId)
        .then((data) => this.values = data)
        .catch(() => errorToast(this.$t("errors.errorOccured")))
        .finally(() => this.progressIndicatorVisible = false);
    }
  },
  computed: {
    saveButtonEnabled() {
      return this.deviceName.length > 0;
    },
    rows() {
      if (!Array.isArray(this.values)) return;

      return this.values.map((x) => {
        return {
          columns: [
            { email: x.user.email },
          ]
        };
      });
    },
  },
  components: {
    Card,
    TextInput,
    Toolbar,
    Form,
    FormSection,
    CheckBoxInput,
    ClickableTable,
  },
};
</script>