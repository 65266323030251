export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("accessToken");

  if (token) {
    return { authorization: token };
  } else {
    return {};
  }
}
