import { createRouter, createWebHashHistory } from "vue-router";

// layouts
import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

import Help from "@/views/help/Index.vue";

// Admin Panel
import Unauthorised from "@/views/management/Unauthorised.vue";
import Devices from "@/views/management/Devices/List.vue";
import EditDevices from "@/views/management/Devices/Edit.vue";
import RemoveDevices from "@/views/management/Devices/Remove.vue";
import AddDevices from "@/views/management/Devices/Add.vue";
import Users from "@/views/management/Users/List.vue";
import EditUsers from "@/views/management/Users/Edit.vue";
import Projects from "@/views/management/Projects/List.vue";
import EditProjects from "@/views/management/Projects/Edit.vue";

// Dashboard
import Dashboard from "@/views/admin/Dashboard/Dashboard.vue";

// Devices
import MyDevices from "@/views/admin/Devices/MyDevices.vue";
import AddDevice from "@/views/admin/Devices/AddDevice.vue";
import AddDeviceWithCode from "@/views/admin/Devices/AddDeviceWithCode.vue";
import EditDevice from "@/views/admin/Devices/EditDevice.vue";
import RemoveDevice from "@/views/admin/Devices/RemoveDevice.vue";

// Device grouping
import Groups from "@/views/admin/Grouping/Groups.vue";
import CreateGroup from "@/views/admin/Grouping/CreateGroup.vue";
import EditGroup from "@/views/admin/Grouping/EditGroup.vue";
import AddGroupDevice from "@/views/admin/Grouping/AddGroupDevice.vue";

// Batch working
import BatchWorkingProfiles from "@/views/admin/BatchWorking/Profiles.vue";
import BatchWorkingProfileEdit from "@/views/admin/BatchWorking/ProfileEdit.vue";
import BatchWorkingProfileCreate from "@/views/admin/BatchWorking/ProfileCreate.vue";
// Pages
import Appollo32BatchWorkingProfile from "@/views/admin/BatchWorking/pages/Apollo32.vue";

// Programs
import ListApollo32Programs from "@/views/admin/Programs/Apollo32/List.vue";
import EditApollo32Program from "@/views/admin/Programs/Apollo32/Edit.vue";
import CreateApollo32Program from "@/views/admin/Programs/Apollo32/Create.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import Verify from "@/views/auth/Verify.vue";
import ForgetPassword from "@/views/auth/ForgetPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";

// views without layouts


import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import * as jose from "jose";

const routes = [
  {
    path: "/admin",
    redirect: "/admin/mydevices",
    meta: { authorize: [] },
    component: Admin,
    children: [
      {
        name: "dashboard",
        path: "/admin/dashboard/:device",
        component: Dashboard,
      },
      {
        name: "groups",
        path: "/admin/groups",
        component: Groups,
      },
      {
        name: "createGroup",
        path: "/admin/groups/new",
        component: CreateGroup,
      },
      {
        name: "editGroup",
        path: "/admin/groups/edit/:group",
        component: EditGroup,
      },
      {
        name: "addGroupDevice",
        path: "/admin/group/adddevice/:group",
        component: AddGroupDevice,
      },
      {
        name: "mydevices",
        path: "/admin/mydevices",
        component: MyDevices,
      },
      {
        name: "addNewDevice",
        path: "/admin/mydevices/new",
        component: AddDevice,
      },
      {
        name: "addNewDeviceWithCode",
        path: "/admin/mydeviceswithcode/new",
        component: AddDeviceWithCode,
      },
      {
        name: "editDevice",
        path: "/admin/mydevices/edit/:device",
        component: EditDevice,
      },
      {
        name: "removeDevice",
        path: "/admin/mydevices/remove/:device",
        component: RemoveDevice,
      },
    ],
  },
  {
    path: "/admin/programs/apollo32",
    redirect: "/auth/mydevices",
    component: Admin,
    children: [
      {
        name: "apollo32.programs",
        path: "/admin/programs/apollo32",
        component: ListApollo32Programs,
      },
      {
        name: "apollo32.program.edit",
        path: "/admin/programs/apollo32/edit/:program",
        component: EditApollo32Program,
      },
      {
        name: "apollo32.program.create",
        path: "/admin/programs/apollo32/create",
        component: CreateApollo32Program,
      },
    ],
  },
  {
    path: "/admin/batchworking",
    redirect: "/admin/batchworking",
    component: Admin,
    children: [
      {
        name: "batchWorking",
        path: "/admin/batchworking",
        component: BatchWorkingProfiles,
      },
      {
        name: "createBatchWorking",
        path: "/admin/batchworking/create/:project",
        component: BatchWorkingProfileCreate,
      },
      {
        name: "editBatchWorking",
        path: "/admin/batchworking/edit/:profile",
        component: BatchWorkingProfileEdit,
      },
    ],
  },
  {
    path: "/admin/batchworking/pages",
    redirect: "/admin/batchworking",
    component: Admin,
    children: [
      {
        name: "batchWorking_apollo32",
        path: "/admin/batchworking/pages/apollo32/:profile",
        component: Appollo32BatchWorkingProfile,
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        name: "Login",
        path: "/auth/login",
        component: Login,
      },
      {
        name: "Register",
        path: "/auth/register",
        component: Register,
      },
      {
        name: "ForgetPassword",
        path: "/auth/forget",
        component: ForgetPassword,
      },
      {
        name: "ResetPassword",
        path: "/auth/resetpassword",
        component: ResetPassword,
      },
      {
        name: "Verify",
        path: "/auth/verify",
        component: Verify,
      },
    ],
  },
  {
    path: "/profile",
    component: Profile,
    meta: { authorize: [] },
  },
  {
    path: "/admin/panel",
    redirect: "/",
    meta: { authorize: ["isSuperUser", "isStaff"] },
    component: Admin,
    children: [
      {
        name: "devices",
        path: "/admin/panel/devices",
        component: Devices,
      },
      {
        name: "devices",
        path: "/admin/panel/devices",
        component: Devices,
      },
      {
        name: "editDevices",
        path: "/admin/panel/devices/edit/:device",
        component: EditDevices,
      },
      {
        name: "removeDevices",
        path: "/admin/panel/devices/remove/:device",
        component: RemoveDevices,
      },
      {
        name: "addDevices",
        path: "/admin/panel/devices/new",
        component: AddDevices,
      },
      {
        name: "users",
        path: "/admin/panel/users",
        component: Users,
      },
      {
        name: "editUsers",
        path: "/admin/panel/users/edit/:user",
        component: EditUsers,
      },
      {
        name: "projects",
        path: "/admin/panel/projects",
        component: Projects,
      },
      {
        name: "editProject",
        path: "/admin/panel/projects/edit/:project",
        component: EditProjects,
      }
    ],
  },
  {
    path: "/help/",
    component: Help,
  },
  {
    path: "/unauthorised/",
    component: Unauthorised,
  },
  {
    path: "/",
    component: Landing,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

export const router = new createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;

  const currentUser = JSON.parse(localStorage.getItem("user"));

  if (authorize) {
    if (!currentUser)
      return next({ path: "/auth", query: { returnUrl: to.path } });

    const token = jose.decodeJwt(currentUser.accessToken);

    if (authorize.length) {

      for (let i = 0; i < authorize.length; i++) {

        if (token[authorize[i]])
          return next();
      }

      return next({ path: "/unauthorised" });
    }
  }

  next();
});
