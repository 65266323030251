<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      h-full
      mb-6
      mt-6
      shadow-lg
      rounded
      bg-white
      te
      border-none
      p-4
      box-border
    ">
    <div class="text-center" v-if="editEnabled">
      <button @click="addNewEntryClicked" type="button" class="
          outline-none
          hover:bg-warmGray-200
          rounded-md
          cursor-pointer
          disabled:opacity-20
          px-2
          transition-all
        ">
        <i class="fas fa-plus-circle p-3"></i>
        {{ $t("programs.addNewException") }}
      </button>
    </div>

    <div class="box-border border-none mt-1 ml-1 rounded">
      <div class="divide-gray-400 border-none box-border">
        <ExceptionDayItem v-for="(item, index) in exceptionItems" :key="index" :index="index" :date="item.date"
          :description="item.description" @editEntry="onItemClick" :editEnabled="editEnabled" />
      </div>
    </div>
  </div>
  <ExceptionDialog ref="exceptionsDialog" @addNewEntry="onAddException" @editEntry="onEditException"
    @removeEntry="onRemoveException" />
</template>

<script>
import moment from "moment";
import ExceptionDayItem from "./ExceptionDayItem.vue";
import ExceptionDialog from "./ExceptionDialog.vue";

export default {
  props: {
    editEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      exceptionItems: [],
    };
  },
  methods: {
    setData(data) {
      if (data) this.exceptionItems = data;
    },
    addNewEntryClicked() {
      this.$refs.exceptionsDialog.open();
    },
    onItemClick(index) {
      if (!this.editEnabled)
        return;

      this.$refs.exceptionsDialog.openEdit(index, this.exceptionItems[index]);
    },
    onAddException(value) {
      this.exceptionItems.push(value);
    },
    onEditException(index, value) {
      this.exceptionItems[index] = value;
    },
    onRemoveException(index) {
      this.exceptionItems.splice(index, 1);
    },
    getData() {
      return this.exceptionItems.map((e) => ({
        date: moment(e.date).format("YYYY/MM/DD"),
        description: e.description,
      }));
    },
  },
  components: {
    ExceptionDayItem,
    ExceptionDialog,
  },
};
</script>