<template>
    <div class="flex flex-wrap w-full z-10">
        <ClickableTable :title="$t('menu.users')" tableId="users" :headers="headers" :rows="rows"
            @onClick="onDeviceClicked" />
    </div>
    <PulseLoader class="text-center" :loading="busy" />
</template>

<script>
import ClickableTable from "../../../components/Tables/ClickableTable.vue";
import { base64UrlObjectEncode } from "../../../helpers/encoing-helper";
import { getUsers } from "../../../services/admin.service";

export default {
    data() {
        return {
            headers: [
                { langKey: "devices.id", key: "id" },
                { langKey: "users.name", key: "name" },
                { langKey: "users.surname", key: "surname" },
                { langKey: "users.email", key: "email" },
                { langKey: "users.isSuperAdmin", key: "isSuperAdmin" },
                { langKey: "users.isStaff", key: "isStaff" },
                { langKey: "users.status", key: "status" },
                { langKey: "devices.actions", key: "actions" },
            ],
            values: [],
            busy: false,
        };
    },
    created() {
        this.busy = true;
        getUsers()
            .then((res) => (this.values = res))
            .finally(() => (this.busy = false));
    },
    methods: {
        onDeviceClicked(index, item) {

            this.$router.push({
                name: "editUsers",
                params: {
                    user: base64UrlObjectEncode(this.values[index]),
                },
            });
        },
    },
    components: {
        ClickableTable,
    },
    computed: {
        rows() {
            if (!Array.isArray(this.values)) return [];

            return this.values.map((x) => {

                return {
                    columns: [
                        { id: x.id },
                        { name: x.name },
                        { surname: x.surname },
                        { email: x.email },
                        { isSuperAdmin: x.isSuperUser ? this.$t("projects.var") : this.$t("projects.yok") },
                        { isStaff: x.isStaff ? this.$t("projects.var") : this.$t("projects.yok") },
                        { status: x.status ? "✓" : "X" },
                    ],
                    buttons: [{
                        langKey: "devices.edit",
                        routerLink: {
                            name: "editUsers",
                            params: { user: base64UrlObjectEncode(x) },
                        },
                        iconClass: "far fa-edit",
                    }],
                };
            });
        },
    },
};
</script>