<template>
  <div class="container mx-auto px-4 h-full ">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="flex-auto px-4 lg:px-10 py-10">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>{{ $t("auth.changepassword") }}</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  {{ $t("auth.password") }}
                </label>
                <input type="password" v-model="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('auth.password')" />
              </div>
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  {{ $t("auth.passwordAgain") }}
                </label>
                <input type="password" v-model="password2"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('auth.passwordAgain')" />
              </div>
              <div class="text-center mt-6">
                <button class="
                    bg-blueGray-800
                    text-white
                    active:bg-blueGray-600
                    text-sm
                    font-bold
                    uppercase
                    px-6
                    py-3
                    rounded
                    shadow
                    hover:shadow-lg
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                    disabled:opacity-20
                  " type="button" @click="handleSubmit" :disabled="!validate">
                  {{ $t("auth.changepassword") }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/auth/login" class="text-blueGray-200 hover:text-blue-400 transition-all">
              <small>{{ $t("auth.loginTitle") }}</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      ...mapState("authentqication", ["user"]),
      password: "",
      password2: "",
      salt: null,
    };
  },
  created() {
    if (this.loginState)
      return this.$router.push("/");

    this.salt = this.$route.query.salt;
  },
  methods: {
    ...mapActions("authentication", ["resetPassword"]),
    handleSubmit() {

      if (this.password !== this.password2) {

        this.$swal({
          title: this.$t("auth.passwordsNotMatching"),
          icon: 'error',
        });

        return;
      }

      this.resetPassword({ salt: this.salt, password: this.password })
        .then(() => {
          this.$swal(this.$t("auth.passwordChangeSuccess"));
          this.$router.push("/auth/login");
        })
        .catch((error) => {
          console.log(error);

          this.$swal({
            icon: 'error',
            title: this.$t("errors.errorOccured")
          });
        });
    },
  },
  computed: {
    ...mapGetters("authentication", ["loginState"]),
    validate() {

      if (!this.password || this.password.length < 4)
        return false;

      if (!this.password2 || this.password2.length < 4)
        return false;

      return true;
    }
  },
};
</script>