<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <header-stats v-if="getHeaderVisibility"/>
      <DashBoardHeader v-else-if="getDashboardHeaderVisibility"/>
      <GenericHeader v-else/>
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <router-view />
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import DashBoardHeader from "@/components/Headers/DashBoardHeader.vue";
import GenericHeader from "@/components/Headers/GenericHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
    DashBoardHeader,
    GenericHeader
  },
  computed: {
    ...mapGetters("headerStats", [
      "getHeaderVisibility",
    ]),
    ...mapGetters("dashboardheader", [
      "getDashboardHeaderVisibility",
    ]),
  },
};
</script>