<template>
    <Card>
        <Toolbar :title="$t('menu.addDevices')">
            <Button :title="$t('navigation.add')" icon-class="fas fa-plus" background-color="bg-emerald-500"
                :disabled="progressIndicatorVisible || !addEnabled" @click="handleAdd" />
        </Toolbar>
        <Form>
            <FormSection :title="$t('devices.deviceInfo')">
                <TextInput v-model.trim="mac" :maxLength="17" :title="$t('devices.mac')" placeHolder="12:34:56:78:90:00"
                    :disabled="progressIndicatorVisible" @keyup="fixedMacAddress()" />

                <TextInput v-model.trim="name" :maxLength="255" :title="$t('devices.name')"
                    :placeHolder="$t('devices.name')" :disabled="progressIndicatorVisible" />

                <SelectInput v-model="project" :title="$t('devices.projectName')" :values="values" value-key="id"
                    display-value-key="name" />

                <TextInput v-model.trim="serialNo" :maxLength="512" :title="$t('devices.serialNo')"
                    :placeHolder="$t('devices.serialNo')" :disabled="progressIndicatorVisible" />
            </FormSection>
        </Form>
        <PulseLoader class="text-center" :loading="progressIndicatorVisible" />
    </Card>
</template>

<script>
import SelectInput from '../../../components/Forms/SelectInput'
import Button from '../../../components/Buttons/Button'
import TextInput from '../../../components/Forms/TextInput'
import FormSection from '../../../components/Forms/FormSection'
import Form from '../../../components/Forms/Form'
import Toolbar from '../../../components/Forms/Toolbar'
import Card from '../../../components/Cards/Card'
import { getProjects, addDevice } from "../../../services/admin.service";
import { successToast, errorToast } from "../../../helpers/toast-helper";

export default {
    components: {
        SelectInput,
        Button,
        TextInput,
        FormSection,
        Form,
        Toolbar,
        Card
    },
    data() {
        return {
            mac: "",
            name: "",
            serialNo: "",
            project: 0,
            values: [],
            progressIndicatorVisible: true
        }
    },
    created() {
        getProjects().then((data) => this.values = data).finally(() => this.progressIndicatorVisible = false);
    },
    methods: {
        fixedMacAddress() {
            let data = this.mac;
            data = data.replace(/([^0-9a-fA-F])*/g, "");

            if (data.length > 0)
                data = data
                    .match(/.{1,2}/g) // ["4a", "89", "26", "c4", "45", "78"]
                    .join(":");

            this.mac = data.slice(0, 17).toUpperCase();
        },
        handleAdd() {

            addDevice({
                deviceId: this.mac,
                name: this.name,
                serialNo: this.serialNo,
                projectId: parseInt(this.project)
            })
                .then(() => {
                    successToast(this.$t("messages.deviceAdded"));
                    this.$router.push({ name: "devices" });
                }).catch((e) => errorToast(this.$t("errors.errorOccured"))).finally(() => this.progressIndicatorVisible = false)
        }
    },
    computed: {
        addEnabled() {
            return this.mac.length == 17 && this.project > 0 && this.name.length > 0;
        }
    }
}
</script>