<template>
  <!-- <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :class="'lang lang-' + lang + ''" :key="`Lang${i}`" :value="lang">
        <span class="flag"></span>
      </option>
    </select>
  </div> -->
  <div id="lang_selector" class="language-dropdown locale-changer" :class="dropdownOpen ? 'open' : ''">
    <label for="toggle" class="lang-flag" :class="`lang-${$i18n.locale}`" title="Click to select the language"
      @click="dropdownOpen = !dropdownOpen">
      <span class="flag"></span>
    </label>
    <ul class="lang-list">
      <li v-for="(lang, i) in langs" :key="`Lang${i}`" :class="'lang lang-' + lang + ''" :title="lang"
        @click="changeLanguage(lang)">
        <span class="flag"></span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "locale-changer",
  data() {
    return { langs: ["tr", "en"], dropdownOpen: false };
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.dropdownOpen = false;

      this.$router.go();
    },
  },
};
</script>
<style>
.language-dropdown {
  width: min-content;
  padding: 5px;
  display: inline-block;
  height: 50px;
  margin-top: 0px;
  margin-right: 10px;
}

.language-dropdown .lang-en .flag {
  background-image: url("http://findicons.com/files/icons/282/flags/48/united_states_of_america_usa.png");
}

.language-dropdown .lang-tr .flag {
  background-image: url("http://icons.iconarchive.com/icons/icondrawer/flags/48/Turkey-icon.png");
}

.language-dropdown label,
.language-dropdown ul li {
  display: block;
  width: 50px;
  height: 38px;
  cursor: pointer;
}

.language-dropdown ul.lang-list {
  position: relative;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}

.language-dropdown ul.lang-list li.selected {
  display: none;
}

.language-dropdown .lang-flag .flag,
.language-dropdown .lang-list .lang .flag {
  float: left;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-size: 35px 35px;
  background-repeat: no-repeat;
  background-position: center;
}

.language-dropdown.open ul.lang-list {
  visibility: visible;
  opacity: 1;
}
</style>
