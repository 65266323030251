import { config, api_routes } from "./config";
import { authHeader } from "../helpers";
import axios from "axios";
const currentUserSubject = JSON.parse(localStorage.getItem("user"));

export const BatchWorkingService = {
    getBatchWorkingProfiles,
    getBatchWorkingDevices,
    postBatchWorkingProfile,
    putBatchWorkingProfile,
    removeBatchWorkingProfile,
    postBatchWorkingDevices,
    getBatchWorkingProfileDevices,
    postApollo32BatchWorkingProfile,
    getApollo32BatchWorkingProfile,
    postApollo32BatchWorkingSetTemp,
    getApollo32BatchWorkingSetTemp,
    getApollo32DeviceBatchWorkingProfile,
    getApollo32BatchName,
    currentUser: currentUserSubject
};

function getBatchWorkingProfiles() {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}${api_routes.batchWorking.batchWorkingProfiles.url}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getBatchWorkingDevices({project, data}) {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/${project.project}/devices`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postBatchWorkingProfile({ project, data }) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/${project}/batches`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function putBatchWorkingProfile({ project, batchId, data }) {

    return new Promise((resolve, reject) => {
        axios
            .put(`${config.apiUrl}/${project}/batches/${batchId}`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function removeBatchWorkingProfile({ project, batchId }) {

    return new Promise((resolve, reject) => {
        axios
            .delete(`${config.apiUrl}/${project}/batches/${batchId}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postBatchWorkingDevices({ project, batchId, data }) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/${project}/batch/${batchId}/devices`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getBatchWorkingProfileDevices({ project, batchId }) {

    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}/${project}/batch/${batchId}/devices`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postApollo32BatchWorkingProfile(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${api_routes.apollo32.profile.set}`, {
                headers: authHeader(),
                data: data,
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getApollo32BatchWorkingProfile(data) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${api_routes.apollo32.profile.get}`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function postApollo32BatchWorkingSetTemp(data) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${api_routes.apollo32.settemp.set}`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getApollo32BatchWorkingSetTemp(data) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${api_routes.apollo32.settemp.get}`, {
                headers: authHeader(),
                data
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getApollo32DeviceBatchWorkingProfile({ deviceId }) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}${api_routes.apollo32.getBatch.get}/${deviceId}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

function getApollo32BatchName({batchid}) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${config.apiUrl}${api_routes.apollo32.getBatchName.get}/${batchid}`, {
                headers: authHeader(),
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}