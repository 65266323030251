<template>
    <div class="
      text-white text-xs
      font-bold
      uppercase
      px-3
      py-1
      rounded
    ">
        <VPagination class="" v-model="modelValue" :pages="pageCount" :range-size="1" active-color="#DCEDFF"
            @update:modelValue="this.$emit('update:modelValue', modelValue)" />
    </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            required: false,
            default: 0
        },
        pageCount: {
            required: true,
        },
    },
    components: {
        VPagination
    }
};
</script>